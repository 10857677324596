import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { Selection } from "../components/Selection";
import { Buses } from "../api/typings";

interface UrlProps {
  chosenBus?: string;
}

export const BusEuroSelection = () => {
  const intl = useIntl();
  const history = useHistory();
  const { chosenBus } = useParams<UrlProps>();

  const euroStandards = [
    "<Euro 2",
    "Euro 3",
    "Euro 4",
    "Euro 5",
    "EEV",
    "Euro 6",
  ];
  
  const mazEuroStandards = [
    "<Euro 2",
    "Euro 3",
    "Euro 4",
    "Euro 5",
  ];
  
  const neolazEuroStandards = [
    "<Euro 2",
    "Euro 3",
  ];

  const standards =
    chosenBus === Buses.MAZ
      ? mazEuroStandards
      : chosenBus === Buses.NeoLAZ
      ? neolazEuroStandards
      : euroStandards;

  const options =
    standards.map((euro) => ({ value: euro, label: euro }));

  const onChange = (option) => {
    if (!option) return;
    history.push(`/buses/${chosenBus}/${option.value}`);
  };

  return (
    <Selection
      options={options}
      placeholder={intl.formatMessage({ id: "euroSelection.header" })}
      onChange={onChange}
    />
  );
};
