import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import styles from "./menu-views.module.scss";

const Ingredients = ({ setIsProgressBarVisible }) => {
  useEffect(() => {
    setIsProgressBarVisible(false);

    return () => setIsProgressBarVisible(true);
  });

  return (
    <div className={styles["container"]}>
      <div className={styles["content"]}>
        <p>
          <FormattedMessage id="ingredients.heading_1" />
        </p>
        <p>
          <FormattedMessage id="ingredients.heading_2" />
        </p>
        <FormattedMessage id="ingredients.paragraph_1" />
        <br />
        <p>
          <FormattedMessage id="ingredients.heading_3" />
        </p>
        <FormattedMessage id="ingredients.paragraph_2" />
        <br />
        <p>
          <FormattedMessage id="ingredients.heading_4" />
        </p>
        <FormattedMessage id="ingredients.paragraph_3" />
        <br />
        <p>
          <FormattedMessage id="ingredients.heading_5" />
        </p>
        <FormattedMessage id="ingredients.paragraph_4" />
        <br />
        <p>
          <FormattedMessage id="ingredients.heading_6" />
        </p>
        <FormattedMessage id="ingredients.paragraph_5" />
        <br />
        <p>
          <FormattedMessage id="ingredients.heading_7" />
        </p>
        <FormattedMessage id="ingredients.paragraph_6" />
        <br />
        <br />
        <span>
          <FormattedMessage id="ingredients.note" />
        </span>
      </div>
    </div>
  );
};

export default Ingredients;
