import { Products, Cars, CarAndProductsData } from "./typings";

export const years = [
	2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011, 2010, 2009,
	2008, 2007, 2006, 2005, 2004, 2003, 2002, 2001, 2000,
];

export const years2023 = [
	2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011,
	2010, 2009, 2008, 2007, 2006, 2005, 2004, 2003, 2002, 2001, 2000,
];

export const teslaYears = [
	2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012,
];

export const saabYears = [
	2014, 2013, 2012, 2011, 2010, 2009, 2008, 2007, 2006, 2005, 2004, 2003, 2002,
	2001, 2000,
];

export const cupraYears = [2023, 2022, 2021, 2020, 2019, 2018];

export const dsAutomobilesYears = [
	2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014,
];

export const carsAndProductsData: CarAndProductsData[] = [
  {
    model: Cars["Alfa Romeo"],
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD, secondary: Products.VCS, third: Products.LPC },
      2002: { primary: Products.STANDARD, secondary: Products.VCS, third: Products.LPC },
      2003: { primary: Products.STANDARD, secondary: Products.VCS, third: Products.LPC },
      2004: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2005: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2006: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2007: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2008: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2009: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2010: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2011: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2012: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2013: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2014: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2015: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2016: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2017: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2018: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2019: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2020: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2021: { primary: Products.LPC, secondary: Products.LLC, third: Products.VCS },
      2022: { primary: Products.LPC, secondary: Products.LLC, third: Products.VCS },
      2023: { primary: Products.LPC, secondary: Products.LLC, third: Products.VCS },
    },
  },
  {
    model: Cars.Audi,
    data: {
		2000: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.G12EVO },
		2001: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
		2003: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
		2002: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
		2004: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
		2005: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
		2006: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
		2007: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
		2008: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
		2009: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2010: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2011: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2012: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2013: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2014: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2015: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2016: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2017: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2018: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2019: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2020: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2021: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2022: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2023: { primary: Products.G12EVO, secondary: Products.MBG12 },
	  },
  },
  {
    model: Cars.Volkswagen,
    data: {
      2000: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.G12EVO },
      2001: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
      2003: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
      2002: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
      2004: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
      2005: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
      2006: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
      2007: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
      2008: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
      2009: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2010: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2011: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2012: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2013: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2014: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2015: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2016: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2017: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2018: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2019: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2020: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2021: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2022: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2023: { primary: Products.G12EVO, secondary: Products.MBG12 },
    },
  },
  {
    model: Cars.Škoda,
    data: {
		2000: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.G12EVO },
		2001: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
		2003: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
		2002: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
		2004: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
		2005: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
		2006: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
		2007: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
		2008: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
		2009: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2010: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2011: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2012: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2013: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2014: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2015: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2016: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2017: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2018: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2019: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2020: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2021: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2022: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2023: { primary: Products.G12EVO, secondary: Products.MBG12 },
	  },
  },
  {
    model: Cars.Seat,
    data: {
		2000: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.G12EVO },
		2001: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
		2003: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
		2002: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
		2004: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
		2005: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
		2006: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
		2007: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
		2008: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
		2009: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2010: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2011: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2012: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2013: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2014: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2015: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2016: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2017: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2018: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2019: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2020: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2021: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2022: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2023: { primary: Products.G12EVO, secondary: Products.MBG12 },
	  },
  },
  {
    model: Cars.Porsche,
    data: {
		2000: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.G12EVO },
		2001: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
		2003: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
		2002: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
		2004: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
		2005: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
		2006: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
		2007: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
		2008: { primary: Products.G12EVO, secondary: Products.MBG12, third: Products.LONGLIFE },
		2009: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2010: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2011: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2012: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2013: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2014: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2015: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2016: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2017: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2018: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2019: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2020: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2021: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2022: { primary: Products.G12EVO, secondary: Products.MBG12 },
		2023: { primary: Products.G12EVO, secondary: Products.MBG12 },
	  },
  },
  {
    model: Cars.Cupra,
    data: {
      2000: { primary: Products.NONE },
      2001: { primary: Products.NONE },
      2002: { primary: Products.NONE },
      2003: { primary: Products.NONE },
      2004: { primary: Products.NONE },
      2005: { primary: Products.NONE },
      2006: { primary: Products.NONE },
      2007: { primary: Products.NONE },
      2008: { primary: Products.NONE },
      2009: { primary: Products.NONE },
      2010: { primary: Products.NONE },
      2011: { primary: Products.NONE },
      2012: { primary: Products.NONE },
      2013: { primary: Products.NONE },
      2014: { primary: Products.NONE },
      2015: { primary: Products.NONE },
      2016: { primary: Products.NONE },
      2017: { primary: Products.NONE },
      2018: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2019: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2020: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2021: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2022: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2023: { primary: Products.G12EVO, secondary: Products.MBG12 },
    },
  },
  {
    model: Cars.Ford,
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD, secondary: Products.LPC, third: Products.VCS },
      2002: { primary: Products.LPC, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2003: { primary: Products.LPC, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2004: { primary: Products.LPC, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2005: { primary: Products.LPC, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2006: { primary: Products.LPC, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2007: { primary: Products.LPC, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2008: { primary: Products.LPC, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2009: { primary: Products.LPC, secondary: Products.LONGLIFE, third: Products.VCS },
      2010: { primary: Products.LPC, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2011: { primary: Products.LPC, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2012: { primary: Products.LPC, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2013: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2014: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2015: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2016: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2017: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2018: { primary: Products.LPC, secondary: Products.VCS, third: Products.LONGLIFE },
      2019: { primary: Products.LPC, secondary: Products.VCS, third: Products.LONGLIFE },
      2020: { primary: Products.LPC, secondary: Products.LLC, third: Products.VCS },
      2021: { primary: Products.LPC, secondary: Products.LLC, third: Products.VCS },
      2022: { primary: Products.LPC, secondary: Products.LLC, third: Products.VCS },
      2023: { primary: Products.LPC, secondary: Products.LLC, third: Products.VCS },
    },
  },
  {
    model: Cars.Opel,
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD, secondary: Products.LPC, third: Products.VCS },
      2002: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
      2003: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
      2004: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
      2005: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2006: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2007: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2008: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2009: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LPC },
      2010: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2011: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2012: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2013: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2014: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2015: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2016: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2017: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2018: { primary: Products.LPC, secondary: Products.VCS, third: Products.LONGLIFE },
      2019: { primary: Products.LPC, secondary: Products.VCS, third: Products.LONGLIFE },
      2020: { primary: Products.LPC, secondary: Products.LLC, third: Products.VCS },
      2021: { primary: Products.LPC, secondary: Products.LLC, third: Products.VCS },
      2022: { primary: Products.LPC, secondary: Products.LLC, third: Products.VCS },
      2023: { primary: Products.LPC, secondary: Products.LLC, third: Products.VCS },
    },
  },
  {
    model: Cars.Jaguar,
    data: {
		2000: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2001: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
		2002: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
		2003: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
		2004: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
		2005: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
		2006: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
		2007: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
		2008: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
		2009: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2010: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2011: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2012: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2013: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2014: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2015: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2016: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2017: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2018: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2019: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2020: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2021: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2022: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2023: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
	  },
  },
  {
    model: Cars.Chevrolet,
    data: {
		2000: { primary: Products.STANDARD },
		2001: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2002: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2003: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2004: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
		2005: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
		2006: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
		2007: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
		2008: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
		2009: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2010: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2011: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2012: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2013: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2014: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2015: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2016: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2017: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2018: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2019: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2020: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2021: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2022: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2023: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
	  },
  },
  {
    model: Cars.Saab,
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2002: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2003: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2004: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
      2005: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
      2006: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
      2007: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
      2008: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
      2009: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2010: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2011: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2012: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2013: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2014: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2015: { primary: Products.NONE },
      2016: { primary: Products.NONE },
      2017: { primary: Products.NONE },
      2018: { primary: Products.NONE },
      2019: { primary: Products.NONE },
      2020: { primary: Products.NONE },
      2021: { primary: Products.NONE },
      2022: { primary: Products.NONE },
      2023: { primary: Products.NONE },
    },
  },
  {
    model: Cars.Cadillac,
    data: {
		2000: { primary: Products.STANDARD },
		2001: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2002: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2003: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2004: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
		2005: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
		2006: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
		2007: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
		2008: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
		2009: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2010: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2011: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2012: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2013: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2014: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2015: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2016: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2017: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2018: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2019: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2020: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2021: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2022: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2023: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
	  },
  },
  {
    model: Cars.Lada,
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD },
      2002: { primary: Products.STANDARD },
      2003: { primary: Products.STANDARD },
      2004: { primary: Products.STANDARD },
      2005: { primary: Products.STANDARD },
      2006: { primary: Products.STANDARD },
      2007: { primary: Products.STANDARD },
      2008: { primary: Products.STANDARD },
      2009: { primary: Products.STANDARD },
      2010: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2011: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2012: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2013: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2014: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2015: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2016: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2017: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2018: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2019: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2020: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2021: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2022: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2023: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
    },
  },
  {
    model: Cars["Land Rover"],
    data: {
      2000: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2001: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
      2002: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
      2003: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
      2004: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
      2005: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
      2006: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
      2007: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
      2008: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
      2009: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2010: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2011: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2012: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2013: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2014: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2015: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2016: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2017: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2018: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2019: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2020: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2021: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2022: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2023: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
    },
  },
  {
    model: Cars.Renault,
    data: {
      2000: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.STANDARD },
      2001: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2002: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2003: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2004: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2005: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2006: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2007: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2008: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2009: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2010: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2011: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2012: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2013: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2014: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2015: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2016: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2017: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2018: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2019: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2020: { primary: Products.LLC, secondary: Products.LPC },
      2021: { primary: Products.LLC, secondary: Products.LPC },
      2022: { primary: Products.LLC, secondary: Products.LPC },
      2023: { primary: Products.LLC, secondary: Products.LPC },
    },
  },
  {
    model: Cars.BMW,
    data: {
      2000: { primary: Products.STANDARD, secondary: Products.VC },
      2001: { primary: Products.VC, secondary: Products.STANDARD },
      2002: { primary: Products.VC, secondary: Products.STANDARD },
      2003: { primary: Products.VC, secondary: Products.STANDARD },
      2004: { primary: Products.VC },
      2005: { primary: Products.VC },
      2006: { primary: Products.VC },
      2007: { primary: Products.VC },
      2008: { primary: Products.VC },
      2009: { primary: Products.VC },
      2010: { primary: Products.VC },
      2011: { primary: Products.VC },
      2012: { primary: Products.VC },
      2013: { primary: Products.VC },
      2014: { primary: Products.VC },
      2015: { primary: Products.VC },
      2016: { primary: Products.VC },
      2017: { primary: Products.VC },
      2018: { primary: Products.VC },
      2019: { primary: Products.VC },
      2020: { primary: Products.VC },
      2021: { primary: Products.VC },
      2022: { primary: Products.VC },
      2023: { primary: Products.VC },
    },
  },
  {
    model: Cars["Mercedes-Benz"],
    data: {
      2000: { primary: Products.MBG12, secondary: Products.STANDARD },
      2001: { primary: Products.MBG12, secondary: Products.VC },
      2002: { primary: Products.MBG12, secondary: Products.VC },
      2003: { primary: Products.MBG12, secondary: Products.VC },
      2004: { primary: Products.MBG12 },
      2005: { primary: Products.MBG12 },
      2006: { primary: Products.MBG12 },
      2007: { primary: Products.MBG12 },
      2008: { primary: Products.MBG12 },
      2009: { primary: Products.MBG12 },
      2010: { primary: Products.MBG12 },
      2011: { primary: Products.MBG12 },
      2012: { primary: Products.MBG12 },
      2013: { primary: Products.MBG12 },
      2014: { primary: Products.MBG12 },
      2015: { primary: Products.MBG12 },
      2016: { primary: Products.MBG12 },
      2017: { primary: Products.MBG12 },
      2018: { primary: Products.MBG12 },
      2019: { primary: Products.MBG12 },
      2020: { primary: Products.MBG12 },
      2021: { primary: Products.MBG12 },
      2022: { primary: Products.MBG12 },
      2023: { primary: Products.MBG12 },
    },
  },
  {
    model: Cars.Mini,
    data: {
      2000: { primary: Products.STANDARD, secondary: Products.VC },
      2001: { primary: Products.VC, secondary: Products.STANDARD },
      2002: { primary: Products.VC, secondary: Products.STANDARD },
      2003: { primary: Products.VC, secondary: Products.STANDARD },
      2004: { primary: Products.VC },
      2005: { primary: Products.VC },
      2006: { primary: Products.VC },
      2007: { primary: Products.VC },
      2008: { primary: Products.VC },
      2009: { primary: Products.VC },
      2010: { primary: Products.VC },
      2011: { primary: Products.VC },
      2012: { primary: Products.VC },
      2013: { primary: Products.VC },
      2014: { primary: Products.VC },
      2015: { primary: Products.VC },
      2016: { primary: Products.VC },
      2017: { primary: Products.VC },
      2018: { primary: Products.VC },
      2019: { primary: Products.VC },
      2020: { primary: Products.VC },
      2021: { primary: Products.VC },
      2022: { primary: Products.VC },
      2023: { primary: Products.VC },
    },
  },
  {
    model: Cars.Toyota,
    data: {
      2000: { primary: Products.LLC, secondary: Products.SLLC, third: Products.LLPRO },
      2001: { primary: Products.LLC, secondary: Products.SLLC, third: Products.LONGLIFE },
      2002: { primary: Products.LLC, secondary: Products.SLLC, third: Products.LONGLIFE },
      2003: { primary: Products.LLC, secondary: Products.SLLC, third: Products.LONGLIFE },
      2004: { primary: Products.LLC, secondary: Products.SLLC, third: Products.LONGLIFE },
      2005: { primary: Products.SLLC, secondary: Products.LLC, third: Products.LONGLIFE },
      2006: { primary: Products.SLLC, secondary: Products.LLC, third: Products.LONGLIFE },
      2007: { primary: Products.SLLC, secondary: Products.LLC, third: Products.LONGLIFE },
      2008: { primary: Products.SLLC, secondary: Products.LLC },
      2009: { primary: Products.SLLC, secondary: Products.LLC },
      2010: { primary: Products.SLLC, secondary: Products.LLC },
      2011: { primary: Products.SLLC, secondary: Products.LLC },
      2012: { primary: Products.SLLC, secondary: Products.LLC },
      2013: { primary: Products.SLLC, secondary: Products.LLC },
      2014: { primary: Products.SLLC, secondary: Products.LLC },
      2015: { primary: Products.SLLC, secondary: Products.LLC },
      2016: { primary: Products.SLLC, secondary: Products.LLC },
      2017: { primary: Products.SLLC, secondary: Products.LLC },
      2018: { primary: Products.SLLC, secondary: Products.LLC },
      2019: { primary: Products.SLLC, secondary: Products.LLC },
      2020: { primary: Products.SLLC, secondary: Products.LLC },
      2021: { primary: Products.SLLC, secondary: Products.LLC },
      2022: { primary: Products.SLLC, secondary: Products.LLC },
      2023: { primary: Products.SLLC, secondary: Products.LLC },
    },
  },
  {
    model: Cars.Lexus,
    data: {
		2000: { primary: Products.LLC, secondary: Products.SLLC, third: Products.LLPRO },
		2001: { primary: Products.LLC, secondary: Products.SLLC, third: Products.LONGLIFE },
		2002: { primary: Products.LLC, secondary: Products.SLLC, third: Products.LONGLIFE },
		2003: { primary: Products.LLC, secondary: Products.SLLC, third: Products.LONGLIFE },
		2004: { primary: Products.LLC, secondary: Products.SLLC, third: Products.LONGLIFE },
		2005: { primary: Products.SLLC, secondary: Products.LLC, third: Products.LONGLIFE },
		2006: { primary: Products.SLLC, secondary: Products.LLC, third: Products.LONGLIFE },
		2007: { primary: Products.SLLC, secondary: Products.LLC, third: Products.LONGLIFE },
		2008: { primary: Products.SLLC, secondary: Products.LLC },
		2009: { primary: Products.SLLC, secondary: Products.LLC },
		2010: { primary: Products.SLLC, secondary: Products.LLC },
		2011: { primary: Products.SLLC, secondary: Products.LLC },
		2012: { primary: Products.SLLC, secondary: Products.LLC },
		2013: { primary: Products.SLLC, secondary: Products.LLC },
		2014: { primary: Products.SLLC, secondary: Products.LLC },
		2015: { primary: Products.SLLC, secondary: Products.LLC },
		2016: { primary: Products.SLLC, secondary: Products.LLC },
		2017: { primary: Products.SLLC, secondary: Products.LLC },
		2018: { primary: Products.SLLC, secondary: Products.LLC },
		2019: { primary: Products.SLLC, secondary: Products.LLC },
		2020: { primary: Products.SLLC, secondary: Products.LLC },
		2021: { primary: Products.SLLC, secondary: Products.LLC },
		2022: { primary: Products.SLLC, secondary: Products.LLC },
		2023: { primary: Products.SLLC, secondary: Products.LLC },
	  },
  },
  {
    model: Cars.Nissan,
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD, secondary: Products.LLC, third: Products.LPC },
      2002: { primary: Products.STANDARD, secondary: Products.LLC, third: Products.LPC },
      2003: { primary: Products.LLC, secondary: Products.STANDARD, third: Products.LPC },
      2004: { primary: Products.LLC, secondary: Products.LPC },
      2005: { primary: Products.LLC, secondary: Products.LPC },
      2006: { primary: Products.LLC, secondary: Products.LPC },
      2007: { primary: Products.LLC, secondary: Products.LPC },
      2008: { primary: Products.LLC, secondary: Products.LPC },
      2009: { primary: Products.LLC, secondary: Products.LPC },
      2010: { primary: Products.LLC, secondary: Products.LPC },
      2011: { primary: Products.LLC, secondary: Products.LPC },
      2012: { primary: Products.LLC, secondary: Products.LPC },
      2013: { primary: Products.LLC, secondary: Products.LPC },
      2014: { primary: Products.LLC, secondary: Products.LPC },
      2015: { primary: Products.LLC, secondary: Products.LPC },
      2016: { primary: Products.LLC, secondary: Products.LPC },
      2017: { primary: Products.LLC, secondary: Products.LPC },
      2018: { primary: Products.LLC, secondary: Products.LPC },
      2019: { primary: Products.LLC, secondary: Products.LPC },
      2020: { primary: Products.LLC, secondary: Products.LPC },
      2021: { primary: Products.LLC, secondary: Products.LPC },
      2022: { primary: Products.LLC, secondary: Products.LPC },
      2023: { primary: Products.LLC, secondary: Products.LPC },
    },
  },
  {
    model: Cars.Honda,
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD, secondary: Products.LLC, third: Products.LPC },
      2002: { primary: Products.STANDARD, secondary: Products.LLC, third: Products.LPC },
      2003: { primary: Products.LLC, secondary: Products.STANDARD, third: Products.LPC },
      2004: { primary: Products.LLC, secondary: Products.LPC },
      2005: { primary: Products.LLC, secondary: Products.LPC },
      2006: { primary: Products.LLC, secondary: Products.LPC },
      2007: { primary: Products.LLC, secondary: Products.LPC },
      2008: { primary: Products.LLC, secondary: Products.LPC },
      2009: { primary: Products.LLC, secondary: Products.LPC },
      2010: { primary: Products.LLC, secondary: Products.LPC },
      2011: { primary: Products.LLC, secondary: Products.LPC },
      2012: { primary: Products.LLC, secondary: Products.LPC },
      2013: { primary: Products.LLC, secondary: Products.LPC },
      2014: { primary: Products.LLC, secondary: Products.LPC },
      2015: { primary: Products.LLC, secondary: Products.LPC },
      2016: { primary: Products.LLC, secondary: Products.LPC },
      2017: { primary: Products.LLC, secondary: Products.LPC },
      2018: { primary: Products.LLC, secondary: Products.LPC },
      2019: { primary: Products.LLC, secondary: Products.LPC },
      2020: { primary: Products.LLC, secondary: Products.LPC },
      2021: { primary: Products.LLC, secondary: Products.LPC },
      2022: { primary: Products.LLC, secondary: Products.LPC },
      2023: { primary: Products.LLC, secondary: Products.LPC },
    },
  },
  {
    model: Cars.Mazda,
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD, secondary: Products.LLC, third: Products.LPC },
      2002: { primary: Products.STANDARD, secondary: Products.LLC, third: Products.LPC },
      2003: { primary: Products.LLC, secondary: Products.STANDARD, third: Products.LPC },
      2004: { primary: Products.LLC, secondary: Products.LPC },
      2005: { primary: Products.LLC, secondary: Products.LPC },
      2006: { primary: Products.LLC, secondary: Products.LPC },
      2007: { primary: Products.LLC, secondary: Products.LPC },
      2008: { primary: Products.LLC, secondary: Products.LPC },
      2009: { primary: Products.LLC, secondary: Products.LPC },
      2010: { primary: Products.LLC, secondary: Products.LPC },
      2011: { primary: Products.LLC, secondary: Products.LPC },
      2012: { primary: Products.LLC, secondary: Products.LPC },
      2013: { primary: Products.LLC, secondary: Products.LPC },
      2014: { primary: Products.LLC, secondary: Products.LPC },
      2015: { primary: Products.LLC, secondary: Products.LPC },
      2016: { primary: Products.LLC, secondary: Products.LPC },
      2017: { primary: Products.LLC, secondary: Products.LPC },
      2018: { primary: Products.LLC, secondary: Products.LPC },
      2019: { primary: Products.LLC, secondary: Products.LPC },
      2020: { primary: Products.LLC, secondary: Products.LPC },
      2021: { primary: Products.LLC, secondary: Products.LPC },
      2022: { primary: Products.LLC, secondary: Products.LPC },
      2023: { primary: Products.LLC, secondary: Products.LPC },
    },
  },
  {
    model: Cars.Mitsubishi,
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD, secondary: Products.LLC, third: Products.LPC },
      2002: { primary: Products.STANDARD, secondary: Products.LLC, third: Products.LPC },
      2003: { primary: Products.LLC, secondary: Products.STANDARD, third: Products.LPC },
      2004: { primary: Products.LLC, secondary: Products.LPC },
      2005: { primary: Products.LLC, secondary: Products.LPC },
      2006: { primary: Products.LLC, secondary: Products.LPC },
      2007: { primary: Products.LLC, secondary: Products.LPC },
      2008: { primary: Products.LLC, secondary: Products.LPC },
      2009: { primary: Products.LLC, secondary: Products.LPC },
      2010: { primary: Products.LLC, secondary: Products.LPC },
      2011: { primary: Products.LLC, secondary: Products.LPC },
      2012: { primary: Products.LLC, secondary: Products.LPC },
      2013: { primary: Products.LLC, secondary: Products.LPC },
      2014: { primary: Products.LLC, secondary: Products.LPC },
      2015: { primary: Products.LLC, secondary: Products.LPC },
      2016: { primary: Products.LLC, secondary: Products.LPC },
      2017: { primary: Products.LLC, secondary: Products.LPC },
      2018: { primary: Products.LLC, secondary: Products.LPC },
      2019: { primary: Products.LLC, secondary: Products.LPC },
      2020: { primary: Products.LLC, secondary: Products.LPC },
      2021: { primary: Products.LLC, secondary: Products.LPC },
      2022: { primary: Products.LLC, secondary: Products.LPC },
      2023: { primary: Products.LLC, secondary: Products.LPC },
    },
  },
  {
    model: Cars.Hyundai,
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD, secondary: Products.LLC, third: Products.SLLC },
      2002: { primary: Products.STANDARD, secondary: Products.LLC, third: Products.SLLC },
      2003: { primary: Products.LLC, secondary: Products.STANDARD, third: Products.SLLC },
      2004: { primary: Products.LLC, secondary: Products.SLLC },
      2005: { primary: Products.LLC, secondary: Products.SLLC },
      2006: { primary: Products.LLC, secondary: Products.SLLC },
      2007: { primary: Products.LLC, secondary: Products.SLLC },
      2008: { primary: Products.LLC, secondary: Products.SLLC },
      2009: { primary: Products.LLC, secondary: Products.SLLC },
      2010: { primary: Products.LLC, secondary: Products.SLLC },
      2011: { primary: Products.LLC, secondary: Products.SLLC },
      2012: { primary: Products.LLC, secondary: Products.SLLC },
      2013: { primary: Products.LLC, secondary: Products.SLLC },
      2014: { primary: Products.LLC, secondary: Products.SLLC },
      2015: { primary: Products.LLC, secondary: Products.SLLC },
      2016: { primary: Products.LLC, secondary: Products.SLLC },
      2017: { primary: Products.LLC, secondary: Products.SLLC },
      2018: { primary: Products.LLC, secondary: Products.SLLC },
      2019: { primary: Products.LLC, secondary: Products.SLLC },
      2020: { primary: Products.LLC, secondary: Products.SLLC, third: Products.FREECOR },
      2021: { primary: Products.LLC, secondary: Products.SLLC, third: Products.FREECOR },
      2022: { primary: Products.LLC, secondary: Products.SLLC, third: Products.FREECOR },
      2023: { primary: Products.LLC, secondary: Products.SLLC, third: Products.FREECOR },
    },
  },
  {
    model: Cars.Kia,
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD, secondary: Products.LLC, third: Products.SLLC },
      2002: { primary: Products.STANDARD, secondary: Products.LLC, third: Products.SLLC },
      2003: { primary: Products.LLC, secondary: Products.STANDARD, third: Products.SLLC },
      2004: { primary: Products.LLC, secondary: Products.SLLC },
      2005: { primary: Products.LLC, secondary: Products.SLLC },
      2006: { primary: Products.LLC, secondary: Products.SLLC },
      2007: { primary: Products.LLC, secondary: Products.SLLC },
      2008: { primary: Products.LLC, secondary: Products.SLLC },
      2009: { primary: Products.LLC, secondary: Products.SLLC },
      2010: { primary: Products.LLC, secondary: Products.SLLC },
      2011: { primary: Products.LLC, secondary: Products.SLLC },
      2012: { primary: Products.LLC, secondary: Products.SLLC },
      2013: { primary: Products.LLC, secondary: Products.SLLC },
      2014: { primary: Products.LLC, secondary: Products.SLLC },
      2015: { primary: Products.LLC, secondary: Products.SLLC },
      2016: { primary: Products.LLC, secondary: Products.SLLC },
      2017: { primary: Products.LLC, secondary: Products.SLLC },
      2018: { primary: Products.LLC, secondary: Products.SLLC },
      2019: { primary: Products.LLC, secondary: Products.SLLC },
      2020: { primary: Products.LLC, secondary: Products.SLLC, third: Products.FREECOR },
      2021: { primary: Products.LLC, secondary: Products.SLLC, third: Products.FREECOR },
      2022: { primary: Products.LLC, secondary: Products.SLLC, third: Products.FREECOR },
      2023: { primary: Products.LLC, secondary: Products.SLLC, third: Products.FREECOR },
    },
  },
  {
    model: Cars.Subaru,
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD, secondary: Products.LLC, third: Products.LPC },
      2002: { primary: Products.STANDARD, secondary: Products.LLC, third: Products.LPC },
      2003: { primary: Products.LLC, secondary: Products.STANDARD, third: Products.LPC },
      2004: { primary: Products.LLC, secondary: Products.LPC },
      2005: { primary: Products.LLC, secondary: Products.LPC },
      2006: { primary: Products.LLC, secondary: Products.LPC },
      2007: { primary: Products.LLC, secondary: Products.LPC },
      2008: { primary: Products.LLC, secondary: Products.LPC },
      2009: { primary: Products.LLC, secondary: Products.LPC },
      2010: { primary: Products.LLC, secondary: Products.LPC },
      2011: { primary: Products.LLC, secondary: Products.LPC },
      2012: { primary: Products.LLC, secondary: Products.LPC },
      2013: { primary: Products.LLC, secondary: Products.LPC },
      2014: { primary: Products.LLC, secondary: Products.LPC },
      2015: { primary: Products.LLC, secondary: Products.LPC },
      2016: { primary: Products.LLC, secondary: Products.LPC },
      2017: { primary: Products.LLC, secondary: Products.LPC },
      2018: { primary: Products.LLC, secondary: Products.LPC },
      2019: { primary: Products.LLC, secondary: Products.LPC },
      2020: { primary: Products.LLC, secondary: Products.LPC },
      2021: { primary: Products.LLC, secondary: Products.LPC },
      2022: { primary: Products.LLC, secondary: Products.LPC },
      2023: { primary: Products.LLC, secondary: Products.LPC },
    },
  },
  {
    model: Cars.Suzuki,
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD, secondary: Products.LLC, third: Products.LPC },
      2002: { primary: Products.STANDARD, secondary: Products.LLC, third: Products.LPC },
      2003: { primary: Products.LLC, secondary: Products.STANDARD, third: Products.LPC },
      2004: { primary: Products.LLC, secondary: Products.LPC },
      2005: { primary: Products.LLC, secondary: Products.LPC },
      2006: { primary: Products.LLC, secondary: Products.LPC },
      2007: { primary: Products.LLC, secondary: Products.LPC },
      2008: { primary: Products.LLC, secondary: Products.LPC },
      2009: { primary: Products.LLC, secondary: Products.LPC },
      2010: { primary: Products.LLC, secondary: Products.LPC },
      2011: { primary: Products.LLC, secondary: Products.LPC },
      2012: { primary: Products.LLC, secondary: Products.LPC },
      2013: { primary: Products.LLC, secondary: Products.LPC },
      2014: { primary: Products.LLC, secondary: Products.LPC },
      2015: { primary: Products.LLC, secondary: Products.LPC },
      2016: { primary: Products.LLC, secondary: Products.LPC },
      2017: { primary: Products.LLC, secondary: Products.LPC },
      2018: { primary: Products.LLC, secondary: Products.LPC },
      2019: { primary: Products.LLC, secondary: Products.LPC },
      2020: { primary: Products.LLC, secondary: Products.LPC },
      2021: { primary: Products.LLC, secondary: Products.LPC },
      2022: { primary: Products.LLC, secondary: Products.LPC },
      2023: { primary: Products.LLC, secondary: Products.LPC },
    },
  },
  {
    model: Cars.Isuzu,
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD, secondary: Products.LLC, third: Products.LPC },
      2002: { primary: Products.STANDARD, secondary: Products.LLC, third: Products.LPC },
      2003: { primary: Products.LLC, secondary: Products.STANDARD, third: Products.LPC },
      2004: { primary: Products.LLC, secondary: Products.LPC },
      2005: { primary: Products.LLC, secondary: Products.LPC },
      2006: { primary: Products.LLC, secondary: Products.LPC },
      2007: { primary: Products.LLC, secondary: Products.LPC },
      2008: { primary: Products.LLC, secondary: Products.LPC },
      2009: { primary: Products.LLC, secondary: Products.LPC },
      2010: { primary: Products.LLC, secondary: Products.LPC },
      2011: { primary: Products.LLC, secondary: Products.LPC },
      2012: { primary: Products.LLC, secondary: Products.LPC },
      2013: { primary: Products.LLC, secondary: Products.LPC },
      2014: { primary: Products.LLC, secondary: Products.LPC },
      2015: { primary: Products.LLC, secondary: Products.LPC },
      2016: { primary: Products.LLC, secondary: Products.LPC },
      2017: { primary: Products.LLC, secondary: Products.LPC },
      2018: { primary: Products.LLC, secondary: Products.LPC },
      2019: { primary: Products.LLC, secondary: Products.LPC },
      2020: { primary: Products.LLC, secondary: Products.LPC },
      2021: { primary: Products.LLC, secondary: Products.LPC },
      2022: { primary: Products.LLC, secondary: Products.LPC },
      2023: { primary: Products.LLC, secondary: Products.LPC },
    },
  },
  {
    model: Cars.Ssangyong,
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD, secondary: Products.LLC, third: Products.LPC },
      2002: { primary: Products.STANDARD, secondary: Products.LLC, third: Products.LPC },
      2003: { primary: Products.LLC, secondary: Products.STANDARD, third: Products.LPC },
      2004: { primary: Products.LLC, secondary: Products.LPC },
      2005: { primary: Products.LLC, secondary: Products.LPC },
      2006: { primary: Products.LLC, secondary: Products.LPC },
      2007: { primary: Products.LLC, secondary: Products.LPC },
      2008: { primary: Products.LLC, secondary: Products.LPC },
      2009: { primary: Products.LLC, secondary: Products.LPC },
      2010: { primary: Products.LLC, secondary: Products.LPC },
      2011: { primary: Products.LLC, secondary: Products.LPC },
      2012: { primary: Products.LLC, secondary: Products.LPC },
      2013: { primary: Products.LLC, secondary: Products.LPC },
      2014: { primary: Products.LLC, secondary: Products.LPC },
      2015: { primary: Products.LLC, secondary: Products.LPC },
      2016: { primary: Products.LLC, secondary: Products.LPC },
      2017: { primary: Products.LLC, secondary: Products.LPC },
      2018: { primary: Products.LLC, secondary: Products.LPC },
      2019: { primary: Products.LLC, secondary: Products.LPC },
      2020: { primary: Products.LLC, secondary: Products.LPC },
      2021: { primary: Products.LLC, secondary: Products.LPC },
      2022: { primary: Products.LLC, secondary: Products.LPC },
      2023: { primary: Products.LLC, secondary: Products.LPC },
    },
  },
  {
    model: Cars.KMG,
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD, secondary: Products.LLC, third: Products.LPC },
      2002: { primary: Products.STANDARD, secondary: Products.LLC, third: Products.LPC },
      2003: { primary: Products.LLC, secondary: Products.STANDARD, third: Products.LPC },
      2004: { primary: Products.LLC, secondary: Products.LPC },
      2005: { primary: Products.LLC, secondary: Products.LPC },
      2006: { primary: Products.LLC, secondary: Products.LPC },
      2007: { primary: Products.LLC, secondary: Products.LPC },
      2008: { primary: Products.LLC, secondary: Products.LPC },
      2009: { primary: Products.LLC, secondary: Products.LPC },
      2010: { primary: Products.LLC, secondary: Products.LPC },
      2011: { primary: Products.LLC, secondary: Products.LPC },
      2012: { primary: Products.LLC, secondary: Products.LPC },
      2013: { primary: Products.LLC, secondary: Products.LPC },
      2014: { primary: Products.LLC, secondary: Products.LPC },
      2015: { primary: Products.LLC, secondary: Products.LPC },
      2016: { primary: Products.LLC, secondary: Products.LPC },
      2017: { primary: Products.LLC, secondary: Products.LPC },
      2018: { primary: Products.LLC, secondary: Products.LPC },
      2019: { primary: Products.LLC, secondary: Products.LPC },
      2020: { primary: Products.LLC, secondary: Products.LPC },
      2021: { primary: Products.LLC, secondary: Products.LPC },
      2022: { primary: Products.LLC, secondary: Products.LPC },
      2023: { primary: Products.LLC, secondary: Products.LPC },
    },
  },
  {
    model: Cars.Peugeot,
    data: {
      2000: { primary: Products.LLC, secondary: Products.STANDARD },
      2001: { primary: Products.LLC, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2002: { primary: Products.LLC, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2003: { primary: Products.LLC, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2004: { primary: Products.LLC, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2005: { primary: Products.LLC, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2006: { primary: Products.LLC, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2007: { primary: Products.LLC, secondary: Products.LPC },
      2008: { primary: Products.LLC, secondary: Products.LPC },
      2009: { primary: Products.LLC, secondary: Products.LPC },
      2010: { primary: Products.LLC, secondary: Products.LPC },
      2011: { primary: Products.LLC, secondary: Products.LPC },
      2012: { primary: Products.LLC, secondary: Products.LPC },
      2013: { primary: Products.LLC, secondary: Products.LPC },
      2014: { primary: Products.LLC, secondary: Products.LPC },
      2015: { primary: Products.LLC, secondary: Products.LPC },
      2016: { primary: Products.LLC, secondary: Products.LPC },
      2017: { primary: Products.LLC, secondary: Products.LPC },
      2018: { primary: Products.LLC, secondary: Products.LPC },
      2019: { primary: Products.LLC, secondary: Products.LPC },
      2020: { primary: Products.LLC, secondary: Products.LPC },
      2021: { primary: Products.LLC, secondary: Products.LPC },
      2022: { primary: Products.LLC, secondary: Products.LPC },
      2023: { primary: Products.LLC, secondary: Products.LPC },
    },
  },
  {
    model: Cars.Citroen,
    data: {
      2000: { primary: Products.LLC, secondary: Products.STANDARD },
      2001: { primary: Products.LLC, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2002: { primary: Products.LLC, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2003: { primary: Products.LLC, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2004: { primary: Products.LLC, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2005: { primary: Products.LLC, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2006: { primary: Products.LLC, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2007: { primary: Products.LLC, secondary: Products.LPC },
      2008: { primary: Products.LLC, secondary: Products.LPC },
      2009: { primary: Products.LLC, secondary: Products.LPC },
      2010: { primary: Products.LLC, secondary: Products.LPC },
      2011: { primary: Products.LLC, secondary: Products.LPC },
      2012: { primary: Products.LLC, secondary: Products.LPC },
      2013: { primary: Products.LLC, secondary: Products.LPC },
      2014: { primary: Products.LLC, secondary: Products.LPC },
      2015: { primary: Products.LLC, secondary: Products.LPC },
      2016: { primary: Products.LLC, secondary: Products.LPC },
      2017: { primary: Products.LLC, secondary: Products.LPC },
      2018: { primary: Products.LLC, secondary: Products.LPC },
      2019: { primary: Products.LLC, secondary: Products.LPC },
      2020: { primary: Products.LLC, secondary: Products.LPC },
      2021: { primary: Products.LLC, secondary: Products.LPC },
      2022: { primary: Products.LLC, secondary: Products.LPC },
      2023: { primary: Products.LLC, secondary: Products.LPC },
    },
  },
  {
    model: Cars["DS Automobiles"],
    data: {
      2000: { primary: Products.NONE },
      2001: { primary: Products.NONE },
      2002: { primary: Products.NONE },
      2003: { primary: Products.NONE },
      2004: { primary: Products.NONE },
      2005: { primary: Products.NONE },
      2006: { primary: Products.NONE },
      2007: { primary: Products.NONE },
      2008: { primary: Products.NONE },
      2009: { primary: Products.NONE },
      2010: { primary: Products.NONE },
      2011: { primary: Products.NONE },
      2012: { primary: Products.NONE },
      2013: { primary: Products.NONE },
      2014: { primary: Products.LLC, secondary: Products.LPC },
      2015: { primary: Products.LLC, secondary: Products.LPC },
      2016: { primary: Products.LLC, secondary: Products.LPC },
      2017: { primary: Products.LLC, secondary: Products.LPC },
      2018: { primary: Products.LLC, secondary: Products.LPC },
      2019: { primary: Products.LLC, secondary: Products.LPC },
      2020: { primary: Products.LLC, secondary: Products.LPC },
      2021: { primary: Products.LLC, secondary: Products.LPC },
      2022: { primary: Products.LLC, secondary: Products.LPC },
      2023: { primary: Products.LLC, secondary: Products.LPC },
    },
  },
  {
    model: Cars.Dacia,
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2002: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2003: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2004: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2005: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2006: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2007: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2008: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2009: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2010: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2011: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2012: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2013: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2014: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2015: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2016: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2017: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2018: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2019: { primary: Products.LPC, secondary: Products.LLC, third: Products.LONGLIFE },
      2020: { primary: Products.LLC, secondary: Products.LPC },
      2021: { primary: Products.LLC, secondary: Products.LPC },
      2022: { primary: Products.LLC, secondary: Products.LPC },
      2023: { primary: Products.LLC, secondary: Products.LPC },
    },
  },
  {
    model: Cars.Fiat,
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD, secondary: Products.VCS, third: Products.LPC },
      2002: { primary: Products.STANDARD, secondary: Products.VCS, third: Products.LPC },
      2003: { primary: Products.STANDARD, secondary: Products.VCS, third: Products.LPC },
      2004: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2005: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2006: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2007: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2008: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2009: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2010: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2011: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2012: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2013: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2014: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2015: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2016: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2017: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2018: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2019: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2020: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
      2021: { primary: Products.LPC, secondary: Products.LLC, third: Products.VCS },
      2022: { primary: Products.LPC, secondary: Products.LLC, third: Products.VCS },
      2023: { primary: Products.LPC, secondary: Products.LLC, third: Products.VCS },
    },
  },
  {
    model: Cars.Lancia,
    data: {
		2000: { primary: Products.STANDARD },
		2001: { primary: Products.STANDARD, secondary: Products.VCS, third: Products.LPC },
		2002: { primary: Products.STANDARD, secondary: Products.VCS, third: Products.LPC },
		2003: { primary: Products.STANDARD, secondary: Products.VCS, third: Products.LPC },
		2004: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
		2005: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
		2006: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
		2007: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
		2008: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
		2009: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
		2010: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
		2011: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
		2012: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
		2013: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
		2014: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
		2015: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
		2016: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
		2017: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
		2018: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
		2019: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
		2020: { primary: Products.VCS, secondary: Products.LPC, third: Products.LONGLIFE },
		2021: { primary: Products.LPC, secondary: Products.LLC, third: Products.VCS },
		2022: { primary: Products.LPC, secondary: Products.LLC, third: Products.VCS },
		2023: { primary: Products.LPC, secondary: Products.LLC, third: Products.VCS },
	  },
  },
  {
    model: Cars.Chrysler,
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2002: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2003: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2004: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
      2005: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
      2006: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
      2007: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
      2008: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
      2009: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2010: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2011: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2012: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2013: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2014: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2015: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2016: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2017: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2018: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2019: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2020: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2021: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2022: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2023: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
    },
  },
  {
    model: Cars.Jeep,
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2002: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2003: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2004: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
      2005: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
      2006: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
      2007: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
      2008: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
      2009: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2010: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2011: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2012: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2013: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2014: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2015: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2016: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2017: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2018: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2019: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2020: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2021: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2022: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2023: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
    },
  },
  {
    model: Cars.Dodge,
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2002: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2003: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2004: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
      2005: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
      2006: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
      2007: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
      2008: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
      2009: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2010: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2011: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2012: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2013: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2014: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2015: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2016: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2017: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2018: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2019: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2020: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2021: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2022: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
      2023: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
    },
  },
  {
    model: Cars.RAM,
    data: {
		2000: { primary: Products.STANDARD },
		2001: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2002: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2003: { primary: Products.STANDARD, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2004: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
		2005: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
		2006: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
		2007: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
		2008: { primary: Products.LONGLIFE, secondary: Products.LLPRO, third: Products.VCS },
		2009: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2010: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2011: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2012: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2013: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2014: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2015: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2016: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2017: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2018: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2019: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2020: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2021: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2022: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
		2023: { primary: Products.VCS, secondary: Products.LONGLIFE, third: Products.LLPRO },
    },
  },
  {
    model: Cars.Volvo,
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD, secondary: Products.VCS },
      2002: { primary: Products.STANDARD, secondary: Products.VCS },
      2003: { primary: Products.STANDARD, secondary: Products.VCS },
      2004: { primary: Products.VC },
      2005: { primary: Products.VC },
      2006: { primary: Products.VC },
      2007: { primary: Products.VC },
      2008: { primary: Products.VC },
      2009: { primary: Products.VC },
      2010: { primary: Products.VC },
      2011: { primary: Products.VC },
      2012: { primary: Products.VC },
      2013: { primary: Products.VC },
      2014: { primary: Products.VC },
      2015: { primary: Products.VC },
      2016: { primary: Products.VC },
      2017: { primary: Products.VC },
      2018: { primary: Products.VC },
      2019: { primary: Products.VC },
      2020: { primary: Products.VC },
      2021: { primary: Products.VC },
      2022: { primary: Products.VC },
      2023: { primary: Products.VC },
    },
  },
  {
    model: Cars.Tesla,
    data: {
      2000: { primary: Products.NONE },
      2001: { primary: Products.NONE },
      2002: { primary: Products.NONE },
      2003: { primary: Products.NONE },
      2004: { primary: Products.NONE },
      2005: { primary: Products.NONE },
      2006: { primary: Products.NONE },
      2007: { primary: Products.NONE },
      2008: { primary: Products.NONE },
      2009: { primary: Products.NONE },
      2010: { primary: Products.NONE },
      2011: { primary: Products.NONE },
      2012: { primary: Products.VC, secondary: Products.G12EVO },
      2013: { primary: Products.VC, secondary: Products.G12EVO },
      2014: { primary: Products.VC, secondary: Products.G12EVO },
      2015: { primary: Products.VC, secondary: Products.G12EVO },
      2016: { primary: Products.VC, secondary: Products.G12EVO },
      2017: { primary: Products.VC, secondary: Products.G12EVO },
      2018: { primary: Products.VC, secondary: Products.G12EVO },
      2019: { primary: Products.VC, secondary: Products.G12EVO },
      2020: { primary: Products.VC, secondary: Products.G12EVO },
      2021: { primary: Products.VC, secondary: Products.G12EVO },
      2022: { primary: Products.VC, secondary: Products.G12EVO },
      2023: { primary: Products.VC, secondary: Products.G12EVO },
    },
  },
];
