import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
// import styles from "./menu-views.module.scss";

const Feedback = ({ setIsProgressBarVisible }) => {
  useEffect(() => {
    setIsProgressBarVisible(false);

    return () => setIsProgressBarVisible(true);
  });

  return <div></div>;
};

export default Feedback;
