import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import styles from "./menu-views.module.scss";

const MixingGuide = ({ setIsProgressBarVisible }) => {
  useEffect(() => {
    setIsProgressBarVisible(false);

    return () => setIsProgressBarVisible(true);
  });

  return (
    <div className={styles["container"]}>
      <div className={styles["content"]}>
        <p>
          <FormattedMessage id="mixingguide.heading_1" />
        </p>
        <FormattedMessage id="mixingguide.paragraph_1" />
        <br />
        <br />
        <FormattedMessage id="mixingguide.paragraph_2" />
        <br />
        <br />
        <FormattedMessage id="mixingguide.paragraph_3" />
        <p>
          <FormattedMessage id="mixingguide.heading_2" />
        </p>
        <FormattedMessage id="mixingguide.paragraph_4" />
      </div>
    </div>
  );
};

export default MixingGuide;
