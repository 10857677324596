import React from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { Cars, Vans, Trucks, Bikes, Buses, Agris } from "../api/typings";
import { Selection } from "../components/Selection";

const getCategoryTyping: (
  category: any
) => typeof Cars | typeof Vans | typeof Trucks | typeof Bikes | typeof Buses | typeof Agris = (category) => {
  switch (category) {
    case "cars":
      return Cars;
    case "vans":
      return Vans;
    case "trucks":
      return Trucks;
    case "bikes":
      return Bikes;
    case "buses":
      return Buses;
    case "agris":
      return Agris;
    default:
      return Cars;
  }
};

export const VehicleBrandSelection = ({ category }) => {
  const intl = useIntl();
  const history = useHistory();

  const cat = getCategoryTyping(category);

  const brands = Object.keys(cat)
    .sort()
    .map((k) => cat[k as any]);

  const options = brands.map((brand) => ({ value: brand, label: brand }));

  const onChange = (option) => {
    if (!option) return;
    history.push(`/${category}/${option.value}`);
  };

  return (
    <Selection
      options={options}
      placeholder={intl.formatMessage({ id: `${category}Selection.header` })}
      onChange={onChange}
    />
  );
};
