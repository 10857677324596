import {
  FordVanMotors,
  MercedesVanMotors,
  Products,
  VanAndProductsData,
  Vans,
} from "./typings";

export const vansAndProductsData: VanAndProductsData[] = [
  {
    model: Vans.Citroen,
    data: {
      2000: { primary: Products.LLC, secondary: Products.STANDARD },
      2001: { primary: Products.LLC, secondary: Products.LONGLIFE },
      2002: { primary: Products.LLC, secondary: Products.LONGLIFE },
      2003: { primary: Products.LLC, secondary: Products.LONGLIFE },
      2004: { primary: Products.LLC, secondary: Products.LONGLIFE },
      2005: { primary: Products.LLC, secondary: Products.LONGLIFE },
      2006: { primary: Products.LLC, secondary: Products.LONGLIFE },
      2007: { primary: Products.LLC, secondary: Products.LONGLIFE },
      2008: { primary: Products.LLC, secondary: Products.LONGLIFE },
      2009: { primary: Products.LLC },
      2010: { primary: Products.LLC },
      2011: { primary: Products.LLC },
      2012: { primary: Products.LLC },
      2013: { primary: Products.LLC },
      2014: { primary: Products.LLC },
      2015: { primary: Products.LLC },
      2016: { primary: Products.LLC },
      2017: { primary: Products.LLC },
      2018: { primary: Products.LLC },
      2019: { primary: Products.LLC },
      2020: { primary: Products.LLC },
      2021: { primary: Products.LLC },
      2022: { primary: Products.LLC },
      2023: { primary: Products.LLC },
    },
  },
  // OBJECT ORER IS IMPORTANT FOR MOTOR BASED SELECTION
  {
    model: Vans.Ford,
    motors: [FordVanMotors.Ford],
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD, secondary: Products.LPC },
      2002: { primary: Products.STANDARD, secondary: Products.LPC },
      2003: { primary: Products.LPC, secondary: Products.LONGLIFE },
      2004: { primary: Products.LPC, secondary: Products.LONGLIFE },
      2005: { primary: Products.LPC, secondary: Products.LONGLIFE },
      2006: { primary: Products.LPC, secondary: Products.LONGLIFE },
      2007: { primary: Products.LPC, secondary: Products.LONGLIFE },
      2008: { primary: Products.LPC, secondary: Products.LONGLIFE },
      2009: { primary: Products.LPC, secondary: Products.LONGLIFE },
      2010: { primary: Products.LPC, secondary: Products.LONGLIFE },
      2011: { primary: Products.LPC, secondary: Products.LONGLIFE },
      2012: { primary: Products.LPC, secondary: Products.LONGLIFE },
      2013: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2014: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2015: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2016: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2017: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2018: { primary: Products.LPC, secondary: Products.VCS },
      2019: { primary: Products.LPC, secondary: Products.VCS },
      2020: { primary: Products.LPC, secondary: Products.LLC },
      2021: { primary: Products.LPC, secondary: Products.LLC },
      2022: { primary: Products.LPC, secondary: Products.LLC },
      2023: { primary: Products.LPC, secondary: Products.LLC },
    },
  },
  // OBJECT ORER IS IMPORTANT FOR MOTOR BASED SELECTION
  {
    model: Vans.Ford,
    motors: [FordVanMotors.Volkswagen],
    data: {
      2000: { primary: Products.NONE },
      2001: { primary: Products.NONE },
      2002: { primary: Products.NONE },
      2003: { primary: Products.NONE },
      2004: { primary: Products.NONE },
      2005: { primary: Products.NONE },
      2006: { primary: Products.NONE },
      2007: { primary: Products.NONE },
      2008: { primary: Products.NONE },
      2009: { primary: Products.NONE },
      2010: { primary: Products.NONE },
      2011: { primary: Products.NONE },
      2012: { primary: Products.NONE },
      2013: { primary: Products.NONE },
      2014: { primary: Products.NONE },
      2015: { primary: Products.NONE },
      2016: { primary: Products.NONE },
      2017: { primary: Products.NONE },
      2018: { primary: Products.NONE },
      2019: { primary: Products.NONE },
      2020: { primary: Products.NONE },
      2021: { primary: Products.NONE },
      2022: { primary: Products.G12EVO },
      2023: { primary: Products.G12EVO },
    },
  },
  {
    model: Vans.Peugeot,
    data: {
      2000: { primary: Products.LLC, secondary: Products.STANDARD },
      2001: { primary: Products.LLC, secondary: Products.LONGLIFE },
      2002: { primary: Products.LLC, secondary: Products.LONGLIFE },
      2003: { primary: Products.LLC, secondary: Products.LONGLIFE },
      2004: { primary: Products.LLC, secondary: Products.LONGLIFE },
      2005: { primary: Products.LLC, secondary: Products.LONGLIFE },
      2006: { primary: Products.LLC, secondary: Products.LONGLIFE },
      2007: { primary: Products.LLC },
      2008: { primary: Products.LLC },
      2009: { primary: Products.LLC },
      2010: { primary: Products.LLC },
      2011: { primary: Products.LLC },
      2012: { primary: Products.LLC },
      2013: { primary: Products.LLC },
      2014: { primary: Products.LLC },
      2015: { primary: Products.LLC },
      2016: { primary: Products.LLC },
      2017: { primary: Products.LLC },
      2018: { primary: Products.LLC },
      2019: { primary: Products.LLC },
      2020: { primary: Products.LLC },
      2021: { primary: Products.LLC },
      2022: { primary: Products.LLC },
      2023: { primary: Products.LLC },
    },
  },
  {
    model: Vans.Volkswagen,
    data: {
      2000: { primary: Products.LONGLIFE, secondary: Products.G12EVO },
      2001: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2002: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2003: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2004: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2005: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2006: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2007: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2008: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2009: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2010: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2011: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2012: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2013: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2014: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2015: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2016: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2017: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2018: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2019: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2020: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2021: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2022: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2023: { primary: Products.G12EVO, secondary: Products.MBG12 },
    },
  },
  {
    model: Vans.Renault,
    data: {
      2000: { primary: Products.LONGLIFE, secondary: Products.STANDARD },
      2001: { primary: Products.LPC, secondary: Products.LLC },
      2002: { primary: Products.LPC, secondary: Products.LLC },
      2003: { primary: Products.LPC, secondary: Products.LLC },
      2004: { primary: Products.LPC, secondary: Products.LLC },
      2005: { primary: Products.LPC, secondary: Products.LLC },
      2006: { primary: Products.LPC, secondary: Products.LLC },
      2007: { primary: Products.LPC, secondary: Products.LLC },
      2008: { primary: Products.LPC, secondary: Products.LLC },
      2009: { primary: Products.LPC, secondary: Products.LLC },
      2010: { primary: Products.LPC, secondary: Products.LLC },
      2011: { primary: Products.LPC, secondary: Products.LLC },
      2012: { primary: Products.LPC, secondary: Products.LLC },
      2013: { primary: Products.LPC, secondary: Products.LLC },
      2014: { primary: Products.LPC, secondary: Products.LLC },
      2015: { primary: Products.LPC, secondary: Products.LLC },
      2016: { primary: Products.LPC, secondary: Products.LLC },
      2017: { primary: Products.LPC, secondary: Products.LLC },
      2018: { primary: Products.LPC, secondary: Products.LLC },
      2019: { primary: Products.LPC, secondary: Products.LLC },
      2020: { primary: Products.LLC, secondary: Products.LPC },
      2021: { primary: Products.LLC, secondary: Products.LPC },
      2022: { primary: Products.LLC, secondary: Products.LPC },
      2023: { primary: Products.LLC, secondary: Products.LPC },
    },
  },
  // OBJECT ORER IS IMPORTANT FOR MOTOR BASED SELECTION
  {
    model: Vans["Mercedes-Benz"],
    motors: [
      MercedesVanMotors.M112,
      MercedesVanMotors.M113,
      MercedesVanMotors.M133,
      MercedesVanMotors.M137,
      MercedesVanMotors.M15x,
      MercedesVanMotors.M176,
      MercedesVanMotors.M177,
      MercedesVanMotors.M256,
      MercedesVanMotors.M260,
      MercedesVanMotors.M264,
      MercedesVanMotors.M266,
      MercedesVanMotors.M27x,
      MercedesVanMotors.M282,
      MercedesVanMotors.M285,
      MercedesVanMotors["OM 608"],
      MercedesVanMotors["OM 611"],
      MercedesVanMotors["OM 612"],
      MercedesVanMotors["OM 613"],
      MercedesVanMotors["OM 62x"],
      MercedesVanMotors["OM 639"],
      MercedesVanMotors["OM 640"],
      MercedesVanMotors["OM 642"],
      MercedesVanMotors["OM 651"],
      MercedesVanMotors["OM 654"],
      MercedesVanMotors["OM 656"],
    ],
    data: {
      2000: { primary: Products.STANDARD, secondary: Products.MBG12 },
      2001: { primary: Products.MBG12 },
      2002: { primary: Products.MBG12 },
      2003: { primary: Products.MBG12 },
      2004: { primary: Products.MBG12 },
      2005: { primary: Products.MBG12 },
      2006: { primary: Products.MBG12 },
      2007: { primary: Products.MBG12 },
      2008: { primary: Products.MBG12 },
      2009: { primary: Products.MBG12 },
      2010: { primary: Products.MBG12 },
      2011: { primary: Products.MBG12 },
      2012: { primary: Products.MBG12 },
      2013: { primary: Products.MBG12 },
      2014: { primary: Products.MBG12 },
      2015: { primary: Products.MBG12 },
      2016: { primary: Products.MBG12 },
      2017: { primary: Products.MBG12 },
      2018: { primary: Products.MBG12 },
      2019: { primary: Products.MBG12 },
      2020: { primary: Products.MBG12 },
      2021: { primary: Products.MBG12 },
      2022: { primary: Products.MBG12 },
      2023: { primary: Products.MBG12 },
    },
  },
  // OBJECT ORER IS IMPORTANT FOR MOTOR BASED SELECTION
  {
    model: Vans["Mercedes-Benz"],
    motors: [
      MercedesVanMotors["M200.71"],
      MercedesVanMotors["OM 607 (Citan)"],
      MercedesVanMotors.M281,
      MercedesVanMotors["OM 699.77 (X-Class)"],
    ],
    data: {
      2000: { primary: Products.NONE },
      2001: { primary: Products.NONE },
      2002: { primary: Products.NONE },
      2003: { primary: Products.NONE },
      2004: { primary: Products.NONE },
      2005: { primary: Products.NONE },
      2006: { primary: Products.NONE },
      2007: { primary: Products.NONE },
      2008: { primary: Products.NONE },
      2009: { primary: Products.NONE },
      2010: { primary: Products.NONE },
      2011: { primary: Products.NONE },
      2012: { primary: Products.LLC },
      2013: { primary: Products.LLC },
      2014: { primary: Products.LLC },
      2015: { primary: Products.LLC },
      2016: { primary: Products.LLC },
      2017: { primary: Products.LLC },
      2018: { primary: Products.LLC },
      2019: { primary: Products.LLC },
      2020: { primary: Products.LLC },
      2021: { primary: Products.LLC },
      2022: { primary: Products.LLC },
      2023: { primary: Products.LLC },
    },
  },
  {
    model: Vans.Toyota,
    data: {
      2000: { primary: Products.LLC, secondary: Products.SLLC },
      2001: { primary: Products.LLC, secondary: Products.SLLC },
      2002: { primary: Products.LLC, secondary: Products.SLLC },
      2003: { primary: Products.LLC, secondary: Products.SLLC },
      2004: { primary: Products.LLC, secondary: Products.SLLC },
      2005: { primary: Products.SLLC, secondary: Products.LLC },
      2006: { primary: Products.SLLC, secondary: Products.LLC },
      2007: { primary: Products.SLLC, secondary: Products.LLC },
      2008: { primary: Products.SLLC, secondary: Products.LLC },
      2009: { primary: Products.SLLC, secondary: Products.LLC },
      2010: { primary: Products.SLLC, secondary: Products.LLC },
      2011: { primary: Products.SLLC, secondary: Products.LLC },
      2012: { primary: Products.SLLC, secondary: Products.LLC },
      2013: { primary: Products.SLLC, secondary: Products.LLC },
      2014: { primary: Products.SLLC, secondary: Products.LLC },
      2015: { primary: Products.SLLC, secondary: Products.LLC },
      2016: { primary: Products.SLLC, secondary: Products.LLC },
      2017: { primary: Products.SLLC, secondary: Products.LLC },
      2018: { primary: Products.SLLC, secondary: Products.LLC },
      2019: { primary: Products.SLLC, secondary: Products.LLC },
      2020: { primary: Products.SLLC, secondary: Products.LLC },
      2021: { primary: Products.SLLC, secondary: Products.LLC },
      2022: { primary: Products.SLLC, secondary: Products.LLC },
      2023: { primary: Products.SLLC, secondary: Products.LLC },
    },
  },
  {
    model: Vans.Opel,
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD, secondary: Products.LPC },
      2002: { primary: Products.LONGLIFE, secondary: Products.LLPRO },
      2003: { primary: Products.LONGLIFE, secondary: Products.LLPRO },
      2004: { primary: Products.LONGLIFE, secondary: Products.LLPRO },
      2005: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2006: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2007: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2008: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2009: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2010: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2011: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2012: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2013: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2014: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2015: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2016: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2017: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2018: { primary: Products.LPC, secondary: Products.VCS },
      2019: { primary: Products.LPC, secondary: Products.VCS },
      2020: { primary: Products.LPC, secondary: Products.LLC },
      2021: { primary: Products.LPC, secondary: Products.LLC },
      2022: { primary: Products.LPC, secondary: Products.LLC },
      2023: { primary: Products.LPC, secondary: Products.LLC },
    },
  },
  {
    model: Vans.Fiat,
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD, secondary: Products.VCS },
      2002: { primary: Products.STANDARD, secondary: Products.VCS },
      2003: { primary: Products.STANDARD, secondary: Products.VCS },
      2004: { primary: Products.VCS, secondary: Products.LPC },
      2005: { primary: Products.VCS, secondary: Products.LPC },
      2006: { primary: Products.VCS, secondary: Products.LPC },
      2007: { primary: Products.VCS, secondary: Products.LPC },
      2008: { primary: Products.VCS, secondary: Products.LPC },
      2009: { primary: Products.VCS, secondary: Products.LPC },
      2010: { primary: Products.VCS, secondary: Products.LPC },
      2011: { primary: Products.VCS, secondary: Products.LPC },
      2012: { primary: Products.VCS, secondary: Products.LPC },
      2013: { primary: Products.VCS, secondary: Products.LPC },
      2014: { primary: Products.VCS, secondary: Products.LPC },
      2015: { primary: Products.VCS, secondary: Products.LPC },
      2016: { primary: Products.VCS, secondary: Products.LPC },
      2017: { primary: Products.VCS, secondary: Products.LPC },
      2018: { primary: Products.VCS, secondary: Products.LPC },
      2019: { primary: Products.VCS, secondary: Products.LPC },
      2020: { primary: Products.VCS, secondary: Products.LPC },
      2021: { primary: Products.LPC, secondary: Products.LLC },
      2022: { primary: Products.LPC, secondary: Products.LLC },
      2023: { primary: Products.LPC, secondary: Products.LLC },
    },
  },
  {
    model: Vans.Škoda,
    data: {
      2000: { primary: Products.LONGLIFE, secondary: Products.G12EVO },
      2001: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2002: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2003: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2004: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2005: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2006: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2007: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2008: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2009: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2010: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2011: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2012: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2013: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2014: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2015: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2016: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2017: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2018: {
        primary: Products.G12EVO /* todo secondary: Products.MBG12 purple V */,
      },
      2019: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2020: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2021: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2022: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2023: { primary: Products.G12EVO, secondary: Products.MBG12 },
    },
  },
  {
    model: Vans.Nissan,
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD, secondary: Products.LLC },
      2002: { primary: Products.STANDARD, secondary: Products.LLC },
      2003: { primary: Products.LLC, secondary: Products.STANDARD },
      2004: { primary: Products.LLC, secondary: Products.LPC },
      2005: { primary: Products.LLC, secondary: Products.LPC },
      2006: { primary: Products.LLC, secondary: Products.LPC },
      2007: { primary: Products.LLC, secondary: Products.LPC },
      2008: { primary: Products.LLC, secondary: Products.LPC },
      2009: { primary: Products.LLC, secondary: Products.LPC },
      2010: { primary: Products.LLC, secondary: Products.LPC },
      2011: { primary: Products.LLC, secondary: Products.LPC },
      2012: { primary: Products.LLC, secondary: Products.LPC },
      2013: { primary: Products.LLC, secondary: Products.LPC },
      2014: { primary: Products.LLC, secondary: Products.LPC },
      2015: { primary: Products.LLC, secondary: Products.LPC },
      2016: { primary: Products.LLC, secondary: Products.LPC },
      2017: { primary: Products.LLC, secondary: Products.LPC },
      2018: { primary: Products.LLC, secondary: Products.LPC },
      2019: { primary: Products.LLC, secondary: Products.LPC },
      2020: { primary: Products.LLC, secondary: Products.LPC },
      2021: { primary: Products.LLC, secondary: Products.LPC },
      2022: { primary: Products.LLC, secondary: Products.LPC },
      2023: { primary: Products.LLC, secondary: Products.LPC },
    },
  },
  {
    model: Vans.Iveco,
    data: {
      2000: { primary: Products.STANDARD, secondary: Products.VCS },
      2001: { primary: Products.VC, secondary: Products.VCS },
      2002: { primary: Products.VC, secondary: Products.VCS },
      2003: { primary: Products.VC, secondary: Products.VCS },
      2004: { primary: Products.VC, secondary: Products.VCS },
      2005: { primary: Products.VC, secondary: Products.VCS },
      2006: { primary: Products.VC, secondary: Products.VCS },
      2007: { primary: Products.VC, secondary: Products.VCS },
      2008: { primary: Products.VC, secondary: Products.VCS },
      2009: { primary: Products.VC, secondary: Products.VCS },
      2010: { primary: Products.VC, secondary: Products.VCS },
      2011: { primary: Products.VC, secondary: Products.VCS },
      2012: { primary: Products.VC, secondary: Products.VCS },
      2013: { primary: Products.VC, secondary: Products.VCS },
      2014: { primary: Products.VC, secondary: Products.VCS },
      2015: { primary: Products.VC, secondary: Products.VCS },
      2016: { primary: Products.VC, secondary: Products.VCS },
      2017: { primary: Products.VC, secondary: Products.VCS },
      2018: { primary: Products.VC, secondary: Products.VCS },
      2019: { primary: Products.VC, secondary: Products.VCS },
      2020: { primary: Products.VC, secondary: Products.VCS },
      2021: { primary: Products.VC, secondary: Products.VCS },
      2022: { primary: Products.VC, secondary: Products.VCS },
      2023: { primary: Products.VC, secondary: Products.VCS },
    },
  },
  {
    model: Vans.Mitsubishi,
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD, secondary: Products.LLC },
      2002: { primary: Products.STANDARD, secondary: Products.LLC },
      2003: { primary: Products.LLC, secondary: Products.STANDARD },
      2004: { primary: Products.LLC, secondary: Products.LPC },
      2005: { primary: Products.LLC, secondary: Products.LPC },
      2006: { primary: Products.LLC, secondary: Products.LPC },
      2007: { primary: Products.LLC, secondary: Products.LPC },
      2008: { primary: Products.LLC, secondary: Products.LPC },
      2009: { primary: Products.LLC, secondary: Products.LPC },
      2010: { primary: Products.LLC, secondary: Products.LPC },
      2011: { primary: Products.LLC, secondary: Products.LPC },
      2012: { primary: Products.LLC, secondary: Products.LPC },
      2013: { primary: Products.LLC, secondary: Products.LPC },
      2014: { primary: Products.LLC, secondary: Products.LPC },
      2015: { primary: Products.LLC, secondary: Products.LPC },
      2016: { primary: Products.LLC, secondary: Products.LPC },
      2017: { primary: Products.LLC, secondary: Products.LPC },
      2018: { primary: Products.LLC, secondary: Products.LPC },
      2019: { primary: Products.LLC, secondary: Products.LPC },
      2020: { primary: Products.LLC, secondary: Products.LPC },
      2021: { primary: Products.LLC, secondary: Products.LPC },
      2022: { primary: Products.LLC, secondary: Products.LPC },
      2023: { primary: Products.LLC, secondary: Products.LPC },
    },
  },
  {
    model: Vans.Isuzu,
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD, secondary: Products.LLC },
      2002: { primary: Products.STANDARD, secondary: Products.LLC },
      2003: { primary: Products.LLC, secondary: Products.STANDARD },
      2004: { primary: Products.LLC, secondary: Products.LPC },
      2005: { primary: Products.LLC, secondary: Products.LPC },
      2006: { primary: Products.LLC, secondary: Products.LPC },
      2007: { primary: Products.LLC, secondary: Products.LPC },
      2008: { primary: Products.LLC, secondary: Products.LPC },
      2009: { primary: Products.LLC, secondary: Products.LPC },
      2010: { primary: Products.LLC, secondary: Products.LPC },
      2011: { primary: Products.LLC, secondary: Products.LPC },
      2012: { primary: Products.LLC, secondary: Products.LPC },
      2013: { primary: Products.LLC, secondary: Products.LPC },
      2014: { primary: Products.LLC, secondary: Products.LPC },
      2015: { primary: Products.LLC, secondary: Products.LPC },
      2016: { primary: Products.LLC, secondary: Products.LPC },
      2017: { primary: Products.LLC, secondary: Products.LPC },
      2018: { primary: Products.LLC, secondary: Products.LPC },
      2019: { primary: Products.LLC, secondary: Products.LPC },
      2020: { primary: Products.LLC, secondary: Products.LPC },
      2021: { primary: Products.LLC, secondary: Products.LPC },
      2022: { primary: Products.LLC, secondary: Products.LPC },
      2023: { primary: Products.LLC, secondary: Products.LPC },
    },
  },
  {
    model: Vans.Mazda,
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD, secondary: Products.LLC },
      2002: { primary: Products.STANDARD, secondary: Products.LLC },
      2003: { primary: Products.LLC, secondary: Products.STANDARD },
      2004: { primary: Products.LLC, secondary: Products.LPC },
      2005: { primary: Products.LLC, secondary: Products.LPC },
      2006: { primary: Products.LLC, secondary: Products.LPC },
      2007: { primary: Products.LLC, secondary: Products.LPC },
      2008: { primary: Products.LLC, secondary: Products.LPC },
      2009: { primary: Products.LLC, secondary: Products.LPC },
      2010: { primary: Products.LLC, secondary: Products.LPC },
      2011: { primary: Products.LLC, secondary: Products.LPC },
      2012: { primary: Products.LLC, secondary: Products.LPC },
      2013: { primary: Products.LLC, secondary: Products.LPC },
      2014: { primary: Products.LLC, secondary: Products.LPC },
      2015: { primary: Products.LLC, secondary: Products.LPC },
      2016: { primary: Products.LLC, secondary: Products.LPC },
      2017: { primary: Products.LLC, secondary: Products.LPC },
      2018: { primary: Products.LLC, secondary: Products.LPC },
      2019: { primary: Products.LLC, secondary: Products.LPC },
      2020: { primary: Products.LLC, secondary: Products.LPC },
      2021: { primary: Products.LLC, secondary: Products.LPC },
      2022: { primary: Products.LLC, secondary: Products.LPC },
      2023: { primary: Products.LLC, secondary: Products.LPC },
    },
  },
  {
    model: Vans["MAN TGE"],
    data: {
      2000: { primary: Products.NONE },
      2001: { primary: Products.NONE },
      2002: { primary: Products.NONE },
      2003: { primary: Products.NONE },
      2004: { primary: Products.NONE },
      2005: { primary: Products.NONE },
      2006: { primary: Products.NONE },
      2007: { primary: Products.NONE },
      2008: { primary: Products.NONE },
      2009: { primary: Products.NONE },
      2010: { primary: Products.NONE },
      2011: { primary: Products.NONE },
      2012: { primary: Products.NONE },
      2013: { primary: Products.NONE },
      2014: { primary: Products.NONE },
      2015: { primary: Products.NONE },
      2016: { primary: Products.NONE },
      2017: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2018: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2019: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2020: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2021: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2022: { primary: Products.G12EVO, secondary: Products.MBG12 },
      2023: { primary: Products.G12EVO, secondary: Products.MBG12 },
    },
  },
  {
    model: Vans.RAM,
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD, secondary: Products.LONGLIFE },
      2002: { primary: Products.STANDARD, secondary: Products.LONGLIFE },
      2003: { primary: Products.STANDARD, secondary: Products.LONGLIFE },
      2004: { primary: Products.LONGLIFE, secondary: Products.LLPRO },
      2005: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2006: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2007: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2008: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2009: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2010: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2011: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2012: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2013: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2014: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2015: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2016: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2017: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2018: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2019: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2020: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2021: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2022: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2023: { primary: Products.VCS, secondary: Products.LONGLIFE },
    },
  },
  {
    model: Vans.Dodge,
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD, secondary: Products.LONGLIFE },
      2002: { primary: Products.STANDARD, secondary: Products.LONGLIFE },
      2003: { primary: Products.STANDARD, secondary: Products.LONGLIFE },
      2004: { primary: Products.LONGLIFE, secondary: Products.LLPRO },
      2005: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2006: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2007: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2008: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2009: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2010: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2011: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2012: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2013: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2014: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2015: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2016: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2017: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2018: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2019: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2020: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2021: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2022: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2023: { primary: Products.VCS, secondary: Products.LONGLIFE },
    },
  },
  {
    model: Vans.Hyundai,
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD, secondary: Products.LLC },
      2002: { primary: Products.STANDARD, secondary: Products.LLC },
      2003: { primary: Products.LLC, secondary: Products.STANDARD },
      2004: { primary: Products.LLC, secondary: Products.SLLC },
      2005: { primary: Products.LLC, secondary: Products.SLLC },
      2006: { primary: Products.LLC, secondary: Products.SLLC },
      2007: { primary: Products.LLC, secondary: Products.SLLC },
      2008: { primary: Products.LLC, secondary: Products.SLLC },
      2009: { primary: Products.LLC, secondary: Products.SLLC },
      2010: { primary: Products.LLC, secondary: Products.SLLC },
      2011: { primary: Products.LLC, secondary: Products.SLLC },
      2012: { primary: Products.LLC, secondary: Products.SLLC },
      2013: { primary: Products.LLC, secondary: Products.SLLC },
      2014: { primary: Products.LLC, secondary: Products.SLLC },
      2015: { primary: Products.LLC, secondary: Products.SLLC },
      2016: { primary: Products.LLC, secondary: Products.SLLC },
      2017: { primary: Products.LLC, secondary: Products.SLLC },
      2018: { primary: Products.LLC, secondary: Products.SLLC },
      2019: { primary: Products.LLC, secondary: Products.SLLC },
      2020: { primary: Products.LLC, secondary: Products.SLLC },
      2021: { primary: Products.LLC, secondary: Products.SLLC },
      2022: { primary: Products.LLC, secondary: Products.SLLC },
      2023: { primary: Products.LLC, secondary: Products.SLLC },
    },
  },
  {
    model: Vans.Kia,
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD, secondary: Products.LLC },
      2002: { primary: Products.STANDARD, secondary: Products.LLC },
      2003: { primary: Products.LLC, secondary: Products.STANDARD },
      2004: { primary: Products.LLC, secondary: Products.SLLC },
      2005: { primary: Products.LLC, secondary: Products.SLLC },
      2006: { primary: Products.LLC, secondary: Products.SLLC },
      2007: { primary: Products.LLC, secondary: Products.SLLC },
      2008: { primary: Products.LLC, secondary: Products.SLLC },
      2009: { primary: Products.LLC, secondary: Products.SLLC },
      2010: { primary: Products.LLC, secondary: Products.SLLC },
      2011: { primary: Products.LLC, secondary: Products.SLLC },
      2012: { primary: Products.LLC, secondary: Products.SLLC },
      2013: { primary: Products.LLC, secondary: Products.SLLC },
      2014: { primary: Products.LLC, secondary: Products.SLLC },
      2015: { primary: Products.LLC, secondary: Products.SLLC },
      2016: { primary: Products.LLC, secondary: Products.SLLC },
      2017: { primary: Products.LLC, secondary: Products.SLLC },
      2018: { primary: Products.LLC, secondary: Products.SLLC },
      2019: { primary: Products.LLC, secondary: Products.SLLC },
      2020: { primary: Products.LLC, secondary: Products.SLLC },
      2021: { primary: Products.LLC, secondary: Products.SLLC },
      2022: { primary: Products.LLC, secondary: Products.SLLC },
      2023: { primary: Products.LLC, secondary: Products.SLLC },
    },
  },
  {
    model: Vans.GMC,
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD, secondary: Products.LONGLIFE },
      2002: { primary: Products.STANDARD, secondary: Products.LONGLIFE },
      2003: { primary: Products.STANDARD, secondary: Products.LONGLIFE },
      2004: { primary: Products.LONGLIFE, secondary: Products.LLPRO },
      2005: { primary: Products.LONGLIFE, secondary: Products.LLPRO },
      2006: { primary: Products.LONGLIFE, secondary: Products.LLPRO },
      2007: { primary: Products.LONGLIFE, secondary: Products.LLPRO },
      2008: { primary: Products.LONGLIFE, secondary: Products.LLPRO },
      2009: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2010: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2011: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2012: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2013: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2014: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2015: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2016: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2017: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2018: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2019: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2020: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2021: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2022: { primary: Products.VCS, secondary: Products.LONGLIFE },
      2023: { primary: Products.VCS, secondary: Products.LONGLIFE },
    },
  },
  {
    model: Vans.FUSO,
    data: {
      2000: { primary: Products.STANDARD },
      2001: { primary: Products.STANDARD },
      2002: { primary: Products.STANDARD, secondary: Products.LLC },
      2003: { primary: Products.LLC },
      2004: { primary: Products.LLC },
      2005: { primary: Products.LLC },
      2006: { primary: Products.LLC },
      2007: { primary: Products.LLC },
      2008: { primary: Products.LLC },
      2009: { primary: Products.LLC },
      2010: { primary: Products.LLC },
      2011: { primary: Products.LLC },
      2012: { primary: Products.LLC },
      2013: { primary: Products.LLC },
      2014: { primary: Products.LLC },
      2015: { primary: Products.LLC },
      2016: { primary: Products.LLC },
      2017: { primary: Products.LLC },
      2018: { primary: Products.LLC },
      2019: { primary: Products.LLC },
      2020: { primary: Products.LLC },
      2021: { primary: Products.LLC },
      2022: { primary: Products.LLC },
      2023: { primary: Products.LLC },
    },
  },
];
