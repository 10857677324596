import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { Selection } from "../components/Selection";
import { BusEngines, Buses } from "../api/typings";

interface UrlProps {
  chosenBus?: string;
  chosenEuroStandard?: string;
}

export const BusEngineSelection = () => {
  const intl = useIntl();
  const history = useHistory();
  const { chosenBus, chosenEuroStandard } = useParams<UrlProps>();

  let engines: Array<String> = [];
  switch (chosenBus) {
    case Buses.Irizar:
      engines = [BusEngines["Paccar DAF"], BusEngines.Scania];
      break;
    case Buses.Isuzu:
      engines = [BusEngines.Isuzu, BusEngines.Cummins, BusEngines.FPT];
      break;
    case Buses.Solaris:
      engines = [BusEngines["Paccar DAF"], BusEngines.Cummins, BusEngines.MAN];
      break;
    case Buses.Solbus:
      engines = [
        BusEngines.FPT,
        BusEngines.Iveco,
        BusEngines.MAN,
        BusEngines.Cummins,
      ];
      break;
    case Buses.Temsa:
      engines = [BusEngines.DAF, BusEngines.Cummins, BusEngines.FPT];
      break;
  }

  const options = engines.map((engine) => ({ value: engine, label: engine }));

  const onChange = (option) => {
    if (!option) return;
    history.push(`/buses/${chosenBus}/${chosenEuroStandard}/${option.value}`);
  };

  return (
    <Selection
      options={options}
      placeholder={intl.formatMessage({ id: "busEngineSelection.header" })}
      onChange={onChange}
    />
  );
};
