import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Link, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import RecommendationStatus from "../components/RecommendationStatus";
import NotFound from "./NotFound";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "../styles/productmatch.module.scss";
import "../styles/slider.css";
import {
  Cars,
  Choices,
  BikeProducts,
  Products,
  Trucks,
  Bikes,
  Vans,
  MercedesVanMotors,
  FordVanMotors,
  Buses,
  BusEngines,
  Agris,
  AgriEngines,
  AgriMakes,
} from "../api/typings";
import { VehicleInfo } from "../components/VehicleInfo";
import { carsAndProductsData, years2023 } from "../api/carsAndProductsData";

import newG12evoImage from "../images/products/polar_kanister_G12evo_23.png";
import newLonglifeImage from "../images/products/polar_kanister_LL_23.png";
import newLpcImage from "../images/products/polar_kanister_LPC_23.png";
import newMbg12Image from "../images/products/polar_kanister_MBG12++_23.png";
import newSLLCImage from "../images/products/polar_kanister_SLLC_23.png";
import newStandardImage from "../images/products/polar_kanister_Standard_23.png";
import newVcImage from "../images/products/polar_kanister_VC_23.png";
import newVcsImage from "../images/products/polar_kanister_VCS-2_23.png";
import newLlcImage from "../images/products/polar_kanister_LLC_23.png";
import newLonglifeproImage from "../images/products/polar_kanister_LL_PRO_10L_23.png";

import { bikesAndProductsData } from "../api/bikesAndProductsData";
import {
  trucksAndProductsData,
  mercedesTruckAndProductData,
} from "../api/trucksAndProductsData";
import { vansAndProductsData } from "../api/vansAndProductsData";
import { busesAndProductsData } from "../api/busesAndProductsData";
import { agriAndProductsData } from "../api/agriAndProductsData";
/* import noImageWithText from "../images/nopicturewithtext.png"; */
/* import noImage from "../images/nopicture.png"; */

interface UrlProps {
  chosenCar?: string;
  chosenYear?: string;
  chosenBike?: string;
  chosenTruck?: string;
  chosenTruckEngine?: string;
  chosenVan?: string;
  vanChosenYear?: string;
  chosenVanMotor?: string;
  chosenBus?: string;
  chosenBusEngine?: string;
  chosenEuroStandard?: string;
  chosenAgri?: string;
  chosenAgriYear?: string;
  chosenAgriEngine?: string;
}

type ProductAndURLorImage = {
  [key in Products | BikeProducts]: string;
};

const ProductMatch: React.FC = () => {
  const {
    chosenCar,
    chosenYear,
    chosenBike,
    chosenTruck,
    chosenTruckEngine,
    chosenVan,
    vanChosenYear,
    chosenVanMotor,
    chosenBus,
    chosenBusEngine,
    chosenEuroStandard,
    chosenAgri,
    chosenAgriEngine,
    chosenAgriYear,
  } = useParams<UrlProps>();

  // setup state
  const [primaryProduct, setPrimaryProduct] = useState<any>();
  const [secondaryProduct, setSecondaryProduct] = useState<any>();
  const [thirdProduct, setThirdProduct] = useState<any>();
  const moreThanOneProduct = primaryProduct && secondaryProduct;
  const [currentSlide, setCurrentSlide] = useState(0);

  const urlChosenBus = chosenBus ? Buses[chosenBus] : null;
  const urlChosenEuroStandard = chosenEuroStandard ? chosenEuroStandard : null;
  const urlChosenBusEngine = chosenBusEngine
    ? BusEngines[chosenBusEngine]
    : null;
  const urlChosenCar = chosenCar ? Cars[chosenCar] : null;
  const urlChosenYear = chosenYear ? parseInt(chosenYear) : null;
  const urlChosenBike = chosenBike ? Bikes[chosenBike] : null;
  const urlChosenTruck = chosenTruck ? Trucks[chosenTruck] : null;
  const urlChosenTruckEngine = chosenTruckEngine ? chosenTruckEngine : null;
  const urlChosenVan = chosenVan ? Vans[chosenVan] : null;
  const urlVanChosenYear = vanChosenYear ? parseInt(vanChosenYear) : null;
  const urlChosenVanMotor: MercedesVanMotors | FordVanMotors | null =
    chosenVanMotor
      ? MercedesVanMotors[chosenVanMotor] || FordVanMotors[chosenVanMotor]
      : null;
  const urlChosenAgri = chosenAgri ? Agris[chosenAgri] : null;
  const urlChosenAgriEngine = chosenAgriEngine
    ? AgriEngines[chosenAgriEngine]
    : null;
  const urlChosenAgriYear = chosenAgriYear ? AgriMakes[chosenAgriYear] : null;

  /* todo something else when there's time */
  // restrict vertical scroll
  useEffect(() => {
    document.body.style.position = "fixed";

    return () => {
      document.body.style.position = "static";
    };
  }, []);

  // SET PRODUCTS
  useEffect(() => {
    // CAR
    if (urlChosenCar && urlChosenYear) {
      const matchData = carsAndProductsData.find(
        (cap) => cap.model === urlChosenCar
      );
      const yearExists = years2023.includes(urlChosenYear);

      if (matchData && yearExists) {
        const products: Choices = matchData.data[urlChosenYear];
        setPrimaryProduct(products.primary);
        setSecondaryProduct(products.secondary);
        setThirdProduct(products.third);
      }
      return;
    }

    // BIKE
    if (urlChosenBike) {
      const matchData = bikesAndProductsData.find(
        (bap) => bap.brand === urlChosenBike
      );

      if (matchData) {
        const products: Choices = matchData.data;
        setPrimaryProduct(products.primary);
        setSecondaryProduct(products.secondary);
      }
      return;
    }

    // TRUCK
    if (urlChosenTruck && urlChosenTruckEngine) {
      let matchData;
      const isMercedes = urlChosenTruck === "Mercedes-Benz";

      if (isMercedes) {
        matchData = mercedesTruckAndProductData.find(
          (map) => map.engine === urlChosenTruckEngine
        );
      } else {
        matchData = trucksAndProductsData.find(
          (tap) => tap.brand === urlChosenTruck
        );
      }

      if (matchData && isMercedes) {
        const products: Choices = matchData.data;
        setPrimaryProduct(products.primary);
        setSecondaryProduct(products.secondary);
        setThirdProduct(products.third);
      } else if (matchData) {
        const products: Choices = matchData.data[urlChosenTruckEngine];
        setPrimaryProduct(products.primary);
        setSecondaryProduct(products.secondary);
      }
      return;
    }

    // VAN
    if (urlChosenVan && urlVanChosenYear && urlChosenVanMotor) {
      const matchData = vansAndProductsData.find((vap) => {
        return (
          vap.motors &&
          vap.model === urlChosenVan &&
          vap.motors.includes(urlChosenVanMotor)
        );
      });

      const yearExists = years2023.includes(urlVanChosenYear);
      if (matchData && yearExists) {
        const products: Choices = matchData.data[urlVanChosenYear];
        setPrimaryProduct(products.primary);
        setSecondaryProduct(products.secondary);
      }
      return;
    }

    if (urlChosenVan && urlVanChosenYear) {
      const matchData = vansAndProductsData.find(
        (vap) => vap.model === urlChosenVan
      );

      const yearExists = years2023.includes(urlVanChosenYear);
      if (matchData && yearExists) {
        const product: Choices = matchData.data[urlVanChosenYear];
        setPrimaryProduct(product.primary);
        setSecondaryProduct(product.secondary);
      }
      return;
    }

    // BUS
    if (urlChosenBus && urlChosenEuroStandard) {
      let matchData: Choices;
      if (urlChosenBusEngine) {
        matchData = busesAndProductsData.find(
          (bap) =>
            bap.brand === urlChosenBus &&
            bap.engine?.includes(BusEngines[urlChosenBusEngine])
        )?.data[urlChosenEuroStandard];
      } else {
        matchData = busesAndProductsData.find(
          (bap) => bap.brand === urlChosenBus
        )?.data[urlChosenEuroStandard];
      }

      setPrimaryProduct(matchData.primary);
      setSecondaryProduct(matchData.secondary);
    }

    // AGRI
    if (urlChosenAgri && urlChosenAgriYear) {
      let matchData;

      if (urlChosenAgriEngine) {
        matchData = agriAndProductsData.find(
          (aap) =>
            aap.brand === urlChosenAgri &&
            aap.engines.includes(urlChosenAgriEngine)
        )?.data[urlChosenAgriYear];
      } else {
        matchData = agriAndProductsData.find(
          (aap) => aap.brand === urlChosenAgri
        )?.data[urlChosenAgriYear];
      }

      setPrimaryProduct(matchData.primary);
      setSecondaryProduct(matchData.secondary);
      setThirdProduct(matchData.third);
    }
  }, [
    urlChosenCar,
    urlChosenYear,
    urlChosenBike,
    urlChosenTruck,
    urlChosenTruckEngine,
    urlChosenVan,
    urlVanChosenYear,
    urlChosenVanMotor,
    urlChosenBus,
    urlChosenEuroStandard,
    urlChosenBusEngine,
    urlChosenAgri,
    urlChosenAgriEngine,
    urlChosenAgriYear,
  ]);

  // SET IMAGES
  const primaryProductImage = productNameToImage[primaryProduct];
  const secondaryProductImage =
    secondaryProduct && productNameToImage[secondaryProduct];
  const thirdProductImage = productNameToImage[thirdProduct];

  const sliderItem = (productName, productImage) => (
    <div className={styles["slider-item"]}>
      <div className={styles["slider-item-text"]}>
        <h2>
          <div>{productName}</div>
        </h2>
      </div>
      <img className={styles["img"]} alt="Product" src={productImage} />
      <div className={styles["linkDiv"]}>
        <Link to={`/${productNameToUrl[productName]}`}>
          <div className={styles["slider-item-text read-more"]}>
            <button>
              <FormattedMessage id=".readmore" defaultMessage="Read more" />
            </button>
          </div>
        </Link>
      </div>
    </div>
  );

  const renderProducts = () => {
    const sliderSettings = {
      infinite: false,
      speed: 500,
      accessibility: true,
      arrows: true,
    };

    if (primaryProduct && !secondaryProduct) {
      return sliderItem(primaryProduct, primaryProductImage);
    }
    if (moreThanOneProduct) {
      return (
        <div className={styles["slider-container"]}>
          <Slider
            beforeChange={(oldIndex, newIndex) => {
              setCurrentSlide(newIndex);
            }}
            {...sliderSettings}
          >
            {sliderItem(primaryProduct, primaryProductImage)}
            {sliderItem(secondaryProduct, secondaryProductImage)}
            {thirdProduct && sliderItem(thirdProduct, thirdProductImage)}
          </Slider>
        </div>
      );
    }
    return <NotFound />;
  };

  return (
    <>
      <VehicleInfo
        carModel={urlChosenCar}
        year={urlChosenYear}
        bikeBrand={urlChosenBike}
        truckBrand={urlChosenTruck}
        truckEngine={urlChosenTruckEngine}
        vanModel={urlChosenVan}
        vanYear={urlVanChosenYear}
        vanMotor={urlChosenVanMotor}
        busBrand={urlChosenBus}
        busEuroStandard={chosenEuroStandard}
        busEngine={urlChosenBusEngine}
        agriBrand={urlChosenAgri}
        agriEngine={urlChosenAgriEngine}
        agriMake={urlChosenAgriYear}
      />
      <RecommendationStatus currentSlide={currentSlide} />
      <div className={styles["container"]}>
        <div>{renderProducts()}</div>
      </div>
    </>
  );
};

const productNameToUrl: ProductAndURLorImage = {
  "Polar Premium Long-Life G12evo -37°C": "g12evo",
  "Polar Premium Long-Life LLC -37°C": "llc",
  "Polar Premium Long-Life -37°C": "longlife",
  "Polar Premium Long-Life PRO -37°C": "longlifepro",
  "Polar Premium Long-Life MB/G12++ -37°C": "mbg12",
  "Polar Standard -37°C": "standard",
  "Polar Premium Long-Life VC -37°C": "vc",
  "Polar Premium Long-Life VCS-2 -37°C": "vcs",
  "Polar Premium Long-Life SLLC -37°C": "sllc",
  "Polar Premium Long-Life LPC -37°C": "lpc",
  "Freecor EV Milli 10 -37°C": "freecor",
  NONE: "",
};

const productNameToImage: ProductAndURLorImage = {
  "Polar Premium Long-Life G12evo -37°C": newG12evoImage,
  "Polar Premium Long-Life LLC -37°C": newLlcImage,
  "Polar Premium Long-Life -37°C": newLonglifeImage,
  "Polar Premium Long-Life PRO -37°C": newLonglifeproImage,
  "Polar Premium Long-Life MB/G12++ -37°C": newMbg12Image,
  "Polar Standard -37°C": newStandardImage,
  "Polar Premium Long-Life VC -37°C": newVcImage,
  "Polar Premium Long-Life VCS-2 -37°C": newVcsImage,
  "Polar Premium Long-Life SLLC -37°C": newSLLCImage,
  "Polar Premium Long-Life LPC -37°C": newLpcImage,
  "Freecor EV Milli 10 -37°C": "",
  NONE: "",
};

export const productAbbreviationToImage = {
  longlife: newLonglifeImage,
  longlifepro: newLonglifeproImage,
  sllc: newSLLCImage,
  mbg12: newMbg12Image,
  g12evo: newG12evoImage,
  vcs: newVcsImage,
  lpc: newLpcImage,
  llc: newLlcImage,
  vc: newVcImage,
  standard: newStandardImage,
};

export default ProductMatch;
