import { BusAndProductsData, Products, Buses, BusEngines } from "./typings";

export const busesAndProductsData: BusAndProductsData[] = [
  {
    brand: Buses.BMC,
    data: {
      "<Euro 2": { primary: Products.STANDARD },
      "Euro 3": { primary: Products.LLPRO, secondary: Products.VCS },
      "Euro 4": { primary: Products.LLPRO, secondary: Products.VCS },
      "Euro 5": { primary: Products.MBG12 },
      EEV: { primary: Products.MBG12 },
      "Euro 6": { primary: Products.MBG12 },
    },
  },
  {
    brand: Buses.DAF,
    data: {
      "<Euro 2": { primary: Products.STANDARD },
      "Euro 3": { primary: Products.LLPRO, secondary: Products.VCS },
      "Euro 4": { primary: Products.LLPRO, secondary: Products.VCS },
      "Euro 5": { primary: Products.LLPRO, secondary: Products.VCS },
      EEV: { primary: Products.LLPRO, secondary: Products.VCS },
      "Euro 6": { primary: Products.LLPRO, secondary: Products.VCS },
    },
  },
  {
    brand: Buses.FUSO,
    data: {
      "<Euro 2": { primary: Products.STANDARD },
      "Euro 3": { primary: Products.LLC },
      "Euro 4": { primary: Products.LLC },
      "Euro 5": { primary: Products.LLC },
      EEV: { primary: Products.LLC },
      "Euro 6": { primary: Products.LLC },
    },
  },
  {
    brand: Buses.IVECO,
    data: {
      "<Euro 2": { primary: Products.STANDARD },
      "Euro 3": { primary: Products.VC, secondary: Products.VCS },
      "Euro 4": { primary: Products.VC, secondary: Products.VCS },
      "Euro 5": { primary: Products.VC, secondary: Products.VCS },
      EEV: { primary: Products.VC, secondary: Products.VCS },
      "Euro 6": { primary: Products.VC, secondary: Products.VCS },
    },
  },
  {
    brand: Buses.Irizar,
    engine: [BusEngines["Paccar DAF"]],
    data: {
      "<Euro 2": { primary: Products.STANDARD },
      "Euro 3": { primary: Products.LLPRO, secondary: Products.VCS },
      "Euro 4": { primary: Products.LLPRO, secondary: Products.VCS },
      "Euro 5": { primary: Products.LLPRO, secondary: Products.VCS },
      EEV: { primary: Products.LLPRO, secondary: Products.VCS },
      "Euro 6": { primary: Products.LLPRO, secondary: Products.VCS },
    },
  },
  {
    brand: Buses.Irizar,
    engine: [BusEngines.Scania],
    data: {
      "<Euro 2": { primary: Products.STANDARD },
      "Euro 3": { primary: Products.LLPRO, secondary: Products.MBG12 },
      "Euro 4": { primary: Products.LLPRO, secondary: Products.MBG12 },
      "Euro 5": { primary: Products.MBG12 },
      EEV: { primary: Products.MBG12 },
      "Euro 6": { primary: Products.MBG12 },
    },
  },
  {
    brand: Buses.Isuzu,
    engine: [BusEngines.Isuzu],
    data: {
      "<Euro 2": { primary: Products.STANDARD },
      "Euro 3": { primary: Products.LLC },
      "Euro 4": { primary: Products.LLC },
      "Euro 5": { primary: Products.LLC },
      EEV: { primary: Products.LLC },
      "Euro 6": { primary: Products.LLC },
    },
  },
  {
    brand: Buses.Isuzu,
    engine: [BusEngines.Cummins],
    data: {
      "<Euro 2": { primary: Products.STANDARD },
      "Euro 3": { primary: Products.MBG12, secondary: Products.LLPRO },
      "Euro 4": { primary: Products.MBG12, secondary: Products.LLPRO },
      "Euro 5": { primary: Products.MBG12 },
      EEV: { primary: Products.MBG12 },
      "Euro 6": { primary: Products.MBG12 },
    },
  },
  {
    brand: Buses.Isuzu,
    engine: [BusEngines.FPT],
    data: {
      "<Euro 2": { primary: Products.STANDARD },
      "Euro 3": { primary: Products.VCS },
      "Euro 4": { primary: Products.VCS },
      "Euro 5": { primary: Products.VCS },
      EEV: { primary: Products.VCS },
      "Euro 6": { primary: Products.VCS },
    },
  },
  {
    brand: Buses.MAZ,
    data: {
      "<Euro 2": { primary: Products.STANDARD },
      "Euro 3": { primary: Products.VC, secondary: Products.VCS },
      "Euro 4": { primary: Products.VC, secondary: Products.VCS },
      "Euro 5": { primary: Products.VC, secondary: Products.VCS },
      EEV: null,
      "Euro 6": null,
    },
  },
  {
    brand: Buses.MAN,
    data: {
      "<Euro 2": { primary: Products.STANDARD },
      "Euro 3": { primary: Products.LLPRO, secondary: Products.MBG12 },
      "Euro 4": { primary: Products.LLPRO, secondary: Products.MBG12 },
      "Euro 5": { primary: Products.LLPRO },
      EEV: { primary: Products.LLPRO },
      "Euro 6": { primary: Products.MBG12 },
    },
  },
  {
    brand: Buses.NEOPLAN,
    data: {
      "<Euro 2": { primary: Products.STANDARD },
      "Euro 3": { primary: Products.LLPRO, secondary: Products.MBG12 },
      "Euro 4": { primary: Products.LLPRO, secondary: Products.MBG12 },
      "Euro 5": { primary: Products.LLPRO },
      EEV: { primary: Products.LLPRO },
      "Euro 6": { primary: Products.MBG12 },
    },
  },
  {
    brand: Buses["Mercedes-Benz"],
    data: {
      "<Euro 2": { primary: Products.STANDARD },
      "Euro 3": { primary: Products.LLPRO, secondary: Products.MBG12 },
      "Euro 4": { primary: Products.LLPRO, secondary: Products.MBG12 },
      "Euro 5": { primary: Products.MBG12 },
      EEV: { primary: Products.MBG12 },
      "Euro 6": { primary: Products.MBG12 },
    },
  },
  {
    brand: Buses.NeoLAZ,
    data: {
      "<Euro 2": { primary: Products.STANDARD },
      "Euro 3": { primary: Products.LLPRO, secondary: Products.MBG12 },
      "Euro 4": null,
      "Euro 5": null,
      EEV: null,
      "Euro 6": null,
    },
  },
  {
    brand: Buses.Scania,
    data: {
      "<Euro 2": { primary: Products.STANDARD },
      "Euro 3": { primary: Products.LLPRO, secondary: Products.MBG12 },
      "Euro 4": { primary: Products.LLPRO, secondary: Products.MBG12 },
      "Euro 5": { primary: Products.MBG12 },
      EEV: { primary: Products.MBG12 },
      "Euro 6": { primary: Products.MBG12 },
    },
  },
  {
    brand: Buses.Setra,
    data: {
      "<Euro 2": { primary: Products.STANDARD },
      "Euro 3": { primary: Products.LLPRO },
      "Euro 4": { primary: Products.LLPRO },
      "Euro 5": { primary: Products.MBG12 },
      EEV: { primary: Products.MBG12 },
      "Euro 6": { primary: Products.MBG12 },
    },
  },
  {
    brand: Buses.Solaris,
    engine: [BusEngines["Paccar DAF"]],
    data: {
      "<Euro 2": { primary: Products.STANDARD },
      "Euro 3": { primary: Products.LLPRO, secondary: Products.VCS },
      "Euro 4": { primary: Products.LLPRO, secondary: Products.VCS },
      "Euro 5": { primary: Products.LLPRO, secondary: Products.VCS },
      EEV: { primary: Products.LLPRO, secondary: Products.VCS },
      "Euro 6": { primary: Products.LLPRO, secondary: Products.VCS },
    },
  },
  {
    brand: Buses.Solaris,
    engine: [BusEngines.Cummins, BusEngines.MAN],
    data: {
      "<Euro 2": { primary: Products.STANDARD },
      "Euro 3": { primary: Products.LLPRO },
      "Euro 4": { primary: Products.LLPRO },
      "Euro 5": { primary: Products.LLPRO },
      EEV: { primary: Products.MBG12 },
      "Euro 6": { primary: Products.MBG12 },
    },
  },
  {
    brand: Buses.Solbus,
    engine: [BusEngines.FPT, BusEngines.Iveco],
    data: {
      "<Euro 2": { primary: Products.STANDARD },
      "Euro 3": { primary: Products.VCS },
      "Euro 4": { primary: Products.VCS },
      "Euro 5": { primary: Products.VCS },
      EEV: { primary: Products.VCS },
      "Euro 6": { primary: Products.VCS },
    },
  },
  {
    brand: Buses.Solbus,
    engine: [BusEngines.Cummins, BusEngines.MAN],
    data: {
      "<Euro 2": { primary: Products.STANDARD },
      "Euro 3": { primary: Products.LLPRO, secondary: Products.MBG12 },
      "Euro 4": { primary: Products.LLPRO, secondary: Products.MBG12 },
      "Euro 5": { primary: Products.MBG12 },
      EEV: { primary: Products.MBG12 },
      "Euro 6": { primary: Products.MBG12 },
    },
  },
  {
    brand: Buses.SOR,
    data: {
      "<Euro 2": { primary: Products.STANDARD },
      "Euro 3": { primary: Products.VCS },
      "Euro 4": { primary: Products.VCS },
      "Euro 5": { primary: Products.VCS },
      EEV: { primary: Products.VCS },
      "Euro 6": { primary: Products.VCS },
    },
  },
  {
    brand: Buses.Temsa,
    engine: [BusEngines.DAF],
    data: {
      "<Euro 2": { primary: Products.STANDARD },
      "Euro 3": { primary: Products.LLPRO, secondary: Products.VCS },
      "Euro 4": { primary: Products.LLPRO, secondary: Products.VCS },
      "Euro 5": { primary: Products.LLPRO, secondary: Products.VCS },
      EEV: { primary: Products.LLPRO, secondary: Products.VCS },
      "Euro 6": { primary: Products.LLPRO, secondary: Products.VCS },
    },
  },
  {
    brand: Buses.Temsa,
    engine: [BusEngines.Cummins],
    data: {
      "<Euro 2": { primary: Products.STANDARD },
      "Euro 3": { primary: Products.MBG12, secondary: Products.LLPRO },
      "Euro 4": { primary: Products.MBG12, secondary: Products.LLPRO },
      "Euro 5": { primary: Products.MBG12 },
      EEV: { primary: Products.MBG12 },
      "Euro 6": { primary: Products.MBG12 },
    },
  },
  {
    brand: Buses.Temsa,
    engine: [BusEngines.FPT],
    data: {
      "<Euro 2": { primary: Products.STANDARD },
      "Euro 3": { primary: Products.VCS, secondary: Products.LLPRO },
      "Euro 4": { primary: Products.VCS, secondary: Products.LLPRO },
      "Euro 5": { primary: Products.VCS, secondary: Products.LLPRO },
      EEV: { primary: Products.VCS, secondary: Products.LLPRO },
      "Euro 6": { primary: Products.VCS },
    },
  },
  {
    brand: Buses["Van Hool"],
    data: {
      "<Euro 2": { primary: Products.STANDARD },
      "Euro 3": { primary: Products.LLPRO, secondary: Products.VCS },
      "Euro 4": { primary: Products.LLPRO, secondary: Products.VCS },
      "Euro 5": { primary: Products.LLPRO, secondary: Products.VCS },
      EEV: { primary: Products.LLPRO, secondary: Products.VCS },
      "Euro 6": { primary: Products.LLPRO, secondary: Products.VCS },
    },
  },
  {
    brand: Buses.Volvo,
    data: {
      "<Euro 2": { primary: Products.STANDARD },
      "Euro 3": { primary: Products.VCS },
      "Euro 4": { primary: Products.VCS },
      "Euro 5": { primary: Products.VCS },
      EEV: { primary: Products.VCS },
      "Euro 6": { primary: Products.VCS },
    },
  },
  {
    brand: Buses["HIGER Bus"],
    data: {
      "<Euro 2": { primary: Products.STANDARD },
      "Euro 3": { primary: Products.LLPRO },
      "Euro 4": { primary: Products.LLPRO },
      "Euro 5": { primary: Products.MBG12 },
      EEV: { primary: Products.MBG12 },
      "Euro 6": { primary: Products.MBG12 },
    },
  },
];
