import React from "react";
import Select from "react-select";
import { isMobileSafari } from "react-device-detect";

interface SelectionProps {
  options: any;
  placeholder: string;
  onChange: any;
}

export const Selection: React.FC<SelectionProps> = ({
  options,
  placeholder,
  onChange,
}) => {
  const styles = {
    container: (provided, state) => ({
      ...provided,
      color: "#c6b791",
      width: "90%",
      margin: "auto",
      fontSize: "21px",
    }),
    control: (provided, state) => ({
      ...provided,
      background: "rgba(1, 1, 1, 0.25)",
      color: "white",
      margin: "20px 0",
      height: "50px",
      fontSize: "16px",
    }),
    input: (provided, state) => ({
      ...provided,
      color: "white",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#ccc",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "#ccc",
    }),
    menu: (provided, state) => ({
      ...provided,
      background: "rgba(1, 1, 1, 0.25);",
    }),
    menuList: (provided, state) => ({
      ...provided,
      height: "400px",
    }),
    option: (provided, state) => ({
      ...provided,
      height: "50px",
      borderBottom: ".1px solid #c6b791",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      background: state.isFocused ? "rgba(1, 1, 1, 0.2);" : "none",
      "&:hover": {
        background: "rgba(1, 1, 1, 0.1);",
      },
      "&:last-child": {
        border: "none",
      },
    }),
  };

  return (
    <Select
      styles={styles}
      options={options}
      menuIsOpen
      autoFocus={!isMobileSafari}
      placeholder={placeholder}
      onChange={onChange}
      /* onMenuOpen={} */
      className="react-select"
      classNamePrefix="react-select"
    />
  );
};
