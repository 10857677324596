import React from "react";
import classnames from "classnames";
import styles from "../styles/hexagon.module.scss";
import carLogo from "../images/car_flat_gold.png";
import vanLogo from "../images/van_flat_gold.png";
import busLogo from "../images/bus_flat_gold.png";
import truckLogo from "../images/truck_flat_gold.png";
import agriLogo from "../images/tractor_flat_gold.png";
import constLogo from "../images/dumper_gold.png";
import liftLogo from "../images/forklift_gold.png";
import bikeLogo from "../images/bike_gold.png";
import otherLogo from "../images/boat_gold.png";

interface Props {
  vehicle: string;
  dim?: boolean;
  solo?: boolean;
  onFocusStart: () => void;
  onFocusEnd: () => void;
}

export const Hexagon: React.FC<Props> = ({
  vehicle,
  dim = false,
  solo,
  onFocusStart,
  onFocusEnd,
}) => {
  return (
    <div className={classnames(styles["item"], { [styles["item-dim"]]: dim })}>
      <div
        className={classnames(styles["hexagon"], {
          [styles["hexagon-solo"]]: solo,
        })}
        onTouchStart={() => setTimeout(onFocusStart, 100)}
        onTouchEnd={() => setTimeout(onFocusEnd, 100)}
        onMouseDown={() => {
          setTimeout(onFocusStart, 100);
        }}
        onMouseUp={() => setTimeout(onFocusEnd, 100)}
      >
        <div className={styles["img-and-type"]}>
          <img
            className={styles["vehicle-img"]}
            alt={vehicle}
            src={getVehicleImage(vehicle)}
          />
        </div>
      </div>
    </div>
  );
};

function getVehicleImage(vehicleName) {
  switch (vehicleName) {
    case "trucks":
      return truckLogo;
    case "agri":
      return agriLogo;
    case "vans":
      return vanLogo;
    case "cars":
      return carLogo;
    case "buses":
      return busLogo;
    case "const":
      return constLogo;
    case "lifting":
      return liftLogo;
    case "motorcycles":
      return bikeLogo;
    case "other":
      return otherLogo;
  }
}
