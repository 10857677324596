import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import styles from "./menu-views.module.scss";

const Standards = ({ setIsProgressBarVisible }) => {
  useEffect(() => {
    setIsProgressBarVisible(false);

    return () => setIsProgressBarVisible(true);
  });

  return (
    <div className={styles["container"]}>
      <div className={styles["content"]}>
        <p>
          <FormattedMessage id="standards.heading_1" />
        </p>
        <FormattedMessage id="standards.paragraph_1" />
        {/* <p>
          <FormattedMessage id="standards.heading_2" />
        </p> */}
      </div>
    </div>
  );
};

export default Standards;
