import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { ProductAbbreviationsToFullName } from "../../api/typings";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import styles from "../../styles/productsView.module.scss";
import { productAbbreviationToImage } from "../ProductMatch";
import "react-accessible-accordion/dist/fancy-example.css";

export const ProductsView = () => {
  const intl = useIntl();
  const [products, setProducts] = useState<any>();

  const productAbbreviations = [
    "longlife",
    "longlifepro",
    "sllc",
    "mbg12",
    "g12evo",
    "vcs",
    "lpc",
    "llc",
    "vc",
    "standard",
    "freecor",
  ];

  useEffect(() => {
    fetch("/api/products")
      .then((res) => res.json())
      .then((data) => setProducts(data));
  }, []);

  return (
    <div>
      {products && (
        <Accordion allowZeroExpanded>
          {productAbbreviations.map((abbreviation) => {
            return (
              <div>
                <AccordionItem
                  key={abbreviation}
                  className={styles["accordion__item"]}
                >
                  <AccordionItemHeading>
                    <AccordionItemButton
                      className={styles["accordion__button"]}
                    >
                      {ProductAbbreviationsToFullName[abbreviation]}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className={styles["accordion__panel"]}>
                    <img
                      alt="Product"
                      src={productAbbreviationToImage[abbreviation]}
                      className={styles["product__image"]}
                    />
                    {products[abbreviation][intl.locale].map((el) => (
                      <div key={el}>&#8226; {el}</div>
                    ))}
                  </AccordionItemPanel>
                </AccordionItem>
              </div>
            );
          })}
        </Accordion>
      )}
    </div>
  );
};
