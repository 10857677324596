import React, { useState } from "react";
import "../styles/polarThermCalculator.css";

export const PolarThermCalculator = () => {
  const [maht, setMaht] = useState("");
  const [hetkene, setHetkene] = useState("");
  const [soovitav, setSoovitav] = useState("");

  const onButtonClick = () => {
    setMaht(document.getElementById("uno").value);
    setHetkene(document.getElementById("dos").value);
    setSoovitav(document.getElementById("tres").value);
  };

  const calculate = (maht, hetkene, soovitav) => {
    let tulemus;
    tulemus = (0.01 * maht * (soovitav - hetkene)) / (1 - 0.01 * hetkene);
    tulemus = Math.round(tulemus * 100) / 100;

    if (maht === "" || hetkene === "" || soovitav === "") {
      return null;
    }

    return <span className="result-calc">{tulemus} (L/л);</span>;
  };

  return (
    <div className="container">
      <div className="polartherm-title">POLARTHERM CALCULATOR</div>

      <div className="label-and-input">
        <div>
          <div>Süsteemi maht (L):</div>
          <div>Объем системы (л):</div>
          <div>System Volume (L):</div>
        </div>
        <input id="uno" type="text" />
      </div>

      <div className="label-and-input">
        <div>
          <div>Hetkene kontsentratsioon süsteemis (%):</div>
          <div>Текущая концентрация в системе (%):</div>
          <div>Current concentration in the system (%):</div>
        </div>
        <input id="dos" type="text" />
      </div>

      <div className="label-and-input">
        <div>
          <div>Soovitav kontsentratsioon (%):</div>
          <div>Рекомендуемая концентрация (%):</div>
          <div>Recommended concentration (%):</div>
        </div>
        <input id="tres" type="text" />
      </div>

      <div className="calculation">
        <div className="calculate-info">
          <div>
            Leia kogus, mis tuleb süsteemist välja valada ja asendada Polartherm
            100% kontsentraadiga, et tõste olemasolevat kontsentratsiooni või
            välja lasta ja asendada veega, et alandada olemasolevat
            kontsentratsiooni.
          </div>
          <div>
            Найдите количество, которое необходимо слить из системы и заменить
            100% концентратом Polartherm, чтобы увеличить существующую
            концентрацию, или слить и заменить водой, чтобы снизить существующую
            концентрацию.
          </div>
          <div>
            Find the volume that needs to be drained from the system and
            replaced with Polartherm 100% concentrate to increase the existing
            concentration, or drain and replace with water to lower the existing
            concentration.
          </div>
        </div>

        <button className="calc-btn" onClick={() => onButtonClick()}>
          Arvuta | Рассчитайте | Calculate
        </button>

        <div className="result-container">
          {calculate(maht, hetkene, soovitav)}
        </div>
      </div>

      <div className="nota-bene">
        <div>Ära sega erinevaid soojusvahetusvedelikke omavahel.</div>
        <div>Не смешивайте разные теплоносители.</div>
        <div>Do not mix different types of heat transfer fluids.</div>
      </div>
    </div>
  );
};
