// @ts-ignore
import React from "react"; // @ts-ignore
import styles from "../styles/experimental.module.scss";

export const Experimental = () => {
  return (
    <div>
      {/* <Tire /> */}
      {/* <div className="vasak" />
      <div className="keskmine" />
      <div className="parem" />
      <Hexagon /> */}

      <div className={styles["container"]}>
        <div className={styles["first-row"]}>
          <div className={styles["item"]}>
            <div className={styles["hexagon"]}>
              <div className={styles["img-type"]}>
                <img
                  className={styles["vehicle-img"]}
                  alt="Cars"
                  src="images/car_flat_gold.png"
                />
                <p>CARS</p>
              </div>
            </div>
          </div>
          <div className={styles["item"]}>
            <div className={styles["hexagon"]}>
              <div className={styles["img-type"]}>
                <img
                  className={styles["vehicle-img"]}
                  alt="Cars"
                  // src="images/van_flat.png"
                  src="images/car_flat_gold.png"
                />
                <p>VANS</p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles["second-row"]}>
          <div className={styles["item"]}>
            <div className={styles["hexagon"]}>
              <div className={styles["img-type"]}>
                <img
                  className={styles["vehicle-img"]}
                  alt="Cars"
                  // src="images/truck_flat.png"
                  src="images/car_flat_gold.png"
                />
                <p>TRUCKS</p>
              </div>
            </div>
          </div>
          <div className={styles["item"]}>
            <div className={styles["hexagon"]}>
              <div className={styles["img-type"]}>
                <img
                  className={styles["vehicle-img"]}
                  alt="Cars"
                  // src="images/bus_flat.png"
                  src="images/car_flat_gold.png"
                />
                <p>BUSES</p>
              </div>
            </div>
          </div>
          <div className={styles["item"]}>
            <div className={styles["hexagon"]}>
              <div className={styles["img-type"]}>
                <img
                  className={styles["vehicle-img"]}
                  alt="Cars"
                  // src="images/tractor_flat.png"
                  src="images/car_flat_gold.png"
                />
                <p>AGRI</p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles["third-row"]}>
          <div className={styles["item"]}>
            <div className={styles["hexagon"]}>
              <div className={styles["img-type"]}>
                <img
                  className={styles["vehicle-img"]}
                  alt="Cars"
                  // src="images/dumper.png"
                  src="images/car_flat_gold.png"
                />
                <p>CONST</p>
              </div>
            </div>
          </div>
          <div className={styles["item"]}>
            <div className={styles["hexagon"]}>
              <div className={styles["img-type"]}>
                <img
                  className={styles["vehicle-img"]}
                  alt="Cars"
                  // src="images/forklift.png"
                  src="images/car_flat_gold.png"
                />
                <p>LIFTING</p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles["fourth-row"]}>
          <div className={styles["item"]}>
            <div className={styles["hexagon"]}>
              <div className={styles["img-type"]}>
                <img
                  className={styles["vehicle-img"]}
                  alt="Cars"
                  // src="images/tank.png"
                  src="images/car_flat_gold.png"
                />
                <p>OTHERS</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
