import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import styles from "./menu-views.module.scss";

const About = ({ setIsProgressBarVisible }) => {
  useEffect(() => {
    setIsProgressBarVisible(false);

    return () => setIsProgressBarVisible(true);
  });

  return (
    <div className={styles["container"]}>
      <div className={styles["content"]}>
        <FormattedMessage id="about.paragraph_1" />
        <p>
          <FormattedMessage id="about.heading_1" />
        </p>
        <FormattedMessage id="about.paragraph_2" />
        <p>
          <FormattedMessage id="about.heading_2" />
        </p>
        <FormattedMessage id="about.paragraph_3" />
      </div>
    </div>
  );
};

export default About;
