import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { Cars } from "../api/typings";
import { cupraYears, dsAutomobilesYears, saabYears, teslaYears, years2023 } from "../api/carsAndProductsData";
import { Selection } from "../components/Selection";

interface UrlProps {
  model: string;
}

export const YearSelection: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();

  const params = useParams<UrlProps>();
  let releaseYears = years2023;

  if (params.model === Cars.Saab) {
    releaseYears = saabYears;
  }
  if (params.model === Cars.Tesla) {
    releaseYears = teslaYears;
  }
  if (params.model === Cars.Cupra) {
    releaseYears = cupraYears;
  }
  if (params.model === Cars["DS Automobiles"]) {
    releaseYears = dsAutomobilesYears;
  }

  const options = releaseYears.map((year) => ({ value: year, label: year }));

  const onChange = (option) => {
    if (!option) return;
    history.push(`/cars/${params.model}/${option.value}`);
  };

  return (
    <Selection
      options={options}
      placeholder={intl.formatMessage({ id: "yearselection.header" })}
      onChange={onChange}
    />
  );
};
