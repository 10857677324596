// @ts-ignore
import React, { useState } from "react"; // @ts-ignore
import { Link } from "react-router-dom"; // @ts-ignore
import { FormattedMessage } from "react-intl"; // @ts-ignore
import styles from "../styles/vehicles.module.scss";
import { Hexagon } from "../components/Hexagon";

const Vehicles = () => {
  const [focusedVehicle, setFocusedVehicle] = useState<string>();

  return (
    <div className={styles["container"]}>
      {/* display vehicle name on top of the screen when touched */}
      <div className={styles["vehicle-name"]}>
        {focusedVehicle ? (
          <FormattedMessage id={"vehicles." + focusedVehicle} />
        ) : (
          <FormattedMessage id={"vehicles.selectVehicle"} />
        )}
      </div>

      {/* <Link to="/cars" className={styles["link"]}>
        <Hexagon
          onFocusStart={() => {
            setFocusedVehicle("cars");
          }}
          onFocusEnd={() => {
            setFocusedVehicle(undefined);
          }}
          vehicle="cars"
          solo
        />
      </Link> */}

      <div className={styles["honeycomb"]}>
        <div className={styles["first-row"]}>
          <Link to="/cars" className={styles["link"]}>
            <Hexagon
              onFocusStart={() => {
                setFocusedVehicle("cars");
              }}
              onFocusEnd={() => {
                setFocusedVehicle(undefined);
              }}
              vehicle="cars"
            />
          </Link>

          <Link to="/bikes" className={styles["link"]}>
            <Hexagon
              onFocusStart={() => {
                setFocusedVehicle("motorcycles");
              }}
              onFocusEnd={() => {
                setFocusedVehicle(undefined);
              }}
              vehicle="motorcycles"
            />
          </Link>
        </div>

        <div className={styles["second-row"]}>
          <Link to="/vans" className={styles["link"]}>
            <Hexagon
              onFocusStart={() => {
                setFocusedVehicle("vans");
              }}
              onFocusEnd={() => {
                setFocusedVehicle(undefined);
              }}
              vehicle="vans"
            />
          </Link>

          <Link to="/trucks" className={styles["link"]}>
            <Hexagon
              onFocusStart={() => {
                setFocusedVehicle("trucks");
              }}
              onFocusEnd={() => {
                setFocusedVehicle(undefined);
              }}
              vehicle="trucks"
            />
          </Link>

          <Link to="/buses" className={styles["link"]}>
            <Hexagon
              onFocusStart={() => {
                setFocusedVehicle("buses");
              }}
              onFocusEnd={() => {
                setFocusedVehicle(undefined);
              }}
              vehicle="buses"
            />
          </Link>
        </div>

        <div className={styles["third-row"]}>
          {/* <Hexagon
            onFocusStart={() => {
              setFocusedVehicle("const");
            }}
            onFocusEnd={() => {
              setFocusedVehicle(undefined);
            }}
            vehicle="const"
            dim
          /> */}
          <Link to="/agris" className={styles["link"]}>
            <Hexagon
              onFocusStart={() => {
                setFocusedVehicle("agri");
              }}
              onFocusEnd={() => {
                setFocusedVehicle(undefined);
              }}
              vehicle="agri"
            />
          </Link>
          <Hexagon
            onFocusStart={() => {
              setFocusedVehicle("lifting");
            }}
            onFocusEnd={() => {
              setFocusedVehicle(undefined);
            }}
            vehicle="lifting"
            dim
          />
        </div>

        {/* <div className={styles["fourth-row"]}>
          <Hexagon
            onFocusStart={() => {
              setFocusedVehicle("motorcycles");
            }}
            onFocusEnd={() => {
              setFocusedVehicle(undefined);
            }}
            vehicle="motorcycles"
            dim
          />
          <Hexagon
            onFocusStart={() => {
              setFocusedVehicle("other");
            }}
            onFocusEnd={() => {
              setFocusedVehicle(undefined);
            }}
            vehicle="other"
            dim
          />
        </div> */}
      </div>
    </div>
  );
};

export default Vehicles;
