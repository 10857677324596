// @ts-ignore
import React from "react"; // @ts-ignore
import { Link } from "react-router-dom"; // @ts-ignore
import { Dropdown, Flag } from "semantic-ui-react"; // @ts-ignore
import classnames from "classnames"; // @ts-ignore
import styles from "../styles/header.module.scss";
import "../styles/hamburgers.css";

const languageCodeToLanguage = {
  en: "English",
  et: "Eesti",
  lv: "Latvių",
  ru: "Pусский",
  lt: "Lietuviškai",
};

interface Props {
  language: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
  isSideNavVisible: boolean;
  setIsSideNavVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header: React.FC<Props> = ({
  language,
  setLanguage,
  isSideNavVisible,
  setIsSideNavVisible,
}) => {
  return (
    <div className={styles["headerBody"]}>
      <div
        className={classnames(styles["dd"], {
          [styles["sidenav-header-bg"]]: isSideNavVisible,
        })}
      >
        <Dropdown
          className={styles["languagebox"]}
          text={languageCodeToLanguage[language]}
        >
          <Dropdown.Menu id="dropdownmenu">
            <Dropdown.Item
              onClick={() => {
                setLanguage("en");
              }}
            >
              <Flag name="gb" /> English
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setLanguage("et");
              }}
            >
              <Flag name="ee" /> Eesti
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setLanguage("lv");
              }}
            >
              <Flag name="lv" /> Latvių
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setLanguage("ru");
              }}
            >
              <Flag name="ru" /> Pусский
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setLanguage("lt");
              }}
            >
              <Flag name="lt" /> Lietuviškai
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <Link
        to="/"
        className={classnames(styles["icon"], {
          [styles["sidenav-header-bg"]]: isSideNavVisible,
        })}
        onClick={() => {
          setIsSideNavVisible(false);
        }}
      >
        <i
          id="restart"
          className={classnames("material-icons", styles["material-icons"])}
        >
          replay
        </i>
      </Link>

      <div
        className={classnames(styles["burger"], {
          [styles["sidenav-header-bg"]]: isSideNavVisible,
        })}
      >
        <button
          onClick={() => {
            setIsSideNavVisible(!isSideNavVisible);
          }}
          className={`hamburger hamburger--elastic ${
            isSideNavVisible ? "is-active" : ""
          }`}
          type="button"
        >
          <span className="hamburger-box">
            <span
              className={classnames(
                "hamburger-inner",
                styles["hamburger-inner"]
              )}
            />
          </span>
        </button>
      </div>
    </div>
  );
};

export default Header;
