import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { Selection } from "../components/Selection";
import { agrisAndEngines } from "../api/agriAndProductsData";
import { Agris } from "../api/typings";

interface UrlProps {
  chosenAgri: Agris;
  chosenAgriYear: string;
}

export const AgriEngineSelection = () => {
  const intl = useIntl();
  const history = useHistory();
  const { chosenAgri, chosenAgriYear } = useParams<UrlProps>();

  let agriAndItsEngineOptions: Array<String> =
    agrisAndEngines.find((agriAndEngine) => agriAndEngine.brand === chosenAgri)
      ?.engines || [];

  const options = agriAndItsEngineOptions.map((opt) => ({
    value: opt,
    label: opt,
  }));

  const onChange = (option) => {
    if (!option) return;
    history.push(`/agris/${chosenAgri}/${chosenAgriYear}/${option.value}`);
  };

  return (
    <Selection
      options={options}
      placeholder={intl.formatMessage({ id: "agrisEngineSelection.header" })}
      onChange={onChange}
    />
  );
};
