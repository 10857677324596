import { BikeAndProductsData, BikeProducts, Bikes } from "./typings";

export const bikesAndProductsData: BikeAndProductsData[] = [
  {
    brand: Bikes.Yamaha,
    data: { primary: BikeProducts.LLC, secondary: BikeProducts.VCS },
  },
  {
    brand: Bikes.Honda,
    data: { primary: BikeProducts.LLC, secondary: BikeProducts.VCS },
  },
  {
    brand: Bikes.Suzuki,
    data: { primary: BikeProducts.LLC, secondary: BikeProducts.VCS },
  },
  {
    brand: Bikes["CF Moto"],
    data: { primary: BikeProducts.VCS, secondary: BikeProducts.STANDARD },
  },
  {
    brand: Bikes.Kawasaki,
    data: { primary: BikeProducts.LLC, secondary: BikeProducts.VCS },
  },
  {
    brand: Bikes.Aprilia,
    data: { primary: BikeProducts.VCS },
  },
  {
    brand: Bikes.Baotian,
    data: { primary: BikeProducts.LLC, secondary: BikeProducts.VCS },
  },
  {
    brand: Bikes["Harley-Davidson"],
    data: { primary: BikeProducts.VCS },
  },
  {
    brand: Bikes.BMW,
    data: { primary: BikeProducts.VC, secondary: BikeProducts.STANDARD },
  },
  {
    brand: Bikes.KTM,
    data: { primary: BikeProducts.VCS },
  },
  {
    brand: Bikes.Kymco,
    data: { primary: BikeProducts.VCS },
  },
  {
    brand: Bikes.Keeway,
    data: { primary: BikeProducts.LLC, secondary: BikeProducts.VCS },
  },
  {
    brand: Bikes.Piaggio,
    data: { primary: BikeProducts.VCS },
  },
  {
    brand: Bikes.CPI,
    data: { primary: BikeProducts.LLC, secondary: BikeProducts.VCS },
  },
  {
    brand: Bikes["Romet Motors"],
    data: { primary: BikeProducts.VCS },
  },
  {
    brand: Bikes.Lifan,
    data: { primary: BikeProducts.LLC, secondary: BikeProducts.VCS },
  },
  {
    brand: Bikes.Ligier,
    data: { primary: BikeProducts.VCS },
  },
  { brand: Bikes.Aixam, data: { primary: BikeProducts.VCS } },
  {
    brand: Bikes["Can-Am"],
    data: { primary: BikeProducts.VCS },
  },
  {
    brand: Bikes["Moto Star"],
    data: { primary: BikeProducts.LLC, secondary: BikeProducts.VCS },
  },
  {
    brand: Bikes.Polaris,
    data: { primary: BikeProducts.VCS },
  },
  {
    brand: Bikes.Buell,
    data: { primary: BikeProducts.VCS },
  },
  {
    brand: Bikes.Dinli,
    data: { primary: BikeProducts.LLC, secondary: BikeProducts.VCS },
  },
  {
    brand: Bikes.Goes,
    data: { primary: BikeProducts.LLC, secondary: BikeProducts.VCS },
  },
  {
    brand: Bikes.Beta,
    data: { primary: BikeProducts.VCS },
  },
  {
    brand: Bikes.Victory,
    data: { primary: BikeProducts.VCS },
  },
  {
    brand: Bikes.Qingqi,
    data: { primary: BikeProducts.LLC, secondary: BikeProducts.VCS },
  },
  {
    brand: Bikes.Jiajue,
    data: { primary: BikeProducts.LLC, secondary: BikeProducts.VCS },
  },
  {
    brand: Bikes.Ducati,
    data: { primary: BikeProducts.VCS },
  },
  {
    brand: Bikes.Peugeot,
    data: { primary: BikeProducts.VCS },
  },
  {
    brand: Bikes.Husqvarna,
    data: { primary: BikeProducts.VCS },
  },
  {
    brand: Bikes.Linhai,
    data: { primary: BikeProducts.VCS },
  },
  {
    brand: Bikes.Microcar,
    data: { primary: BikeProducts.VCS },
  },
  {
    brand: Bikes.Gasgas,
    data: { primary: BikeProducts.VCS },
  },
  {
    brand: Bikes.Benelli,
    data: { primary: BikeProducts.VCS },
  },
  {
    brand: Bikes.Indian,
    data: { primary: BikeProducts.VCS },
  },
  {
    brand: Bikes.Triumph,
    data: { primary: BikeProducts.VCS },
  },
  {
    brand: Bikes["MV Agusta"],
    data: { primary: BikeProducts.VCS },
  },
  {
    brand: Bikes.Motus,
    data: { primary: BikeProducts.VCS },
  },
];
