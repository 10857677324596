import {
	MercedesTruckEngines,
	MercedesTruckProductsData,
	Products,
	TruckAndProductsData,
	Trucks,
} from "./typings";

export const trucksAndProductsData: TruckAndProductsData[] = [
	{
		brand: Trucks.DAF,
		data: {
			"<Euro 2": { primary: Products.STANDARD },
			"Euro 3": { primary: Products.LLPRO, secondary: Products.VCS },
			"Euro 4": { primary: Products.LLPRO, secondary: Products.VCS },
			"Euro 5": { primary: Products.LLPRO, secondary: Products.VCS },
			EEV: { primary: Products.LLPRO, secondary: Products.VCS },
			"Euro 6": { primary: Products.LLPRO, secondary: Products.VCS },
		},
	},
	{
		brand: Trucks.Iveco,
		data: {
			"<Euro 2": { primary: Products.STANDARD },
			"Euro 3": { primary: Products.VC, secondary: Products.LLPRO },
			"Euro 4": { primary: Products.VC, secondary: Products.LLPRO },
			"Euro 5": { primary: Products.VC, secondary: Products.LLPRO },
			EEV: { primary: Products.VC, secondary: Products.LLPRO },
			"Euro 6": { primary: Products.VC, secondary: Products.VCS },
		},
	},
	{
		brand: Trucks.MAN,
		data: {
			"<Euro 2": { primary: Products.STANDARD },
			"Euro 3": { primary: Products.LLPRO, secondary: Products.MBG12 },
			"Euro 4": { primary: Products.LLPRO, secondary: Products.MBG12 },
			"Euro 5": { primary: Products.LLPRO, secondary: Products.MBG12 },
			EEV: { primary: Products.LLPRO, secondary: Products.MBG12 },
			"Euro 6": { primary: Products.MBG12 },
		},
	},
	{
		brand: Trucks.Renault,
		data: {
			"<Euro 2": { primary: Products.STANDARD },
			"Euro 3": { primary: Products.VCS, secondary: Products.LLPRO },
			"Euro 4": { primary: Products.VCS, secondary: Products.LLPRO },
			"Euro 5": { primary: Products.VCS, secondary: Products.LLPRO },
			EEV: { primary: Products.VCS, secondary: Products.LLPRO },
			"Euro 6": { primary: Products.VCS },
		},
	},
	{
		brand: Trucks.Scania,
		data: {
			"<Euro 2": { primary: Products.STANDARD },
			"Euro 3": { primary: Products.LLPRO, secondary: Products.MBG12 },
			"Euro 4": { primary: Products.LLPRO, secondary: Products.MBG12 },
			"Euro 5": { primary: Products.MBG12 },
			EEV: { primary: Products.MBG12 },
			"Euro 6": { primary: Products.MBG12 },
		},
	},
	{
		brand: Trucks.Volvo,
		data: {
			"<Euro 2": { primary: Products.STANDARD },
			"Euro 3": { primary: Products.VCS, secondary: Products.LLPRO },
			"Euro 4": { primary: Products.VCS, secondary: Products.LLPRO },
			"Euro 5": { primary: Products.VCS, secondary: Products.LLPRO },
			EEV: { primary: Products.VCS, secondary: Products.LLPRO },
			"Euro 6": { primary: Products.VCS },
		},
	},
	{
		brand: Trucks.FUSO,
		data: {
			"<Euro 2": { primary: Products.STANDARD },
			"Euro 3": { primary: Products.LLC },
			"Euro 4": { primary: Products.LLC },
			"Euro 5": { primary: Products.LLC },
			EEV: { primary: Products.LLC },
			"Euro 6": { primary: Products.LLC },
		},
	},
];

export const mercedesTruckAndProductData: MercedesTruckProductsData[] = [
	{
		engine: MercedesTruckEngines["OM 300"],
		data: { primary: Products.MBG12, secondary: Products.LLPRO, third: Products.VCS },
	},
	{
		engine: MercedesTruckEngines["OM 400"],
		data: { primary: Products.MBG12, secondary: Products.LLPRO, third: Products.VCS },
	},
	{
		engine: MercedesTruckEngines["OM 904-926"],
		data: { primary: Products.MBG12, secondary: Products.LLPRO, third: Products.VCS },
	},
	{
		engine: MercedesTruckEngines["OM 934, 936"],
		data: { primary: Products.MBG12 },
	},
	{
		engine: MercedesTruckEngines["OM 457, 460"],
		data: { primary: Products.MBG12, secondary: Products.LLPRO, third: Products.VCS },
	},
	{
		engine: MercedesTruckEngines["OM 500"],
		data: { primary: Products.MBG12, secondary: Products.LLPRO, third: Products.VCS },
	},
	{
		engine: MercedesTruckEngines["OM 470-473"],
		data: { primary: Products.MBG12 },
	},
	{
		engine: MercedesTruckEngines["With SWR"],
		data: { primary: Products.MBG12 },
	},
];
