import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { Vans } from "../api/typings";
import { years2023 } from "../api/carsAndProductsData";
import { Selection } from "../components/Selection";

interface UrlProps {
  chosenVan: string;
}

export const VanYearSelection: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();

  const params = useParams<UrlProps>();
  let releaseYears = years2023;

  if (params.chosenVan === Vans["MAN TGE"]) {
    releaseYears = [2020, 2019, 2018, 2017];
  }

  const options = releaseYears.map((year) => ({ value: year, label: year }));

  const onChange = (option) => {
    if (!option) return;
    history.push(`/vans/${params.chosenVan}/${option.value}`);
  };

  return (
    <Selection
      options={options}
      placeholder={intl.formatMessage({ id: "yearselection.header" })}
      onChange={onChange}
    />
  );
};
