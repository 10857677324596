export enum Products {
  LONGLIFE = "Polar Premium Long-Life -37°C",
  LLPRO = "Polar Premium Long-Life PRO -37°C",
  SLLC = "Polar Premium Long-Life SLLC -37°C",
  MBG12 = "Polar Premium Long-Life MB/G12++ -37°C",
  G12EVO = "Polar Premium Long-Life G12evo -37°C",
  VCS = "Polar Premium Long-Life VCS-2 -37°C",
  LPC = "Polar Premium Long-Life LPC -37°C",
  LLC = "Polar Premium Long-Life LLC -37°C",
  VC = "Polar Premium Long-Life VC -37°C",
  STANDARD = "Polar Standard -37°C",
  FREECOR = "Freecor EV Milli 10 -37°C",
  NONE = "NONE",
}

export enum BikeProducts {
  LLC = "Polar Premium Long-Life LLC -37°C",
  VCS = "Polar Premium Long-Life VCS-2 -37°C",
  STANDARD = "Polar Standard -37°C",
  VC = "Polar Premium Long-Life VC -37°C",
}

export enum ProductAbbreviationsToFullName {
  "longlife" = "Polar Premium Long-Life -37°C",
  "longlifepro" = "Polar Premium Long-Life PRO -37°C",
  "sllc" = "Polar Premium Long-Life SLLC -37°C",
  "mbg12" = "Polar Premium Long-Life MB/G12++ -37°C",
  "g12evo" = "Polar Premium Long-Life G12evo -37°C",
  "vcs" = "Polar Premium Long-Life VCS-2 -37°C",
  "lpc" = "Polar Premium Long-Life LPC -37°C",
  "llc" = "Polar Premium Long-Life LLC -37°C",
  "vc" = "Polar Premium Long-Life VC -37°C",
  "standard" = "Polar Standard -37°C",
  "freecor" = "Freecor EV Milli 10 -37°C",
}

export type Choices = {
  primary: string;
  secondary?: string;
  third?: string;
  fourth?: string;
  fifth?: string;
};

export type YearsAndChoices2023 = {
  2000: Choices;
  2001: Choices;
  2002: Choices;
  2003: Choices;
  2004: Choices;
  2005: Choices;
  2006: Choices;
  2007: Choices;
  2008: Choices;
  2009: Choices;
  2010: Choices;
  2011: Choices;
  2012: Choices;
  2013: Choices;
  2014: Choices;
  2015: Choices;
  2016: Choices;
  2017: Choices;
  2018: Choices;
  2019: Choices;
  2020: Choices;
  2021: Choices;
  2022: Choices;
  2023: Choices;
};

export type CarAndProductsData = {
  model: Cars;
  data: YearsAndChoices2023;
};

export enum Cars {
  "Alfa Romeo" = "Alfa Romeo",
  "Audi" = "Audi",
  "BMW" = "BMW",
  "Cadillac" = "Cadillac",
  "Chevrolet" = "Chevrolet",
  "Chrysler" = "Chrysler",
  "Citroen" = "Citroen",
  "Cupra" = "Cupra",
  "Dacia" = "Dacia",
  "Dodge" = "Dodge",
  "DS Automobiles" = "DS Automobiles",
  "Fiat" = "Fiat",
  "Ford" = "Ford",
  "Honda" = "Honda",
  "Hyundai" = "Hyundai",
  "Isuzu" = "Isuzu",
  "Iveco" = "Iveco",
  "Jaguar" = "Jaguar",
  "Jeep" = "Jeep",
  "Kia" = "Kia",
  "KMG" = "KMG",
  "Lada" = "Lada",
  "Lancia" = "Lancia",
  "Land Rover" = "Land Rover",
  "Lexus" = "Lexus",
  "Mazda" = "Mazda",
  "Mercedes-Benz" = "Mercedes-Benz",
  "Mini" = "Mini",
  "Mitsubishi" = "Mitsubishi",
  "Nissan" = "Nissan",
  "Opel" = "Opel",
  "Peugeot" = "Peugeot",
  "Porsche" = "Porsche",
  "RAM" = "RAM",
  "Renault" = "Renault",
  "Saab" = "Saab",
  "Seat" = "Seat",
  "Škoda" = "Škoda",
  "Ssangyong" = "Ssangyong",
  "Subaru" = "Subaru",
  "Suzuki" = "Suzuki",
  "Tesla" = "Tesla",
  "Toyota" = "Toyota",
  "Volkswagen" = "Volkswagen",
  "Volvo" = "Volvo",
}

export type BikeAndProductsData = {
  brand: Bikes;
  data: Choices;
};

export enum Bikes {
  "Aixam" = "Aixam",
  "Aprilia" = "Aprilia",
  "Baotian" = "Baotian",
  "Benelli" = "Benelli",
  "Beta" = "Beta",
  "BMW" = "BMW",
  "Buell" = "Buell",
  "Can-Am" = "Can-Am",
  "CF Moto" = "CF Moto",
  "CPI" = "CPI",
  "Dinli" = "Dinli",
  "Ducati" = "Ducati",
  "Gasgas" = "Gasgas",
  "Goes" = "Goes",
  "Harley-Davidson" = "Harley-Davidson",
  "Honda" = "Honda",
  "Husqvarna" = "Husqvarna",
  "Indian" = "Indian",
  "Jiajue" = "Jiajue",
  "Kawasaki" = "Kawasaki",
  "Keeway" = "Keeway",
  "KTM" = "KTM",
  "Kymco" = "Kymco",
  "Lifan" = "Lifan",
  "Ligier" = "Ligier",
  "Linhai" = "Linhai",
  "Microcar" = "Microcar",
  "Moto Star" = "Moto Star",
  "Motus" = "Motus",
  "MV Agusta" = "MV Agusta",
  "Peugeot" = "Peugeot",
  "Piaggio" = "Piaggio",
  "Polaris" = "Polaris",
  "Qingqi" = "Qingqi",
  "Romet Motors" = "Romet Motors",
  "Suzuki" = "Suzuki",
  "Triumph" = "Triumph",
  "Victory" = "Victory",
  "Yamaha" = "Yamaha",
}

export type EuroStandardAndProducts = {
  "<Euro 2": Choices;
  "Euro 3": Choices;
  "Euro 4": Choices | null;
  "Euro 5": Choices | null;
  EEV: Choices | null;
  "Euro 6": Choices | null;
};

export type TruckAndProductsData = {
  brand: Trucks;
  data: EuroStandardAndProducts;
};

export enum MercedesTruckEngines {
  "OM 300" = "OM 300",
  "OM 400" = "OM 400",
  "OM 904-926" = "OM 904-926",
  "OM 934, 936" = "OM 934, 936",
  "OM 457, 460" = "OM 457, 460",
  "OM 500" = "OM 500",
  "OM 470-473" = "OM 470-473",
  "With SWR" = "With SWR",
}

export type MercedesTruckProductsData = {
  engine: MercedesTruckEngines;
  data: Choices;
};

export enum Trucks {
  "DAF" = "DAF",
  "Iveco" = "Iveco",
  "MAN" = "MAN",
  "Renault" = "Renault",
  "Volvo" = "Volvo",
  "Scania" = "Scania",
  "Mercedes-Benz" = "Mercedes-Benz",
  "FUSO" = "FUSO",
}

export enum Vans {
  "Citroen" = "Citroen",
  "Ford" = "Ford",
  "Peugeot" = "Peugeot",
  "Volkswagen" = "Volkswagen",
  "Renault" = "Renault",
  "Mercedes-Benz" = "Mercedes-Benz",
  "Toyota" = "Toyota",
  "Opel" = "Opel",
  "Fiat" = "Fiat",
  "Škoda" = "Škoda",
  "Nissan" = "Nissan",
  "Iveco" = "Iveco",
  "Mitsubishi" = "Mitsubishi",
  "Isuzu" = "Isuzu",
  "Mazda" = "Mazda",
  "MAN TGE" = "MAN TGE",
  "RAM" = "RAM",
  "Dodge" = "Dodge",
  "Hyundai" = "Hyundai",
  "Kia" = "Kia",
  "GMC" = "GMC",
  "FUSO" = "FUSO",
}

export enum MercedesVanMotors {
  M112 = "M112",
  M113 = "M113",
  M133 = "M133",
  M137 = "M137",
  M15x = "M15x",
  M176 = "M176",
  M177 = "M177",
  M256 = "M256",
  M260 = "M260",
  M264 = "M264",
  M266 = "M266",
  M27x = "M27x",
  M281 = "M281",
  M282 = "M282",
  M285 = "M285",
  "M200.71" = "M200.71",
  "OM 607 (Citan)" = "OM 607 (Citan)",
  "OM 608" = "OM 608",
  "OM 611" = "OM 611",
  "OM 612" = "OM 612",
  "OM 613" = "OM 613",
  "OM 62x" = "OM 62x",
  "OM 639" = "OM 639",
  "OM 640" = "OM 640",
  "OM 642" = "OM 642",
  "OM 651" = "OM 651",
  "OM 654" = "OM 654",
  "OM 656" = "OM 656",
  "OM 699.77 (X-Class)" = "OM 699.77 (X-Class)",
}
export enum FordVanMotors {
  Ford = "Ford",
  Volkswagen = "Volkswagen",
}

export type VanAndProductsData = {
  model: Vans;
  motors?: (MercedesVanMotors | FordVanMotors)[];
  data: YearsAndChoices2023;
};

export enum Buses {
  "BMC" = "BMC",
  "DAF" = "DAF",
  "FUSO" = "FUSO",
  "IVECO" = "IVECO",
  "Irizar" = "Irizar",
  "Isuzu" = "Isuzu",
  "MAZ" = "MAZ",
  "MAN" = "MAN",
  "NEOPLAN" = "NEOPLAN",
  "Mercedes-Benz" = "Mercedes-Benz",
  "NeoLAZ" = "NeoLAZ",
  "Scania" = "Scania",
  "Setra" = "Setra",
  "Solaris" = "Solaris",
  "Solbus" = "Solbus",
  "SOR" = "SOR",
  "Temsa" = "Temsa",
  "Van Hool" = "Van Hool",
  "Volvo" = "Volvo",
  "HIGER Bus" = "HIGER Bus",
}

export enum BusEngines {
  "Paccar DAF" = "Paccar DAF",
  "Scania" = "Scania",
  "Isuzu" = "Isuzu",
  "Cummins" = "Cummins",
  "FPT" = "FPT",
  "MAN" = "MAN",
  "Iveco" = "Iveco",
  "DAF" = "DAF",
}

export type BusAndProductsData = {
  brand: Buses;
  engine?: BusEngines[];
  data: EuroStandardAndProducts;
};

export enum Agris {
  "AGCO" = "AGCO",
  "Agromehanika" = "Agromehanika",
  "Antonio Carraro" = "Antonio Carraro",
  "Belarus" = "Belarus",
  "Branson | TYM" = "Branson | TYM",
  "Case IH" = "Case IH",
  "Claas" = "Claas",
  "Deutz-Fahr" = "Deutz-Fahr",
  "Dieci" = "Dieci",
  "Dong Feng" = "Dong Feng",
  "Fendt" = "Fendt",
  "Hürlimann" = "Hürlimann",
  "JCB" = "JCB",
  "John Deere" = "John Deere",
  "Kioti | Daedong" = "Kioti | Daedong",
  "Kramer" = "Kramer",
  "Kubota" = "Kubota",
  "KUKJE | Branson" = "KUKJE | Branson",
  "Lamborghini" = "Lamborghini",
  "Landini" = "Landini",
  "Lovol" = "Lovol",
  "Manitou" = "Manitou",
  "Massey Ferguson" = "Massey Ferguson",
  "McCormick" = "McCormick",
  "Merlo" = "Merlo",
  "New Holland" = "New Holland",
  "Same" = "Same",
  "Solis" = "Solis",
  "Steyr" = "Steyr",
  "Zetor" = "Zetor",
  "TYM" = "TYM",
  "Ursus" = "Ursus",
  "Valmet | Valtra" = "Valmet | Valtra",
  "Valtra" = "Valtra",
  "Versatile" = "Versatile",
}

export enum AgriEngines {
  "Agco Power" = "Agco Power",
  "Cummins" = "Cummins",
  "Iseki" = "Iseki",
  "Sisu | Agco Power" = "Sisu | Agco Power",
  "Lombardini | Kohler" = "Lombardini | Kohler",
  "Deutz" = "Deutz",
  "Kohler" = "Kohler",
  "Kubota" = "Kubota",
  "Yanmar" = "Yanmar",
  "Belarco" = "Belarco",
  "Doosan" = "Doosan",
  "Liadong" = "Liadong",
  "MTZ | MMZ" = "MTZ | MMZ",
  "Weichai" = "Weichai",
  "Kukje" = "Kukje",
  "CNH | Iveco FPT" = "CNH | Iveco FPT",
  "FPT" = "FPT",
  "Iveco | FPT" = "Iveco | FPT",
  "LS Mtron" = "LS Mtron",
  "MWM" = "MWM",
  "Perkins | Caterpillar" = "Perkins | Caterpillar",
  "Shibaura" = "Shibaura",
  "Caterpillar" = "Caterpillar",
  "DPS (Deere Power System)" = "DPS (Deere Power System)",
  "Iveco" = "Iveco",
  "John Deere" = "John Deere",
  "MAN" = "MAN",
  "Mercedes-Benz" = "Mercedes-Benz",
  "SDF" = "SDF",
  "Mitsubishi" = "Mitsubishi",
  "Simpson" = "Simpson",
  "Changchai" = "Changchai",
  "Jangsu Jianghuai" = "Jangsu Jianghuai",
  "MTU" = "MTU",
  "Daedong" = "Daedong",
  "Branson | TYM" = "Branson | TYM",
  "Huafeng" = "Huafeng",
  "Laidong" = "Laidong",
  "Shangchai" = "Shangchai",
  "Xinchai" = "Xinchai",
  "BetaPower" = "BetaPower",
  "Ford" = "Ford",
  "Nef (FPT)" = "Nef (FPT)",
  "ITL" = "ITL",
  "Same | SDF" = "Same | SDF",
  "Zetor" = "Zetor",
  "TYM" = "TYM",
  "KUKJE | Branson" = "KUKJE | Branson",
}

export type AgrisAndTheirEngines = {
  brand: Agris;
  engines: AgriEngines[];
};

export enum AgriMakes {
  "<2000" = "<2000",
  "2000+" = "2000+",
}

export type AgriData = {
  "<2000": Choices;
  "2000+": Choices;
};

export type AgriAndProductsData = {
  brand: Agris;
  engines: AgriEngines[];
  data: AgriData;
};
