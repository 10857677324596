import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ReactGA from "react-ga";
import {
  createIntl,
  createIntlCache,
  IntlProvider,
  RawIntlProvider,
} from "react-intl";
import messages_en from "./translations/en.json";
import messages_et from "./translations/et.json";
import messages_ru from "./translations/ru.json";
import messages_lv from "./translations/lv.json";
import messages_lt from "./translations/lt.json";

import Header from "./components/Header";
import SideNav from "./components/SideNav";
import Vehicles from "./views/Vehicles";
import ProductMatch from "./views/ProductMatch"; // TODO rm default export
import { ProductDescription } from "./views/ProductDescription";
import About from "./views/menu-views/About";
import NotFound from "./views/NotFound";

import ReadMore from "./views/menu-views/ReadMore";
import Ingredients from "./views/menu-views/Ingredients";
import MixingGuide from "./views/menu-views/MixingGuide";
import Standards from "./views/menu-views/Standards";
import Feedback from "./views/menu-views/Feedback";
import "./styles/app.css";

import { YearSelection } from "./views/YearSelection";
import { Experimental } from "./views/Experimental";
import { Products } from "./api/typings";
import { TruckEngineSelection } from "./views/TruckEngineSelection";
import { VanYearSelection } from "./views/VanYearSelection";
import { VanMotorSelection } from "./views/VanMotorSelection";
import { ProductsView } from "./views/menu-views/ProductsView";
import { PolarThermCalculator } from "./views/PolarThermCalculator";
import { VehicleBrandSelection } from "./views/VehicleBrandSelection";
import { BusEngineSelection } from "./views/BusEngineSelection";
import { BusEuroSelection } from "./views/BusEuroSelection";
import { AgriEngineSelection } from "./views/AgriEngineSelection";
import { AgriYearSelection } from "./views/AgriYearSelection";

// bind country codes to translation files
const messages = {
  en: messages_en,
  et: messages_et,
  ru: messages_ru,
  lv: messages_lv,
  lt: messages_lt,
};

const languageAbbrevations = ["en", "et", "ru", "lv", "lt"];

// analytics
ReactGA.initialize("UA-124515988-1");
ReactGA.pageview(window.location.pathname + window.location.search);

export const App = () => {
  // language without region code
  let browserLanguage = navigator.language.split(/[-_]/)[0];
  if (!languageAbbrevations.includes(browserLanguage)) {
    browserLanguage = "en";
  }

  useEffect(() => {
    setLanguage(browserLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [browserLanguage]);

  // setup state
  const [language, setLanguage] = useState(browserLanguage);
  const [isSideNavVisible, setIsSideNavVisible] = useState(false);
  const [isProgressBarVisible, setIsProgressBarVisible] = useState(true);

  const cache = createIntlCache();
  const intl = createIntl(
    {
      locale: language,
      messages: messages[language],
    },
    cache
  );

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <RawIntlProvider value={intl}>
        <BrowserRouter>
          <>
            <Header
              language={language}
              setLanguage={setLanguage}
              isSideNavVisible={isSideNavVisible}
              setIsSideNavVisible={setIsSideNavVisible}
            />
            <SideNav
              isSideNavVisible={isSideNavVisible}
              setIsSideNavVisible={setIsSideNavVisible}
            />
            <Switch>
              <Route path="/" exact>
                <Vehicles />
              </Route>

              <Route
                path="/cars"
                exact
                component={() => <VehicleBrandSelection category="cars" />}
              />

              <Route path="/cars/:model" exact>
                <YearSelection />
              </Route>

              <Route path="/cars/:chosenCar/:chosenYear" exact>
                <ProductMatch />
              </Route>

              <Route
                path="/bikes"
                exact
                component={() => <VehicleBrandSelection category="bikes" />}
              />

              <Route path="/bikes/:chosenBike" exact>
                <ProductMatch />
              </Route>

              <Route
                path="/trucks"
                exact
                component={() => <VehicleBrandSelection category="trucks" />}
              />

              <Route path="/trucks/:chosenTruck" exact>
                <TruckEngineSelection />
              </Route>

              <Route path="/trucks/:chosenTruck/:chosenTruckEngine" exact>
                <ProductMatch />
              </Route>

              <Route
                path="/vans"
                exact
                component={() => <VehicleBrandSelection category="vans" />}
              />

              <Route path="/vans/:chosenVan" exact>
                <VanYearSelection />
              </Route>

              {/* could also not check for brand & year, but redirect to ProductMatch inside VanMotorSelection */}
              <Route
                path="/vans/:chosenVan(Mercedes-Benz)/:vanChosenYear(2023|2022|2021|2020|2019|2018|2017|2016|2015|2014|2013|2012)"
                exact
              >
                <VanMotorSelection />
              </Route>

              {/* could also not check for brand & year, but redirect to ProductMatch inside VanMotorSelection */}
              <Route
                path="/vans/:chosenVan(Ford)/:vanChosenYear(2023|2022)"
                exact
              >
                <VanMotorSelection />
              </Route>

              <Route path="/vans/:chosenVan/:vanChosenYear" exact>
                <ProductMatch />
              </Route>

              <Route
                path="/vans/:chosenVan(Mercedes-Benz|Ford)/:vanChosenYear/:chosenVanMotor"
                exact
              >
                <ProductMatch />
              </Route>

              <Route
                path="/buses"
                exact
                component={() => <VehicleBrandSelection category="buses" />}
              />

              <Route
                path="/buses/:chosenBus"
                exact
                component={() => <BusEuroSelection />}
              />

              <Route
                path="/buses/:chosenBus(Irizar|Isuzu|Solaris|Solbus|Temsa)/:chosenEuroStandard"
                exact
                component={() => <BusEngineSelection />}
              />

              <Route
                path="/buses/:chosenBus/:chosenEuroStandard/:chosenBusEngine"
                exact
                component={() => <ProductMatch />}
              />

              <Route
                path="/buses/:chosenBus/:chosenEuroStandard"
                exact
                component={() => <ProductMatch />}
              />

              <Route
                path="/agris"
                exact
                component={() => <VehicleBrandSelection category="agris" />}
              />

              <Route
                path="/agris/:chosenAgri"
                exact
                component={() => <AgriYearSelection />}
              />
              
              <Route
                path="/agris/:chosenAgri/:chosenAgriYear(<2000)"
                exact
                component={() => <ProductMatch />}
              />
              
              <Route
                path="/agris/:chosenAgri/:chosenAgriYear"
                exact
                component={() => <AgriEngineSelection />}
              />
              
              <Route
                path="/agris/:chosenAgri/:chosenAgriYear/:chosenAgriEngine"
                exact
                component={() => <ProductMatch />}
              />

              <Route path="/products" exact>
                <ProductsView />
              </Route>

              <Route path="/g12evo" exact>
                <ProductDescription product={Products.G12EVO} />
              </Route>

              <Route path="/llc" exact>
                <ProductDescription product={Products.LLC} />
              </Route>

              <Route path="/sllc" exact>
                <ProductDescription product={Products.SLLC} />
              </Route>

              <Route path="/lpc" exact>
                <ProductDescription product={Products.LPC} />
              </Route>

              <Route path="/longlife" exact>
                <ProductDescription product={Products.LONGLIFE} />
              </Route>

              <Route path="/longlifepro" exact>
                <ProductDescription product={Products.LLPRO} />
              </Route>

              <Route path="/mbg12" exact>
                <ProductDescription product={Products.MBG12} />
              </Route>

              <Route path="/standard" exact>
                <ProductDescription product={Products.STANDARD} />
              </Route>

              <Route path="/vc" exact>
                <ProductDescription product={Products.VC} />
              </Route>

              <Route path="/vcs" exact>
                <ProductDescription product={Products.VCS} />
              </Route>

              <Route path="/freecor" exact>
                <ProductDescription product={Products.FREECOR} />
              </Route>

              <Route path="/about" exact>
                <About setIsProgressBarVisible={setIsProgressBarVisible} />
              </Route>

              <Route path="/readmore" exact>
                <ReadMore setIsProgressBarVisible={setIsProgressBarVisible} />
              </Route>

              <Route path="/ingredients" exact>
                <Ingredients
                  setIsProgressBarVisible={setIsProgressBarVisible}
                />
              </Route>

              <Route path="/mixingguide" exact>
                <MixingGuide
                  setIsProgressBarVisible={setIsProgressBarVisible}
                />
              </Route>

              <Route path="/standards" exact>
                <Standards setIsProgressBarVisible={setIsProgressBarVisible} />
              </Route>

              <Route path="/feedback" exact>
                <Feedback setIsProgressBarVisible={setIsProgressBarVisible} />
              </Route>

              <Route path="/experimental" exact>
                <Experimental />
              </Route>

              <Route path="/polartherm" exact>
                <PolarThermCalculator />
              </Route>

              <Route component={NotFound} />
            </Switch>
          </>
        </BrowserRouter>
      </RawIntlProvider>
    </IntlProvider>
  );
};
