import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { BikeProducts, Products } from "../api/typings";
import styles from "../styles/productdescription.module.scss";

interface Props {
  product: Products | BikeProducts;
}

export const ProductDescription: React.FC<Props> = ({ product }) => {
  const intl = useIntl();

  const [descriptions, setDescriptions] = useState([]);

  useEffect(() => {
    fetch(`/api/${getProductApiName(product)}`)
      .then((res) => res.json())
      .then((data) => setDescriptions(data));
  }, []);

  return (
    <div className={styles["container"]}>
      <div className={styles["content"]}>
        <div className={styles["product-title"]}>{product}</div>
        <div className={styles["description"]}>
          {descriptions[intl.locale]?.map((paragraph) => (
            <p key={paragraph}>&#8226;&nbsp;{paragraph}</p>
          ))}
        </div>
      </div>
    </div>
  );
};

function getProductApiName(product: BikeProducts | Products) {
  switch (product) {
    case Products.G12EVO:
      return "g12evo";
    case Products.LLC:
      return "llc";
    case Products.SLLC:
      return "sllc";
    case Products.LPC:
      return "lpc";
    case Products.LONGLIFE:
      return "longlife";
    case Products.LLPRO:
      return "longlifepro";
    case Products.MBG12:
      return "mbg12";
    case Products.STANDARD:
      return "standard";
    case Products.VC:
      return "vc";
    case Products.VCS:
      return "vcs";
    case Products.FREECOR:
      return "freecor";
  }
}
