// @ts-ignore
import React from "react"; // @ts-ignore
import styles from "../styles/notfound.module.scss";

const NotFound = () => {
  return (
    <div className={styles["container"]}>
      <div>Page not found.</div>
    </div>
  );
};

export default NotFound;
