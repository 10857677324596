// @ts-ignore
import React from "react"; // @ts-ignore
import { FormattedMessage } from "react-intl"; // @ts-ignore
import styles from "../styles/recommendationStatus.module.scss"; // @ts-ignore
import classnames from "classnames";

const RecommendationStatus = ({ currentSlide }) => {
  return (
    <div className={styles["container"]}>
      <div
        className={classnames(styles["recommendation-text"], {
          [styles["recommended"]]: currentSlide === 0,
          [styles["alternative"]]: currentSlide !== 0,
        })}
      >
        {currentSlide === 0 ? (
          <FormattedMessage id="productmatch.recommended" />
        ) : (
          <FormattedMessage id="productmatch.alternative" />
        )}
      </div>
    </div>
  );
};

export default RecommendationStatus;
