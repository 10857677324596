import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { AgriMakes, Agris } from "../api/typings";
import { Selection } from "../components/Selection";

interface UrlProps {
  chosenAgri: Agris;
}

export const AgriYearSelection: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();

  const params = useParams<UrlProps>();
  const { chosenAgri } = params;

  const agriMakes = [AgriMakes["<2000"], AgriMakes["2000+"]];

  const options = agriMakes.map((year) => ({ value: year, label: year }));

  const onChange = (option) => {
    if (!option) return;
    history.push(`/agris/${chosenAgri}/${option.value}`);
  };

  return (
    <Selection
      options={options}
      placeholder={intl.formatMessage({ id: "agrisMakeSelection.header" })}
      onChange={onChange}
    />
  );
};
