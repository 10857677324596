import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { FordVanMotors, MercedesVanMotors, Vans } from "../api/typings";
import { Selection } from "../components/Selection";

interface UrlProps {
  chosenVan: string;
  vanChosenYear: string;
}

export const VanMotorSelection = () => {
  const intl = useIntl();
  const history = useHistory();

  const params = useParams<UrlProps>();

  let vanMotors;
  if (params.chosenVan === Vans["Mercedes-Benz"]) {
    vanMotors = Object.keys(MercedesVanMotors).map(
      (k) => MercedesVanMotors[k as any]
    );
  }
  if (params.chosenVan === Vans.Ford) {
    vanMotors = Object.keys(FordVanMotors).map((k) => FordVanMotors[k as any]);
  }

  const options = vanMotors.map((motor) => ({ value: motor, label: motor }));

  const onChange = (option) => {
    if (!option) return;
    history.push(
      `/vans/${params.chosenVan}/${params.vanChosenYear}/${option.value}`
    );
  };

  return (
    <Selection
      options={options}
      placeholder={intl.formatMessage({ id: "vanEngineSelection.header" })}
      onChange={onChange}
    />
  );
};
