import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { Selection } from "../components/Selection";

interface UrlProps {
  chosenTruck?: string;
}

export const TruckEngineSelection = () => {
  const intl = useIntl();
  const history = useHistory();
  const { chosenTruck } = useParams<UrlProps>();

  const isMercedes = chosenTruck === "Mercedes-Benz";

  const mercedesTruckEngines = [
    "OM 300",
    "OM 400",
    "OM 904-926",
    "OM 934, 936",
    "OM 457, 460",
    "OM 500",
    "OM 470-473",
    "With SWR",
  ];

  const truckEngines = [
    "<Euro 2",
    "Euro 3",
    "Euro 4",
    "Euro 5",
    "EEV",
    "Euro 6",
  ];
  const options = isMercedes
    ? mercedesTruckEngines.map((engine) => ({ value: engine, label: engine }))
    : truckEngines.map((engine) => ({
        value: engine,
        label: engine,
      }));

  const onChange = (option) => {
    if (!option) return;
    history.push(`/trucks/${chosenTruck}/${option.value}`);
  };

  return (
    <Selection
      options={options}
      placeholder={intl.formatMessage({ id: "truckEngineSelection.header" })}
      onChange={onChange}
    />
  );
};
