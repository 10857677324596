// @ts-ignore
import React from "react"; // @ts-ignore
import { Link } from "react-router-dom"; // @ts-ignore
import { FormattedMessage } from "react-intl"; // @ts-ignore
import styles from "../styles/sidenav.module.scss"; // @ts-ignore
import classnames from "classnames";

const SideNav = ({ isSideNavVisible, setIsSideNavVisible }) => {
  return (
    <>
      <div
        className={classnames(styles["navdimmer"], {
          [styles["dimbackground"]]: isSideNavVisible,
        })}
      />

      <div
        className={classnames(styles["sidenav"], {
          [styles["appear"]]: isSideNavVisible,
        })}
      >
        {isSideNavVisible && (
          <div className={styles["navtitles"]}>
            <Link to="/about" onClick={() => setIsSideNavVisible(false)}>
              <FormattedMessage id="navbar.about" defaultMessage="About" />
            </Link>
            <Link to="/readmore" onClick={() => setIsSideNavVisible(false)}>
              <FormattedMessage
                id="navbar.readmore"
                defaultMessage="Read more"
              />
            </Link>
            <Link to="/ingredients" onClick={() => setIsSideNavVisible(false)}>
              <FormattedMessage
                id="navbar.ingredients"
                defaultMessage="Ingredients"
              />
            </Link>
            <Link to="/mixingguide" onClick={() => setIsSideNavVisible(false)}>
              <FormattedMessage
                id="navbar.mixingguide"
                defaultMessage="Mixing guide"
              />
            </Link>
            <Link to="/standards" onClick={() => setIsSideNavVisible(false)}>
              <FormattedMessage
                id="navbar.standards"
                defaultMessage="Standards"
              />
            </Link>
            <Link to="/products" onClick={() => setIsSideNavVisible(false)}>
              <FormattedMessage
                id="navbar.products"
                defaultMessage="Products"
              />
            </Link>
            {/* <div className={styles["feedback"]}>
              <FormattedMessage
                id="navbar.feedback"
                defaultMessage="Feedback"
              />
            </div> */}
            {/* <Link to="/feedback" onClick={() => setIsSideNavVisible(false)}>
            <FormattedMessage id="navbar.feedback" defaultMessage="Feedback" />
          </Link> */}
          </div>
        )}
      </div>
    </>
  );
};

export default SideNav;
