import React from "react";
import styles from "../styles/vehicleInfo.module.scss";

interface Props {
  carModel?: string;
  year?: number | null;
  bikeBrand?: string;
  truckBrand?: string;
  truckEngine?: string | null;
  vanModel?: string;
  vanYear?: number | null;
  vanMotor?: string | null;
  busBrand?: string;
  busEuroStandard?: string;
  busEngine?: string;
  agriBrand?: string;
  agriEngine?: string;
  agriMake?: string;
}

export const VehicleInfo = ({
  carModel,
  year,
  bikeBrand,
  truckBrand,
  truckEngine,
  vanModel,
  vanYear,
  vanMotor,
  busBrand,
  busEuroStandard,
  busEngine,
  agriBrand,
  agriEngine,
  agriMake,
}: Props) => {
  return (
    <div className={styles["vehicle-info"]}>
      {carModel && year && <div>{`${carModel} ${year}`}</div>}
      {bikeBrand && <div>{`${bikeBrand}`}</div>}
      {truckBrand && truckEngine && <div>{`${truckBrand} ${truckEngine}`}</div>}
      {vanModel && vanYear && (
        <div>{`${vanModel} ${vanYear} ${vanMotor ? vanMotor : ""}`}</div>
      )}
      {busBrand && busEuroStandard && (
        <div>{`${busBrand} ${busEuroStandard} ${
          busEngine ? busEngine : ""
        }`}</div>
      )}
      {agriBrand && agriEngine && agriMake && (
        <div>{`${agriBrand} ${agriEngine} ${agriMake}`}</div>
      )}
    </div>
  );
};
