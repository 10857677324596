import {
  AgriAndProductsData,
  AgriEngines,
  Agris,
  AgrisAndTheirEngines,
  Products,
} from "./typings";

// could it be a brand - key, engines - value array instead?
export const agrisAndEngines: AgrisAndTheirEngines[] = [
  {
    brand: Agris.AGCO,
    engines: [
      AgriEngines["Agco Power"],
      AgriEngines.Cummins,
      AgriEngines.Iseki,
      AgriEngines["Sisu | Agco Power"],
    ],
  },
  {
    brand: Agris.Agromehanika,
    engines: [AgriEngines["Lombardini | Kohler"]],
  },
  {
    brand: Agris["Antonio Carraro"],
    engines: [
      AgriEngines.Deutz,
      AgriEngines.Kohler,
      AgriEngines.Kubota,
      AgriEngines.Yanmar,
    ],
  },
  {
    brand: Agris.Belarus,
    engines: [
      AgriEngines.Belarco,
      AgriEngines.Cummins,
      AgriEngines.Doosan,
      AgriEngines.Liadong,
      AgriEngines["Lombardini | Kohler"],
      AgriEngines["MTZ | MMZ"],
      AgriEngines.Weichai,
    ],
  },
  {
    brand: Agris["Branson | TYM"],
    engines: [AgriEngines.Kukje],
  },
  {
    brand: Agris["Case IH"],
    engines: [
      AgriEngines["CNH | Iveco FPT"],
      AgriEngines.Cummins,
      AgriEngines.FPT,
      AgriEngines["Iveco | FPT"],
      AgriEngines["LS Mtron"],
      AgriEngines.MWM,
      AgriEngines["Perkins | Caterpillar"],
      AgriEngines.Shibaura,
      AgriEngines["Sisu | Agco Power"],
    ],
  },
  {
    brand: Agris.Claas,
    engines: [
      AgriEngines.Caterpillar,
      AgriEngines.Cummins,
      AgriEngines.Deutz,
      AgriEngines["DPS (Deere Power System)"],
      AgriEngines.FPT,
      AgriEngines.Iveco,
      AgriEngines["John Deere"],
      AgriEngines["LS Mtron"],
      AgriEngines.MAN,
      AgriEngines["Mercedes-Benz"],
      AgriEngines["Perkins | Caterpillar"],
      AgriEngines.SDF,
    ],
  },
  {
    brand: Agris["Deutz-Fahr"],
    engines: [
      AgriEngines.Deutz,
      AgriEngines["Mercedes-Benz"],
      AgriEngines.Mitsubishi,
      AgriEngines["Perkins | Caterpillar"],
      AgriEngines.SDF,
      AgriEngines.Simpson,
    ],
  },
  {
    brand: Agris.Dieci,
    engines: [AgriEngines.FPT, AgriEngines.Kubota],
  },
  {
    brand: Agris["Dong Feng"],
    engines: [AgriEngines.Changchai, AgriEngines["Jangsu Jianghuai"]],
  },
  {
    brand: Agris.Fendt,
    engines: [
      AgriEngines["Agco Power"],
      AgriEngines.Deutz,
      AgriEngines.MAN,
      AgriEngines.MTU,
      AgriEngines.MWM,
    ],
  },
  {
    brand: Agris.Hürlimann,
    engines: [AgriEngines.Deutz, AgriEngines.SDF],
  },
  {
    brand: Agris.JCB,
    engines: [
      AgriEngines["Agco Power"],
      AgriEngines.Cummins,
      AgriEngines["Perkins | Caterpillar"],
      AgriEngines["Sisu | Agco Power"],
    ],
  },
  {
    brand: Agris["John Deere"],
    engines: [
      AgriEngines.Cummins,
      AgriEngines.FPT,
      AgriEngines["John Deere"],
      AgriEngines.Yanmar,
    ],
  },
  {
    brand: Agris["Kioti | Daedong"],
    engines: [
      AgriEngines.Daedong,
      AgriEngines.Doosan,
      AgriEngines["Perkins | Caterpillar"],
      AgriEngines.Shibaura,
    ],
  },
  {
    brand: Agris.Kramer,
    engines: [AgriEngines.Deutz, AgriEngines.Kohler, AgriEngines.Yanmar],
  },
  {
    brand: Agris.Kubota,
    engines: [AgriEngines.Kubota],
  },
  {
    brand: Agris["KUKJE | Branson"],
    engines: [AgriEngines["Branson | TYM"], AgriEngines.Yanmar],
  },
  {
    brand: Agris.Lamborghini,
    engines: [AgriEngines.Deutz, AgriEngines.SDF],
  },
  {
    brand: Agris.Landini,
    engines: [
      AgriEngines.Cummins,
      AgriEngines.Deutz,
      AgriEngines.FPT,
      AgriEngines.Kohler,
      AgriEngines["Perkins | Caterpillar"],
      AgriEngines.Yanmar,
    ],
  },
  {
    brand: Agris.Lovol,
    engines: [
      AgriEngines.Doosan,
      AgriEngines.Huafeng,
      AgriEngines.Laidong,
      AgriEngines.Shangchai,
      AgriEngines.Xinchai,
      AgriEngines.Yanmar,
    ],
  },
  {
    brand: Agris.Manitou,
    engines: [AgriEngines.Deutz, AgriEngines.Kubota, AgriEngines.Yanmar],
  },
  {
    brand: Agris["Massey Ferguson"],
    engines: [
      AgriEngines["Agco Power"],
      AgriEngines.Iseki,
      AgriEngines.MAN,
      AgriEngines.Mitsubishi,
      AgriEngines["Perkins | Caterpillar"],
      AgriEngines.Shibaura,
      AgriEngines.Simpson,
      AgriEngines["Sisu | Agco Power"],
    ],
  },
  {
    brand: Agris.McCormick,
    engines: [
      AgriEngines.BetaPower,
      AgriEngines.Cummins,
      AgriEngines.Daedong,
      AgriEngines.Deutz,
      AgriEngines.FPT,
      AgriEngines["Iveco | FPT"],
      AgriEngines.Kohler,
      AgriEngines.Kukje,
      AgriEngines.Mitsubishi,
      AgriEngines["Perkins | Caterpillar"],
    ],
  },
  {
    brand: Agris.Merlo,
    engines: [
      AgriEngines.Deutz,
      AgriEngines.FPT,
      AgriEngines.Kohler,
      AgriEngines["Perkins | Caterpillar"],
    ],
  },
  {
    brand: Agris["New Holland"],
    engines: [
      AgriEngines.Cummins,
      AgriEngines.Ford,
      AgriEngines.FPT,
      AgriEngines["Iveco | FPT"],
      AgriEngines["LS Mtron"],
      AgriEngines.Mitsubishi,
      AgriEngines.MWM,
      AgriEngines["Nef (FPT)"],
      AgriEngines.Shibaura,
      AgriEngines["Sisu | Agco Power"],
      AgriEngines.Yanmar,
    ],
  },
  {
    brand: Agris.Same,
    engines: [AgriEngines.Deutz, AgriEngines.SDF, AgriEngines.Simpson],
  },
  {
    brand: Agris.Solis,
    engines: [AgriEngines.ITL, AgriEngines.Mitsubishi],
  },
  {
    brand: Agris.Steyr,
    engines: [
      AgriEngines.FPT,
      AgriEngines["Iveco | FPT"],
      AgriEngines.MWM,
      AgriEngines["Perkins | Caterpillar"],
      AgriEngines["Sisu | Agco Power"],
    ],
  },
  {
    brand: Agris.Zetor,
    engines: [
      AgriEngines.Deutz,
      AgriEngines["Same | SDF"],
      AgriEngines.Zetor,
      AgriEngines.Yanmar,
    ],
  },
  {
    brand: Agris.TYM,
    engines: [
      AgriEngines.Cummins,
      AgriEngines.Daedong,
      AgriEngines.Deutz,
      AgriEngines.Doosan,
      AgriEngines.Kukje,
      AgriEngines.Mitsubishi,
      AgriEngines["Perkins | Caterpillar"],
      AgriEngines.TYM,
      AgriEngines.Yanmar,
    ],
  },
  {
    brand: Agris.Ursus,
    engines: [
      AgriEngines.Deutz,
      AgriEngines.Kohler,
      AgriEngines["KUKJE | Branson"],
      AgriEngines["Perkins | Caterpillar"],
    ],
  },
  {
    brand: Agris["Valmet | Valtra"],
    engines: [AgriEngines["Sisu | Agco Power"]],
  },
  {
    brand: Agris.Valtra,
    engines: [
      AgriEngines["Agco Power"],
      AgriEngines["Perkins | Caterpillar"],
      AgriEngines["Sisu | Agco Power"],
    ],
  },
  {
    brand: Agris.Versatile,
    engines: [AgriEngines.Cummins],
  },
];

export const agriAndProductsData: AgriAndProductsData[] = [
  {
    brand: Agris.AGCO,
    engines: [AgriEngines["Agco Power"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": { primary: Products.VCS, secondary: Products.LLPRO },
    },
  },
  {
    brand: Agris.AGCO,
    engines: [AgriEngines.Cummins],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": { primary: Products.MBG12, secondary: Products.LLPRO },
    },
  },
  {
    brand: Agris.AGCO,
    engines: [AgriEngines.Iseki],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLC,
        secondary: Products.VCS,
        third: Products.LLPRO,
      },
    },
  },
  {
    brand: Agris.AGCO,
    engines: [AgriEngines["Sisu | Agco Power"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLPRO,
        secondary: Products.VCS,
        third: Products.MBG12,
      },
    },
  },
  {
    brand: Agris.Agromehanika,
    engines: [AgriEngines["Lombardini | Kohler"]],
    data: {
      "<2000": { primary: Products.STANDARD },
      "2000+": {
        primary: Products.VCS,
        secondary: Products.LLPRO,
        third: Products.STANDARD,
      },
    },
  },
  {
    brand: Agris["Antonio Carraro"],
    engines: [AgriEngines.Deutz],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.LLPRO,
        third: Products.VCS,
      },
    },
  },
  {
    brand: Agris["Antonio Carraro"],
    engines: [AgriEngines.Kohler],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.VC,
      },
    },
  },
  {
    brand: Agris["Antonio Carraro"],
    engines: [AgriEngines.Kubota],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLC,
        secondary: Products.VCS,
      },
    },
  },
  {
    brand: Agris["Antonio Carraro"],
    engines: [AgriEngines.Yanmar],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLPRO,
        secondary: Products.LLC,
        third: Products.VCS,
      },
    },
  },
  {
    brand: Agris.Belarus,
    engines: [AgriEngines.Belarco, AgriEngines["MTZ | MMZ"], AgriEngines.Kukje],
    data: {
      "<2000": { primary: Products.STANDARD },
      "2000+": {
        primary: Products.STANDARD,
      },
    },
  },
  {
    brand: Agris.Belarus,
    engines: [AgriEngines.Cummins],
    data: {
      "<2000": { primary: Products.STANDARD },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.LLPRO,
      },
    },
  },
  {
    brand: Agris.Belarus,
    engines: [AgriEngines.Doosan, AgriEngines.Liadong, AgriEngines.Weichai],
    data: {
      "<2000": { primary: Products.STANDARD },
      "2000+": {
        primary: Products.LLC,
        secondary: Products.LLPRO,
        third: Products.STANDARD,
      },
    },
  },
  {
    brand: Agris.Belarus,
    engines: [AgriEngines["Lombardini | Kohler"]],
    data: {
      "<2000": { primary: Products.STANDARD },
      "2000+": {
        primary: Products.VCS,
        secondary: Products.LLPRO,
        third: Products.STANDARD,
      },
    },
  },
  {
    brand: Agris["Branson | TYM"],
    engines: [AgriEngines.Kukje],
    data: {
      "<2000": { primary: Products.STANDARD },
      "2000+": {
        primary: Products.STANDARD,
      },
    },
  },
  {
    brand: Agris["Case IH"],
    engines: [AgriEngines.Cummins],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.LLPRO,
      },
    },
  },
  {
    brand: Agris["Case IH"],
    engines: [
      AgriEngines["CNH | Iveco FPT"],
      AgriEngines.FPT,
      AgriEngines["Iveco | FPT"],
    ],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.VCS,
        secondary: Products.LLPRO,
        third: Products.VC,
      },
    },
  },
  {
    brand: Agris["Case IH"],
    engines: [AgriEngines["LS Mtron"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLC,
        secondary: Products.LLPRO,
        third: Products.STANDARD,
      },
    },
  },
  {
    brand: Agris["Case IH"],
    engines: [AgriEngines.MWM],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLPRO,
        secondary: Products.VCS,
      },
    },
  },
  {
    brand: Agris["Case IH"],
    engines: [AgriEngines["Perkins | Caterpillar"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.VCS,
        secondary: Products.VC,
      },
    },
  },
  {
    brand: Agris["Case IH"],
    engines: [AgriEngines.Shibaura],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLC,
        secondary: Products.VCS,
        third: Products.LLPRO,
      },
    },
  },
  {
    brand: Agris["Case IH"],
    engines: [AgriEngines["Sisu | Agco Power"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLPRO,
        secondary: Products.VCS,
        third: Products.MBG12,
      },
    },
  },
  {
    brand: Agris.Claas,
    engines: [AgriEngines.Caterpillar],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLPRO,
        secondary: Products.VCS,
      },
    },
  },
  {
    brand: Agris.Claas,
    engines: [AgriEngines.Cummins],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.LLPRO,
      },
    },
  },
  {
    brand: Agris.Claas,
    engines: [AgriEngines.Deutz, AgriEngines.MAN],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.LLPRO,
        third: Products.VCS,
      },
    },
  },
  {
    brand: Agris.Claas,
    engines: [AgriEngines["DPS (Deere Power System)"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.VCS,
        secondary: Products.LLPRO,
        third: Products.MBG12,
      },
    },
  },
  {
    brand: Agris.Claas,
    engines: [AgriEngines.FPT, AgriEngines["John Deere"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.VCS,
        secondary: Products.LLPRO,
        third: Products.VC,
      },
    },
  },
  {
    brand: Agris.Claas,
    engines: [AgriEngines.Iveco],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.VCS,
        secondary: Products.LLPRO,
      },
    },
  },
  {
    brand: Agris.Claas,
    engines: [AgriEngines["LS Mtron"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLC,
        secondary: Products.LLPRO,
        third: Products.STANDARD,
      },
    },
  },
  {
    brand: Agris.Claas,
    engines: [AgriEngines["Mercedes-Benz"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
      },
    },
  },
  {
    brand: Agris.Claas,
    engines: [AgriEngines["Perkins | Caterpillar"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.VCS,
        secondary: Products.VC,
      },
    },
  },
  {
    brand: Agris.Claas,
    engines: [AgriEngines.SDF],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.VC,
      },
    },
  },
  {
    brand: Agris["Deutz-Fahr"],
    engines: [AgriEngines.Deutz],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.LLPRO,
        third: Products.VCS,
      },
    },
  },
  {
    brand: Agris["Deutz-Fahr"],
    engines: [AgriEngines["Mercedes-Benz"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
      },
    },
  },
  {
    brand: Agris["Deutz-Fahr"],
    engines: [AgriEngines.Mitsubishi],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLC,
        secondary: Products.LLPRO,
      },
    },
  },
  {
    brand: Agris["Deutz-Fahr"],
    engines: [AgriEngines["Perkins | Caterpillar"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.VCS,
        secondary: Products.VC,
      },
    },
  },
  {
    brand: Agris["Deutz-Fahr"],
    engines: [AgriEngines.SDF],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.VC,
      },
    },
  },
  {
    brand: Agris["Deutz-Fahr"],
    engines: [AgriEngines.Simpson],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.LLPRO,
        third: Products.STANDARD,
      },
    },
  },
  {
    brand: Agris["Deutz-Fahr"],
    engines: [AgriEngines.FPT],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.VCS,
        secondary: Products.LLPRO,
        third: Products.VC,
      },
    },
  },
  {
    brand: Agris.Dieci,
    engines: [AgriEngines.FPT],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.VCS,
        secondary: Products.LLPRO,
        third: Products.VC,
      },
    },
  },
  {
    brand: Agris.Dieci,
    engines: [AgriEngines.Kubota],
    data: {
      "<2000": { primary: Products.LLC },
      "2000+": {
        primary: Products.LLC,
        secondary: Products.VCS,
      },
    },
  },
  {
    brand: Agris["Dong Feng"],
    engines: [AgriEngines.Changchai, AgriEngines["Jangsu Jianghuai"]],
    data: {
      "<2000": { primary: Products.LLC },
      "2000+": {
        primary: Products.LLC,
        secondary: Products.LLPRO,
        third: Products.STANDARD,
      },
    },
  },
  {
    brand: Agris.Fendt,
    engines: [AgriEngines["Agco Power"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.VCS,
        third: Products.LLPRO,
      },
    },
  },
  {
    brand: Agris.Fendt,
    engines: [AgriEngines.Deutz, AgriEngines.MAN],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.LLPRO,
        third: Products.VCS,
      },
    },
  },
  {
    brand: Agris.Fendt,
    engines: [AgriEngines.MTU],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLPRO,
        secondary: Products.VC,
      },
    },
  },
  {
    brand: Agris.Fendt,
    engines: [AgriEngines.MWM],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLPRO,
        secondary: Products.VCS,
      },
    },
  },
  {
    brand: Agris.Hürlimann,
    engines: [AgriEngines.Deutz],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.LLPRO,
        third: Products.VCS,
      },
    },
  },
  {
    brand: Agris.Hürlimann,
    engines: [AgriEngines.SDF],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.VC,
      },
    },
  },
  {
    brand: Agris.JCB,
    engines: [AgriEngines["Agco Power"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.VCS,
        third: Products.LLPRO,
      },
    },
  },
  {
    brand: Agris.JCB,
    engines: [AgriEngines.Cummins],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.LLPRO,
      },
    },
  },
  {
    brand: Agris.JCB,
    engines: [AgriEngines["Perkins | Caterpillar"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.VCS,
        secondary: Products.VC,
      },
    },
  },
  {
    brand: Agris.JCB,
    engines: [AgriEngines["Sisu | Agco Power"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLPRO,
        secondary: Products.VCS,
        third: Products.MBG12,
      },
    },
  },
  {
    brand: Agris["John Deere"],
    engines: [AgriEngines.Cummins],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.LLPRO,
      },
    },
  },
  {
    brand: Agris["John Deere"],
    engines: [AgriEngines.FPT, AgriEngines["John Deere"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.VCS,
        secondary: Products.LLPRO,
        third: Products.VC,
      },
    },
  },
  {
    brand: Agris["John Deere"],
    engines: [AgriEngines.Yanmar],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLPRO,
        secondary: Products.LLC,
        third: Products.VCS,
      },
    },
  },
  {
    brand: Agris["Kioti | Daedong"],
    engines: [AgriEngines.Daedong],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLC,
        secondary: Products.LLPRO,
        third: Products.STANDARD,
      },
    },
  },
  {
    brand: Agris["Kioti | Daedong"],
    engines: [AgriEngines.Doosan],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLC,
        secondary: Products.LLPRO,
        third: Products.VCS,
      },
    },
  },
  {
    brand: Agris["Kioti | Daedong"],
    engines: [AgriEngines["Perkins | Caterpillar"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.VCS,
        secondary: Products.VC,
      },
    },
  },
  {
    brand: Agris["Kioti | Daedong"],
    engines: [AgriEngines.Shibaura],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLC,
        secondary: Products.VCS,
        third: Products.LLPRO,
      },
    },
  },
  {
    brand: Agris.Kramer,
    engines: [AgriEngines.Deutz],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.LLPRO,
        third: Products.VCS,
      },
    },
  },
  {
    brand: Agris.Kramer,
    engines: [AgriEngines.Kohler],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.VC,
      },
    },
  },
  {
    brand: Agris.Kramer,
    engines: [AgriEngines.Yanmar],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLPRO,
        secondary: Products.LLC,
        third: Products.VCS,
      },
    },
  },
  {
    brand: Agris.Kubota,
    engines: [AgriEngines.Kubota],
    data: {
      "<2000": { primary: Products.LLC },
      "2000+": {
        primary: Products.LLC,
        secondary: Products.VCS,
      },
    },
  },
  {
    brand: Agris["KUKJE | Branson"],
    engines: [AgriEngines["Branson | TYM"]],
    data: {
      "<2000": { primary: Products.STANDARD },
      "2000+": {
        primary: Products.STANDARD,
      },
    },
  },
  {
    brand: Agris["KUKJE | Branson"],
    engines: [AgriEngines.Yanmar],
    data: {
      "<2000": { primary: Products.STANDARD },
      "2000+": {
        primary: Products.LLPRO,
        secondary: Products.LLC,
        third: Products.VCS,
      },
    },
  },
  {
    brand: Agris.Lamborghini,
    engines: [AgriEngines.Deutz],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.LLPRO,
        third: Products.VCS,
      },
    },
  },
  {
    brand: Agris.Lamborghini,
    engines: [AgriEngines.SDF],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.VC,
      },
    },
  },
  {
    brand: Agris.Landini,
    engines: [AgriEngines.Cummins],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.LLPRO,
      },
    },
  },
  {
    brand: Agris.Landini,
    engines: [AgriEngines.Deutz],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.LLPRO,
        third: Products.VCS,
      },
    },
  },
  {
    brand: Agris.Landini,
    engines: [AgriEngines.FPT],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.VCS,
        secondary: Products.LLPRO,
        third: Products.VC,
      },
    },
  },
  {
    brand: Agris.Landini,
    engines: [AgriEngines.Kohler],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.VC,
      },
    },
  },
  {
    brand: Agris.Landini,
    engines: [AgriEngines["Perkins | Caterpillar"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.VCS,
        secondary: Products.VC,
      },
    },
  },
  {
    brand: Agris.Landini,
    engines: [AgriEngines.Yanmar],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLPRO,
        secondary: Products.LLC,
        third: Products.VCS,
      },
    },
  },
  {
    brand: Agris.Lovol,
    engines: [AgriEngines.Doosan],
    data: {
      "<2000": { primary: Products.STANDARD },
      "2000+": {
        primary: Products.LLC,
        secondary: Products.LLPRO,
      },
    },
  },
  {
    brand: Agris.Lovol,
    engines: [
      AgriEngines.Huafeng,
      AgriEngines.Laidong,
      AgriEngines.Shangchai,
      AgriEngines.Xinchai,
    ],
    data: {
      "<2000": { primary: Products.STANDARD },
      "2000+": {
        primary: Products.LLC,
        secondary: Products.LLPRO,
        third: Products.STANDARD,
      },
    },
  },
  {
    brand: Agris.Lovol,
    engines: [AgriEngines.Yanmar],
    data: {
      "<2000": { primary: Products.STANDARD },
      "2000+": {
        primary: Products.LLPRO,
        secondary: Products.LLC,
        third: Products.VCS,
      },
    },
  },
  {
    brand: Agris.Manitou,
    engines: [AgriEngines.Deutz],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.LLPRO,
        third: Products.VCS,
      },
    },
  },
  {
    brand: Agris.Manitou,
    engines: [AgriEngines.Kubota],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLC,
        secondary: Products.VCS,
      },
    },
  },
  {
    brand: Agris.Manitou,
    engines: [AgriEngines.Yanmar],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLPRO,
        secondary: Products.LLC,
        third: Products.VCS,
      },
    },
  },
  {
    brand: Agris["Massey Ferguson"],
    engines: [AgriEngines["Agco Power"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.VCS,
        third: Products.LLPRO,
      },
    },
  },
  {
    brand: Agris["Massey Ferguson"],
    engines: [AgriEngines.Iseki, AgriEngines.Shibaura],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLC,
        secondary: Products.VCS,
        third: Products.LLPRO,
      },
    },
  },
  {
    brand: Agris["Massey Ferguson"],
    engines: [AgriEngines.MAN],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.LLPRO,
        third: Products.VCS,
      },
    },
  },
  {
    brand: Agris["Massey Ferguson"],
    engines: [AgriEngines.Mitsubishi],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLC,
        secondary: Products.LLPRO,
      },
    },
  },
  {
    brand: Agris["Massey Ferguson"],
    engines: [AgriEngines["Perkins | Caterpillar"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.VCS,
        secondary: Products.VC,
      },
    },
  },
  {
    brand: Agris["Massey Ferguson"],
    engines: [AgriEngines.Simpson],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.LLPRO,
        third: Products.STANDARD,
      },
    },
  },
  {
    brand: Agris["Massey Ferguson"],
    engines: [AgriEngines["Sisu | Agco Power"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLPRO,
        secondary: Products.VCS,
        third: Products.MBG12,
      },
    },
  },
  {
    brand: Agris.McCormick,
    engines: [AgriEngines.BetaPower],
    data: {
      "<2000": { primary: Products.NONE },
      "2000+": {
        primary: Products.VCS,
        secondary: Products.LLPRO,
      },
    },
  },
  {
    brand: Agris.McCormick,
    engines: [AgriEngines.Cummins],
    data: {
      "<2000": { primary: Products.NONE },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.LLPRO,
      },
    },
  },
  {
    brand: Agris.McCormick,
    engines: [AgriEngines.Daedong],
    data: {
      "<2000": { primary: Products.NONE },
      "2000+": {
        primary: Products.LLC,
        secondary: Products.LLPRO,
        third: Products.STANDARD,
      },
    },
  },
  {
    brand: Agris.McCormick,
    engines: [AgriEngines.Deutz],
    data: {
      "<2000": { primary: Products.NONE },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.LLPRO,
        third: Products.VCS,
      },
    },
  },
  {
    brand: Agris.McCormick,
    engines: [AgriEngines.FPT, AgriEngines["Iveco | FPT"]],
    data: {
      "<2000": { primary: Products.NONE },
      "2000+": {
        primary: Products.VCS,
        secondary: Products.LLPRO,
        third: Products.VC,
      },
    },
  },
  {
    brand: Agris.McCormick,
    engines: [AgriEngines.Kohler],
    data: {
      "<2000": { primary: Products.NONE },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.VC,
      },
    },
  },
  {
    brand: Agris.McCormick,
    engines: [AgriEngines.Kukje],
    data: {
      "<2000": { primary: Products.NONE },
      "2000+": {
        primary: Products.LLC,
        secondary: Products.VCS,
      },
    },
  },
  {
    brand: Agris.McCormick,
    engines: [AgriEngines.Mitsubishi],
    data: {
      "<2000": { primary: Products.NONE },
      "2000+": {
        primary: Products.LLC,
        secondary: Products.LLPRO,
      },
    },
  },
  {
    brand: Agris.McCormick,
    engines: [AgriEngines["Perkins | Caterpillar"]],
    data: {
      "<2000": { primary: Products.NONE },
      "2000+": {
        primary: Products.VCS,
        secondary: Products.VC,
      },
    },
  },
  {
    brand: Agris.Merlo,
    engines: [AgriEngines.Deutz],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.LLPRO,
        third: Products.VCS,
      },
    },
  },
  {
    brand: Agris.Merlo,
    engines: [AgriEngines.FPT],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.VCS,
        secondary: Products.LLPRO,
        third: Products.VC,
      },
    },
  },
  {
    brand: Agris.Merlo,
    engines: [AgriEngines.Kohler],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.VC,
      },
    },
  },
  {
    brand: Agris.Merlo,
    engines: [AgriEngines["Perkins | Caterpillar"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.VCS,
        secondary: Products.VC,
      },
    },
  },
  {
    brand: Agris["New Holland"],
    engines: [AgriEngines.Cummins],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.LLPRO,
      },
    },
  },
  {
    brand: Agris["New Holland"],
    engines: [AgriEngines.Ford, AgriEngines["Nef (FPT)"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.VCS,
        secondary: Products.LLPRO,
      },
    },
  },
  {
    brand: Agris["New Holland"],
    engines: [AgriEngines.FPT, AgriEngines["Iveco | FPT"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.VCS,
        secondary: Products.LLPRO,
        third: Products.VC,
      },
    },
  },
  {
    brand: Agris["New Holland"],
    engines: [AgriEngines["LS Mtron"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLC,
        secondary: Products.LLPRO,
        third: Products.STANDARD,
      },
    },
  },
  {
    brand: Agris["New Holland"],
    engines: [AgriEngines.Mitsubishi],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLC,
        secondary: Products.LLPRO,
      },
    },
  },
  {
    brand: Agris["New Holland"],
    engines: [AgriEngines.MWM],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLPRO,
        secondary: Products.VCS,
      },
    },
  },
  {
    brand: Agris["New Holland"],
    engines: [AgriEngines.Shibaura],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLC,
        secondary: Products.VCS,
        third: Products.LLPRO,
      },
    },
  },
  {
    brand: Agris["New Holland"],
    engines: [AgriEngines["Sisu | Agco Power"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLPRO,
        secondary: Products.VCS,
        third: Products.MBG12,
      },
    },
  },
  {
    brand: Agris["New Holland"],
    engines: [AgriEngines.Yanmar],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLPRO,
        secondary: Products.LLC,
        third: Products.VCS,
      },
    },
  },
  {
    brand: Agris.Same,
    engines: [AgriEngines.Deutz],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.LLPRO,
        third: Products.VCS,
      },
    },
  },
  {
    brand: Agris.Same,
    engines: [AgriEngines.SDF],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.VC,
      },
    },
  },
  {
    brand: Agris.Same,
    engines: [AgriEngines.Simpson],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.LLPRO,
        third: Products.STANDARD,
      },
    },
  },
  {
    brand: Agris.Solis,
    engines: [AgriEngines.ITL],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.STANDARD,
        secondary: Products.LLPRO,
        third: Products.VCS,
      },
    },
  },
  {
    brand: Agris.Solis,
    engines: [AgriEngines.Mitsubishi],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLC,
        secondary: Products.LLPRO,
      },
    },
  },
  {
    brand: Agris.Steyr,
    engines: [AgriEngines.FPT, AgriEngines["Iveco | FPT"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.VCS,
        secondary: Products.LLPRO,
        third: Products.VC,
      },
    },
  },
  {
    brand: Agris.Steyr,
    engines: [AgriEngines.MWM],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLPRO,
        secondary: Products.VCS,
      },
    },
  },
  {
    brand: Agris.Steyr,
    engines: [AgriEngines["Perkins | Caterpillar"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.VCS,
        secondary: Products.VC,
      },
    },
  },
  {
    brand: Agris.Steyr,
    engines: [AgriEngines["Sisu | Agco Power"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLPRO,
        secondary: Products.VCS,
        third: Products.MBG12,
      },
    },
  },
  {
    brand: Agris.Zetor,
    engines: [AgriEngines.Deutz],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.LLPRO,
        third: Products.VCS,
      },
    },
  },
  {
    brand: Agris.Zetor,
    engines: [AgriEngines["Same | SDF"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.VC,
      },
    },
  },
  {
    brand: Agris.Zetor,
    engines: [AgriEngines.Zetor],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLPRO,
        secondary: Products.VCS,
      },
    },
  },
  {
    brand: Agris.Zetor,
    engines: [AgriEngines.Yanmar],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLPRO,
        secondary: Products.LLC,
        third: Products.VCS,
      },
    },
  },
  {
    brand: Agris.TYM,
    engines: [AgriEngines.Cummins],
    data: {
      "<2000": { primary: Products.STANDARD },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.LLPRO,
      },
    },
  },
  {
    brand: Agris.TYM,
    engines: [AgriEngines.Daedong, AgriEngines.Doosan, AgriEngines.TYM],
    data: {
      "<2000": { primary: Products.STANDARD },
      "2000+": {
        primary: Products.LLC,
        secondary: Products.LLPRO,
        third: Products.STANDARD,
      },
    },
  },
  {
    brand: Agris.TYM,
    engines: [AgriEngines.Deutz],
    data: {
      "<2000": { primary: Products.STANDARD },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.LLPRO,
        third: Products.VCS,
      },
    },
  },
  {
    brand: Agris.TYM,
    engines: [AgriEngines.Kukje],
    data: {
      "<2000": { primary: Products.STANDARD },
      "2000+": {
        primary: Products.LLC,
        secondary: Products.VCS,
      },
    },
  },
  {
    brand: Agris.TYM,
    engines: [AgriEngines.Mitsubishi],
    data: {
      "<2000": { primary: Products.STANDARD },
      "2000+": {
        primary: Products.LLC,
        secondary: Products.LLPRO,
      },
    },
  },
  {
    brand: Agris.TYM,
    engines: [AgriEngines["Perkins | Caterpillar"]],
    data: {
      "<2000": { primary: Products.STANDARD },
      "2000+": {
        primary: Products.VCS,
        secondary: Products.VC,
      },
    },
  },
  {
    brand: Agris.TYM,
    engines: [AgriEngines.Yanmar],
    data: {
      "<2000": { primary: Products.STANDARD },
      "2000+": {
        primary: Products.LLPRO,
        secondary: Products.LLC,
        third: Products.VCS,
      },
    },
  },
  {
    brand: Agris.Ursus,
    engines: [AgriEngines.Deutz],
    data: {
      "<2000": { primary: Products.STANDARD },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.LLPRO,
        third: Products.VCS,
      },
    },
  },
  {
    brand: Agris.Ursus,
    engines: [AgriEngines.Kohler],
    data: {
      "<2000": { primary: Products.STANDARD },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.VC,
      },
    },
  },
  {
    brand: Agris.Ursus,
    engines: [AgriEngines["KUKJE | Branson"]],
    data: {
      "<2000": { primary: Products.STANDARD },
      "2000+": {
        primary: Products.LLC,
        secondary: Products.LLPRO,
        third: Products.STANDARD,
      },
    },
  },
  {
    brand: Agris.Ursus,
    engines: [AgriEngines["Perkins | Caterpillar"]],
    data: {
      "<2000": { primary: Products.STANDARD },
      "2000+": {
        primary: Products.VCS,
        secondary: Products.VC,
      },
    },
  },
  {
    brand: Agris["Valmet | Valtra"],
    engines: [AgriEngines["Sisu | Agco Power"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLPRO,
        secondary: Products.VCS,
        third: Products.MBG12,
      },
    },
  },
  {
    brand: Agris.Valtra,
    engines: [AgriEngines["Agco Power"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.VCS,
        third: Products.LLPRO,
      },
    },
  },
  {
    brand: Agris.Valtra,
    engines: [AgriEngines["Perkins | Caterpillar"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.VCS,
        secondary: Products.VC,
      },
    },
  },
  {
    brand: Agris.Valtra,
    engines: [AgriEngines["Sisu | Agco Power"]],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.LLPRO,
        secondary: Products.VCS,
        third: Products.MBG12,
      },
    },
  },
  {
    brand: Agris.Versatile,
    engines: [AgriEngines.Cummins],
    data: {
      "<2000": { primary: Products.LLPRO },
      "2000+": {
        primary: Products.MBG12,
        secondary: Products.LLPRO,
      },
    },
  },
];
